import { Injectable } from '@angular/core';
import { ISave } from '../domain/interfaces/iSave';

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  currentSave: ISave = {
    unsavedChanges: false,
    pageName: "none",
    routeTo: "./endpoints"
  }

  constructor(
  ) { }
}
