<p class="mb-5">Fill out the necessary general information for your new customer!</p>
<form clrForm [formGroup]="generalInformationForm" (change)="validateForm()" autocomplete="off">
    <clr-input-container>
        <label for="name" class="required-label">Name</label>
        <input type="text" formControlName="name" placeholder="Customer Name" clrInput />
        <clr-control-error *clrIfError="'required'">Enter your new customer's name.</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">
            Allowed characters: letters, numbers, space, and hyphen symbol.
        </clr-control-error>
    </clr-input-container>
    <clr-input-container>
        <label for="domain" class="required-label">Domain</label>
        <input type="text" formControlName="domain" placeholder="Custom Domain" clrInput />
        <clr-control-helper>Ex: nimble.nimdeploy.com</clr-control-helper>
        <clr-control-error *clrIfError="'required'">Enter your new customer's custom domain</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">
            Allowed characters: lowercase letters, numbers, hyphen symbol and don't write your DNS Zone name.
        </clr-control-error>
    </clr-input-container>
    <p class="mt-4 mb-3 fs-6">Chocolatey Repository</p>
    <clr-input-container>
        <label for="chocolateyRepositoryUrl" class="required-label">Repository URL</label>
        <input type="text" formControlName="chocolateyRepositoryUrl" placeholder="Chocolatey Repository Url" clrInput />
        <clr-control-error>Enter the chocolatey repository URL.</clr-control-error>
    </clr-input-container>
    <p class="mt-4 mb-3 fs-6">GitHub Branch</p>
    <clr-radio-container clrInline>
        <label for="deploymentBranch" class="required-label">Deployment Branch</label>
        <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="deploymentBranch" value="master" />
            <label>master</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="deploymentBranch" value="develop" />
            <label>develop</label>
        </clr-radio-wrapper>
        <clr-control-error>Choose a deployment branch.</clr-control-error>
    </clr-radio-container>
</form>