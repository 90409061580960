import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointLog } from '../domain/interfaces/endpointlog';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EndpointLogsService {
  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  requestLogs(endpointId: number) {
    return this.http.post(`${this.URL}/EndpointLog/request/${endpointId}`, null);
  }

  getLogs(endpointId: number) {
    return this.http.get<EndpointLog>(`${this.URL}/EndpointLog/${endpointId}`);
  }
}
