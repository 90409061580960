import { Component, OnInit, ViewChild } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { Action } from 'src/app/modules/shared/domain/action';
import { VersionDropEvent } from 'src/app/domain/interfaces/version-drop-event';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Stage } from 'src/app/modules/shared/domain/enums/stage';

const PRODUCTION = 'Production';
const ACCEPTANCE = 'Acceptance';
const TESTING = 'Testing';
const PRODUCTION_LIST = 'production-list';
const ACCEPTANCE_LIST = 'acceptance-list';
const TESTING_LIST = 'testing-list';

@Component({
  selector: 'nd-package-lifecycle',
  templateUrl: './package-lifecycle.component.html',
  styleUrls: ['./package-lifecycle.component.scss'],
})
export class PackageLifecycleComponent implements OnInit {
  @ViewChild('notification', { static: false }) notification: NotifyMeComponent;
  actions: Action[];
  testingActions: Action[] = [];
  acceptanceActions: Action[] = [];
  productionActions: Action[] = [];
  saveButtonState = ClrLoadingState.DEFAULT;
  isLoading = true;
  isAnMspDashboard = false;

  columnIdListDictionary = new Map<string, Action[]>();

  constructor(
    private actionService: ActionService,
    private actionVersionService: ActionversionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.url[0].path === 'msp' && environment.isMsp) {
      this.isAnMspDashboard = true;
    } else {
      this.isAnMspDashboard = false;
    }

    this.actionService.getActionsWithoutRecycledVersions().subscribe((data) => {
      this.actions = data;
      this.testingActions = !this.isAnMspDashboard
        ? [...this.actions.map((a) => this.selectByLifecycle(a, TESTING))]
        : [...this.actions.map((a) => this.selectByStage(a, Stage.Sandbox))];

      this.acceptanceActions = !this.isAnMspDashboard
        ? [...this.actions.map((a) => this.selectByLifecycle(a, ACCEPTANCE))]
        : [
            ...this.actions.map((a) =>
              this.selectByStage(a, Stage.QualityAssurance)
            ),
          ];

      this.productionActions = !this.isAnMspDashboard
        ? [...this.actions.map((a) => this.selectByLifecycle(a, PRODUCTION))]
        : [
            ...this.actions.map((a) =>
              this.selectByStage(a, Stage.Operational)
            ),
          ];

      this.columnIdListDictionary.set(TESTING_LIST, this.testingActions);
      this.columnIdListDictionary.set(ACCEPTANCE_LIST, this.acceptanceActions);
      this.columnIdListDictionary.set(PRODUCTION_LIST, this.productionActions);
      this.isLoading = false;
    });
  }

  private selectByLifecycle(action: Action, lifecycle: string): Action {
    return {
      id: action.id,
      name: action.name,
      actionVersion: action.actionVersion.filter((av) =>
        av.actionVersionTag.find((avt) => avt.tag.name === lifecycle)
      ),
      isActive: false,
      isDetachedInstall: false,
      isRebootRequired: false,
      hasOverridenGlobalAutoLifecycle: false,
    };
  }

  private selectByStage(action: Action, stage: Stage): Action {
    return {
      id: action.id,
      name: action.name,
      actionVersion: action.actionVersion.filter((av) => av.stage === stage),
      isActive: false,
      isDetachedInstall: false,
      isRebootRequired: false,
      hasOverridenGlobalAutoLifecycle: false,
    };
  }

  filterActionsWithoutVersions(action: Action): boolean {
    return !!action.actionVersion.length;
  }

  drop(event: VersionDropEvent) {
    this.move([event.version], event.fromId, event.toId);
  }

  move(versions: ActionVersion[], fromId: string, toId?: string) {
    if (!versions.length) return;
    versions.forEach((v) => (v.isMoved = true));
    const fromList = this.columnIdListDictionary.get(fromId);
    const toList = this.columnIdListDictionary.get(
      toId ?? (fromId == TESTING_LIST ? ACCEPTANCE_LIST : PRODUCTION_LIST)
    );
    const fromPackage = fromList.find((a) => a.id == versions[0].actionId);
    const toPackage = toList.find((a) => a.id == versions[0].actionId);

    fromPackage.actionVersion = fromPackage.actionVersion.filter(
      (av) => versions.indexOf(av) == -1
    );

    // this should be a temporary filter, because according to the new architecture there can only be one lifecycle per version
    // however this was not the case before and there are multiple lifeycles for some versions
    toPackage.actionVersion = toPackage.actionVersion.filter(
      (v) => versions.indexOf(v) == -1
    );

    toPackage.actionVersion = toPackage.actionVersion.concat(versions);
  }

  async saveChanges() {
    this.saveButtonState = ClrLoadingState.LOADING;
    const data = {
      versionIdsToAcceptance: this.flatMapIds(this.acceptanceActions),
      versionIdsToTesting: this.flatMapIds(this.testingActions),
      versionIdsToProduction: this.flatMapIds(this.productionActions),
    };

    const observable = !this.isAnMspDashboard
      ? this.actionVersionService.BulkUpdateActionVersionLifecycle(data)
      : this.actionVersionService.bulkUpdateActionVersionStage(data);

    observable.subscribe({
      next: () => {
        this.saveButtonState = ClrLoadingState.SUCCESS;
      },
      error: () => {
        this.saveButtonState = ClrLoadingState.DEFAULT;
        this.notification.showNotification({
          defaultNotificationText: 'Failed to update the package lifecycles!',
          standardStatus: 'danger',
          notificationShow: true,
        });
      },
    });
  }

  private flatMapIds(actions: Action[]) {
    return actions
      .map((a) => a.actionVersion)
      .reduce((prev: ActionVersion[], current: ActionVersion[]) => {
        return prev.concat(current);
      })
      .filter((v) => v.isMoved)
      .map((v) => v.id);
  }
}
