import { Component, OnInit, ViewChild, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { HostService } from 'src/app/services/host.service';
import { AssignmentService } from 'src/app/services/tasklist.service';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {

  @Input() notif: NotifyMeComponent;

  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  constructor(private hSvc: HostService, private aSvc: AssignmentService, private bSvc: BlueprintService, private router: Router
    // private message: NzMessageService
  ) { }
  previousSelectedElement;
  endpoints;
  selected;
  endpointAssignment;
  selectedEndpointName = "No Endpoint Selected";
  listofBlueprints: IBluePrint[] = [];
  listofAssignmentStatus;

  selectedEpAssignments: any[] = [];



  documents: Array<any> = [];
  selectedDocuments: Array<any> = [];
  selectOnDrag = true;
  selectMode = false;
  disable = false;
  disableRangeSelection = false;
  isDesktop = true;
  selectWithShortcut = false;
  dragOverItems = false;
  someMethod(e) {
  }

  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true
    }
    this.notifyMe.showNotification(data);
  }


  ngOnInit(): void {
    this.GetEndPoints();
    this.aSvc.GetAssignmentStatus().subscribe(aStat => {
      this.listofAssignmentStatus = aStat;
    })
    this.bSvc.GetBluePrint().subscribe(v => {
      //@ts-ignore
      this.listofBlueprints = v;
    })
  }

  async GetEndPoints() {
    this.hSvc.GetEndPoints().subscribe(v => {
      this.endpoints = v;
    })


  }

  ngAfterViewInit(): void {

    // this.notifyMe.defaultNotificationText = "kekws"
    // this.notifyMe.notificationShow = false;

    // throw new Error('Method not implemented.');
  }
  AddNewAssignmentToEndpoint() {
    this.aSvc.PostSingleAssignment(this.selected.id, 5).subscribe(v => { //id 5 = Status Design
      this.GetAssignmentsByEndpointId(this.selected.id);
      // this.message.create('success', `New Assignment added to ${this.selected.name}.`);
      this.RouteAssignmentDetails(v.id);
    })
  }

  DeleteAssignmentById(assignmentId) {
    this.aSvc.RemoveAssignmentByID(assignmentId).subscribe(v => {
      this.GetAssignmentsByEndpointId(this.selected.id);
      // this.message.create('success', `Assignment deleted.`);
    })
  }

  DeleteAssignments() {
    for (let index = 0; index < this.selectedEpAssignments.length; index++) {
      this.DeleteAssignmentById(this.selectedEpAssignments[index].id);
    }
  }

  GetAssignmentsByEndpointId(endpointId) {
    this.aSvc.GetAssignmentByEndpointID(endpointId).subscribe(v => {
      this.endpointAssignment = v;
    })
  }

  RouteAssignmentDetails(assignmentId) {
    this.router.navigate(['/assignmentdetails', assignmentId]);
  }

  ClearSelected() {
    if (this.previousSelectedElement != null) {
      this.previousSelectedElement.style = "background:var(--clr-color-action-800);";
      this.selected = null;
      this.selectedEndpointName = "No Endpoint Selected"
      this.endpointAssignment = null;
      this.previousSelectedElement = null;
    }
  }

  SelectAction(item, refEl) {
    if (this.previousSelectedElement != null) {
      this.previousSelectedElement.style = "background:var(--clr-color-action-800);";
    }
    if (this.previousSelectedElement == refEl) {
      this.ClearSelected();
    }
    else {
      this.previousSelectedElement = refEl;
      this.GetAssignmentsByEndpointId(item.id);
      this.selectedEndpointName = item.name;
      this.selected = item;
      refEl.style = "background: #0c7a6f;";
    }
  }

  UpdateAssignmentVisible = false;
  AddNewAssignmentVisible = false;

  handleCancel() {
    this.AddNewAssignmentVisible = false;
    this.UpdateAssignmentVisible = false;
  }

  PostNewAssignment() {
    this.AddNewAssignmentVisible = false;
  }

  // "id": parseInt(id),
  //     "endpointId": parseInt(endpointId),
  //     "assignmentStatusId": parseInt(statusId),
  //     "blueprintId": parseInt(blueprintId)
  UpdateAssignment(id, endpointId, assignmentStatusId, blueprintId) {
    this.aSvc.UpdateAssignmentByID(id, endpointId, assignmentStatusId, blueprintId).subscribe(v => {
      if (blueprintId != "") {
        this.aSvc.PutBlueprintActionsIntoAssignmentActions(id, blueprintId).subscribe(as => {
          this.GetAssignmentsByEndpointId(this.selected.id);
        })
      }
      else {
        this.GetAssignmentsByEndpointId(this.selected.id);
      }
    })
    this.UpdateAssignmentVisible = false;
  }

  showModal(): void {
    this.AddNewAssignmentVisible = true;
  }

  currentAssignmentId = null;
  currentEndpointId = null;
  currentAssignmentStatusId = null;
  currentAssignmentBlueprintId = null;

  selectedBlueprint = null;
  selectedAssignmentStatus = null;

  showUpdateAssignment(currAssignmentId, currentEndpointId, currStatusId, currAssignmentBlueprintId): void {
    this.currentAssignmentId = currAssignmentId;
    this.currentEndpointId = currentEndpointId;
    this.currentAssignmentStatusId = currStatusId;
    this.currentAssignmentBlueprintId = currAssignmentBlueprintId;

    if (currStatusId != null) {
      this.selectedAssignmentStatus = this.listofAssignmentStatus.filter(aStatus => aStatus.id === this.currentAssignmentStatusId)[0];
    }
    else {
      this.selectedAssignmentStatus = this.listofAssignmentStatus.filter(aStatus => aStatus.id === 5)[0];
    }

    if (this.currentAssignmentBlueprintId != null) {
      this.selectedBlueprint = this.listofBlueprints.filter(blue => blue.id === this.currentAssignmentBlueprintId)[0];
    }
    else {
      this.selectedBlueprint = this.listofBlueprints[0];
    }
    this.UpdateAssignmentVisible = true;
  }

  changeAssignmentStatus(aStatus) {
    this.selectedAssignmentStatus = aStatus;
  }
  changeBlueprint(blueprint) {
    this.selectedBlueprint = blueprint;
  }

  multiAction = false;
  selectionChanged(e) {
    this.selectedEpAssignments = e;
    if (e.length >= 1) {
      this.multiAction = true;
    }
    if (e.length == 0) {
      this.multiAction = false;
    }
  }

}

