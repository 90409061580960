import { Component, ViewChild } from '@angular/core';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { GroupsService } from 'src/app/services/groups.service';
import { HostService } from 'src/app/services/host.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { Action } from 'src/app/modules/shared/domain/action';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { IEndPointGroup } from 'src/app/domain/interfaces/iendpointgroup';
import _ from 'lodash';
import { PaginationModel } from 'src/app/domain/interfaces/paginationModel';
import { ActionStatus } from 'src/app/domain/enums/action-status';
import { zip } from 'rxjs';

@Component({
  selector: 'nd-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  currentDate: Date = new Date();
  bugDataVisible: boolean = false;
  assignmentActions: IAssignmentAction[];
  assignmentActionsLength: number;
  pageSizeOptions: number[] = [10, 15, 20];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  endpointCount: number;
  actionCount: number;
  groupCount: number;
  blueprintCount: number;
  paginationModel: PaginationModel;
  pageIsLoading: boolean;
  actionStatus = ActionStatus;

  @ViewChild('pagination') paginator;

  onChangePage(index) {
    const idx = index - 1;
    this.paginationModel.skip = idx * this.paginator.pageSize;
    this.paginationModel.take = this.paginator.pageSize;
    this.itemsPerPage = this.paginator.pageSize;
    this.assignmentActions = [];
    this.getAssignmentActions();
  }

  constructor(
    private hostService: HostService,
    private actionService: ActionService,
    private groupService: GroupsService,
    private blueprintService: BlueprintService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.getAssignmentActions();
    this.getCounts();
    this.paginationModel = { skip: 0, take: this.itemsPerPage };
  }

  routeTo(event, pageName) {
    event.stopPropagation();
    event.preventDefault();
    this.router.navigate([pageName], { relativeTo: this.activatedRoute });
  }

  getCounts() {
    const observables = [
      this.hostService.GetCount(),
      this.actionService.GetCount(),
      this.blueprintService.GetCount(),
      this.groupService.GetCount(),
    ];

    zip(...observables).subscribe((data) => {
      this.endpointCount = data[0];
      this.actionCount = data[1];
      this.blueprintCount = data[2];
      this.groupCount = data[3];
    });
  }

  getAssignmentActions() {
    this.pageIsLoading = true;
    this.hostService
      .GetAssignmentActions(this.paginationModel)
      .subscribe((v) => {
        this.assignmentActionsLength = v.length;
        this.assignmentActions = v.items;
        this.pageIsLoading = false;
      });
  }
}
