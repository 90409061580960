<div class="main-wrapper-expandable-package-item">
    <div class="unexpanded-content" (click)="onToggleExpand()">
        <div class="title">
            <h4>{{ action.name }}</h4> <span> ({{ action.actionVersion.length }})</span>
        </div>
        <cds-icon shape="angle" [size]="'md'" style="--color: var(--ndp-brand-color)"
            direction="{{ expanded ? 'down' : 'right' }}">
        </cds-icon>
    </div>
    <div *ngIf="expanded" class="expanded-content">
        <div *ngIf="!action.actionVersion.length">
            There are no available version for this package
        </div>
        <ng-container *ngIf="action.actionVersion.length">
            <nd-package-version-card *ngFor="let v of action.actionVersion" [hasButton]="hasButtons" [version]="v"
                (dropEvent)="drop($event)" (moveEvent)="move([$event])"></nd-package-version-card>
            <ng-container *ngIf="hasButtons">
                <hr />
                <button if="move-all-button" type="button" class="btn btn-info-outline py-0 m-0"
                    (click)="move(action.actionVersion)">
                    <cds-icon shape="export" [size]="'sm'"></cds-icon> <span> {{moveAllButtonText}}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>