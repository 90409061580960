import { Injectable } from '@angular/core';
import { Application } from '../domain/interfaces/application';
import { HttpClient } from '@angular/common/http';
import { ApplicationEndpoint } from '../domain/interfaces/application-endpoint';
import { map, } from 'rxjs/operators';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  URL: string = this.localStorageService.apiUrl;

  applications$ = this.http.get<Application[]>(`${this.URL}/application`).pipe(
    map((applications) =>
      applications.map((application) => ({
        ...application,
        applicationVersions: application.applicationVersions.sort((a, b) =>
          a.version > b.version ? -1 : 1
        ),
      }))
    )
  );

  applicationEndpoints$ = (applicationId: number) => this.http.get<ApplicationEndpoint[]>(`${this.URL}/application/${applicationId}/endpoints`);

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }
}
