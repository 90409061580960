import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { PackageCleanupConfiguration } from '../models/package-cleanup-configuration';
import { VersionCleanupStatusDto } from '../models/version-cleanup-status-dto';

@Injectable({
  providedIn: 'root',
})
export class PackageCleanupService {
  baseUrl: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getPackageCleanupConfiguration(): Observable<PackageCleanupConfiguration> {
    return this.http.get<PackageCleanupConfiguration>(
      `${this.baseUrl}/packageCleanupConfiguration`
    );
  }

  updatePackageCleanupConfiguration(
    configuration: PackageCleanupConfiguration
  ): Observable<PackageCleanupConfiguration> {
    return this.http.put<PackageCleanupConfiguration>(
      `${this.baseUrl}/packageCleanupConfiguration`,
      configuration
    );
  }

  getWhatIfConfiguration(
    configuration: PackageCleanupConfiguration
  ): Observable<VersionCleanupStatusDto[]> {
    return this.http.get<VersionCleanupStatusDto[]>(
      `${this.baseUrl}/packageCleanupConfiguration/what-if`,
      {
        params: {
          recycleBinRetentionPeriodInDays:
            configuration.recycleBinRetentionPeriodInDays.toString(),
          versionCountToKeep: configuration.versionCountToKeep.toString(),
          isAutomaticCleanupOn: configuration.isAutomaticCleanupOn.toString(),
        },
      }
    );
  }
}
