<!-- <button type="button" class="btn btn-icon btn-primary" title="Add new Blueprint" style="margin-bottom: 0px;" (click)="showModal()"><clr-icon shape="plus"></clr-icon></button> -->

<div class="card table-container-card">
  <div class="table-heading">
    <div class="table-title">
      <cds-icon shape="applications" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Blueprints</h3>
    </div>
    <div class="action-group">
      <button type="button" class="btn btn-icon btn-success btn-md" title="Add new Blueprint"
        (click)="showAddNewBlueprintModal()">
        <img src="../../../assets/icons/plus.svg" #tooltip="matTooltip" matTooltip="Add Blueprint"
          [matTooltipPosition]="'above'" matTooltipHideDelay="100" />
      </button>
      <button [disabled]="selectedBlueprints.length!=1" class="action-item" class="btn btn-icon btn-primary  btn-md"
        (click)="showRenameTemplateModal(selectedBlueprints)" #tooltip="matTooltip" matTooltip="Rename Blueprint"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/edit.svg" />
      </button>
      <button [disabled]="1>selectedBlueprints.length" class="action-item" class="btn btn-icon btn-primary  btn-md"
        (click)="CloneBlueprint(selectedBlueprints)" #tooltip="matTooltip" matTooltip="Clone Blueprint"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/add.svg" />
      </button>
      <button [disabled]="selectedBlueprints.length==0" class="action-item" class="btn btn-icon btn-danger  btn-md"
        (click)="openDeleteModal = true" #tooltip="matTooltip" matTooltip="Delete Blueprint"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/trash.svg" />
      </button>
    </div>
  </div>

  <clr-datagrid class="datagrid-compact" [clrDgSelected]="selectedBlueprints"
    (clrDgSelectedChange)="blueprintSelectionChanged($event)" [clrDgLoading]="blueprintsLoading" id="my-datagrid">
    <clr-dg-column [clrDgField]="'name'" [style.width.px]="250">Blueprint</clr-dg-column>
    <clr-dg-column>Description</clr-dg-column>
    <clr-dg-row *clrDgItems="let data of listOfDisplayData" [clrDgItem]="data">
      <clr-dg-cell [contextMenu]="itemMenu" [contextMenuSubject]="data" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">{{data.name}}</clr-dg-cell>
      <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{data.description}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[10,15,20,30,45,60,75,90,100]">Blueprints per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} blueprints
      </clr-dg-pagination>
    </clr-dg-footer>
    <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
      <clr-dg-detail>
        <clr-dg-detail-header>
          <div style="display: flex; justify-content: space-between;">
            <div>
              <div class="btn-group btn-icon">
                <button (click)="RouteTemplateDetails(detail.id)" type="button" class="btn btn-icon btn-success btn-md"
                  #tooltip="matTooltip" matTooltip="Edit Blueprint Actions" [matTooltipPosition]="'above'"
                  matTooltipHideDelay="100">
                  <clr-icon shape="note"></clr-icon>
                </button>
              </div>
              {{detail.name}}
            </div>
            <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
              <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
            </button>
          </div>
          
        </clr-dg-detail-header>

        <clr-dg-detail-body>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink>Packages</button>
              <clr-tab-content *clrIfActive="true">
                <clr-datagrid class="datagrid-compact" [clrDgLoading]="isLoadingDetails">
                  <clr-dg-column>Package</clr-dg-column>
                  <clr-dg-column>Reboot</clr-dg-column>
                  <clr-dg-row *clrDgItems="let blueprint of viewListOfActions" [clrDgItem]="blueprint.action">
                    <clr-dg-cell>{{blueprint.action.name}}</clr-dg-cell>
                    <clr-dg-cell><input type="checkbox" clrCheckbox [(ngModel)]="blueprint.isRebootRequired"
                        (change)="updateBlueprintAction(blueprint)" /></clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="100">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15,30,45,60,75,90,100]">Packages per
                        page</clr-dg-page-size>
                      {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}}
                      Packages
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>

<clr-modal [(clrModalOpen)]="addNewTemplateVisible">
  <h3 class="modal-title">New Blueprint</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Blueprint Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="newBlueprintName" />
      </clr-input-container>
      <clr-input-container>
        <label>Blueprint Description</label>
        <input clrInput placeholder="Enter Description here!" name="description"
          [(ngModel)]="newBlueprintDescription" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelNewBlueprint()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkNewBlueprint()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="renameTemplateVisible">
  <h3 class="modal-title">Rename blueprint</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Blueprint Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="renameBlueprintName" />
      </clr-input-container>
      <clr-input-container>
        <label>Blueprint Description</label>
        <input clrInput placeholder="Enter Description here!" name="description"
          [(ngModel)]="renameBlueprintDescription" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelRenameBlueprint()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkRenameBlueprint()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="viewTemplateDataVisible">
  <h3 class="modal-title">View Blueprint Actions</h3>
  <div class="modal-body">
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>ID</clr-dg-column>
      <clr-dg-column>Name</clr-dg-column>

      <clr-dg-row *clrDgItems="let blueprint of viewListOfActions" [clrDgItem]="blueprint.action">
        <clr-dg-cell>{{blueprint.action.id}}</clr-dg-cell>
        <clr-dg-cell>{{blueprint.action.name}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
          <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Actions per page</clr-dg-page-size>
          {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}} Actions
        </clr-dg-pagination>

      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelTemplateActions()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkTemplateActions()">Ok</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="openDeleteModal">
  <h3 class="modal-title">Delete Blueprints?</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeDeleteModal()">No</button>
    <button type="button" class="btn btn-primary" (click)="DeleteBlueprint(selectedBlueprints)">Yes</button>
  </div>
</clr-modal>
<app-notify-me #cmp></app-notify-me>

<context-menu #itemMenu>
  <ng-template contextMenuItem let-item (execute)="RouteTemplateDetails($event.item.id)">
    <p style="margin: 0px 10px 0px 10px;">Edit {{item.name}} Actions</p>
  </ng-template>
</context-menu>