<div *ngIf="dataIsLoading" class="loading d-flex align-items-center justify-content-center p-3">
  <span class="spinner spinner-md"></span>
</div>
<clr-datagrid *ngIf="!dataIsLoading">
  <clr-dg-column>Package Name</clr-dg-column>
  <clr-dg-column *ngFor="let tenant of tenants">{{ tenant.name }}</clr-dg-column>

  <clr-dg-row *clrDgItems="let sub of subscriptionStatuses">
    <clr-dg-cell>{{ sub.action.name }}</clr-dg-cell>
    <clr-dg-cell *ngFor="let status of sub.statuses" class="text-center py-1">
      <cds-icon *ngIf="status.isSubscribed" class="cursor-pointer" matTooltip="Subscribed" shape="success-standard"
        status="success" size="md" solid></cds-icon>
      <cds-icon *ngIf="!status.isSubscribed" class="cursor-pointer" matTooltip="Not Subscribed" shape="times-circle"
        size="md" solid style="--color: #CCCCCC;"></cds-icon>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10,15,30,45,60,75,100]">Packages per page</clr-dg-page-size>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Packages
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>