import { Injectable } from '@angular/core';
import { EndpointTableColumnVisibility } from '../../../domain/interfaces/endpoint-table-column-visibility';
import { environment } from 'src/environments/environment';

const THEME_KEY = 'THEME_KEY';
const ENDPOINT_TABLE_COLUMNS_VISIBILITY_KEY = 'endpointTableColumnsVisibility';
const API_URL = 'API_URL';
const TENANT_NAME = 'TENANT_NAME';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  set isLightTheme(value: boolean) {
    this.set(THEME_KEY, value);
  }

  get isLightTheme(): boolean | null {
    return this.get<boolean>(THEME_KEY);
  }

  set endpointTableColumnsVisibility(value: EndpointTableColumnVisibility) {
    this.set(ENDPOINT_TABLE_COLUMNS_VISIBILITY_KEY, value);
  }

  get endpointTableColumnsVisibility(): EndpointTableColumnVisibility | null {
    return this.get<EndpointTableColumnVisibility>(
      ENDPOINT_TABLE_COLUMNS_VISIBILITY_KEY
    );
  }

  set apiUrl(value: string) {
    this.set(API_URL, value);
  }

  get apiUrl(): string {
    const url = this.get<string>(API_URL);
    if (!url) return environment.api.apiUrl;
    return url;
  }

  set tenantName(value: string) {
    this.set(TENANT_NAME, value);
  }

  get tenantName(): string | null {
    const name = this.get<string>(TENANT_NAME);
    if (!name) return null;
    return name;
  }

  private get<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (item) return JSON.parse(item);
    return null;
  }

  private set(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }
}
