import { CommandSpecification } from './command-specification';

export class Command {
  specification: CommandSpecification;
  parameters: Parameter[];

  constructor(specification: CommandSpecification, parameters: Parameter[]) {
    this.specification = specification;
    this.parameters = parameters;
  }

  toPowershell(): string {
    return this.parameters.reduce((comm, param) => {
      const paramType = this.specification.parameters.filter(
        (p) => p.name === param.name
      )[0].type;
      switch (paramType) {
        case 'string':
        case 'string-select':
          if (param.value !== '' && !param.value) break;
          return `${comm} -${param.name} "${param.value}"`;
        case 'number':
          if (!param.value) break;
          return `${comm} -${param.name} ${param.value}`;
        case 'boolean':
          return `${comm} -${param.name} $${!!param.value}`;
        case 'array':
          if (!param.value) break;
          return `${comm} -${param.name} @(${param.value})`;
        case 'scriptblock':
          if (!param.value) break;
          return `${comm} -${param.name} {${param.value}}`;
        case 'switch':
          if (!param.value) break;
          return `${comm} -${param.name}`;
        case 'path':
          if (!param.value) break;
          return `${comm} -${param.name} (Force-Resolve-Path "${param.value}")`;
        default:
          throw Error(`Invalid Param Type: ${paramType}`);
      }
      return comm;
    }, this.specification.name);
  }
}

export interface Parameter {
  name: string;
  value?: string | boolean;
}
