import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEndPoint } from '../domain/interfaces/iendpoint';
import { AssignmentActionsDTO } from '../domain/interfaces/iassignmentlist';
import { PaginationModel } from '../domain/interfaces/paginationModel';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HostService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  GetEndPoints() {
    return this.http.get<IEndPoint[]>(`${this.URL}/endpoint`);
  }

  GetCount(): Observable<number> {
    return this.http.get<number>(`${this.URL}/endpoint/count`);
  }

  GetAssignmentActions(filter: PaginationModel = { skip: 0, take: 0 }) {
    return this.http.get<AssignmentActionsDTO>(`${this.URL}/assignmentAction`, {
      params: <any>filter,
    });
  }

  GetSingleEndPoint(id: number) {
    return this.http.get<IEndPoint>(`${this.URL}/endpoint/${id}`);
  }

  GetEndPointByName(name: string) {
    return this.http.get<IEndPoint>(`${this.URL}/endpoint/name/${name}`);
  }

  PostSingleEndPoint(name: string, endpointstatusid) {
    var data = { name: name, endpointstatusid: parseInt(endpointstatusid) };
    return this.http.post<IEndPoint>(`${this.URL}/endpoint`, data);
  }

  RemoveSingleEndPointByID(id) {
    return this.http.delete<IEndPoint>(`${this.URL}/endpoint/${id}`);
  }

  UpdateSingleEndpointForGroups(data) {
    return this.http.put<IEndPoint>(`${this.URL}/endpoint/${data.id}`, data);
  }

  UpdateSingleEndpoint(data) {
    return this.http.put<IEndPoint>(`${this.URL}/endpoint/${data.id}`, data);
  }

  AddTagToEndpointByEndpointID(endpointId, data) {
    return this.http.put<IEndPoint>(
      `${this.URL}/endpoint/tags/${endpointId}`,
      data
    );
  }
}
