import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { User } from '../domain/interfaces/user';
import { Observable } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  defaultScope = 'api://74b18863-387c-44ec-b88d-1632efebbf17/Tenant.ReadWrite';

  constructor(private msalService: MsalService) {}

  public get currentUserValue(): User | null {
    const accountInfo = this.msalService.instance.getActiveAccount();
    if (accountInfo) {
      return {
        username: accountInfo.username ?? 'logged out',
        name: accountInfo.name,
        claims: accountInfo.idTokenClaims,
      };
    } else {
      return null;
    }
  }

  getToken(): Observable<string> {
    return this.msalService
      .acquireTokenSilent({
        scopes: [this.defaultScope],
      })
      .pipe(map((auth) => auth.accessToken));
  }

  logInWithUsername(
    username: string,
    password: string
  ): Observable<AuthenticationResult> {
    throw new Error('USERNAME AND PASSWORD LOGIN IS UNSUPPORTED');
  }

  logInWithAzure(): Observable<AuthenticationResult> {
    return this.msalService.loginPopup().pipe(
      tap((result) => {
        this.msalService.instance.setActiveAccount(result.account);
      })
    );
  }

  logOut(): void {
    this.msalService.instance.setActiveAccount(null);
  }
}
