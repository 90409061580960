import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/modules/shared/services/local-storage.service';

@Component({
  selector: 'nd-download-msi-installer',
  templateUrl: './download-msi-installer.component.html',
  styleUrls: ['./download-msi-installer.component.scss'],
})
export class DownloadMsiInstallerComponent implements OnInit {
  url = `${this.localStorageService.apiUrl}/blob/msi-installer`;
  downloadUrl: string;
  isLoading = true;

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.getMsiDownloadUrl();
  }

  getMsiDownloadUrl() {
    this.httpClient.get<string>(this.url).subscribe({
      next: (data) => {
        this.downloadUrl = data;
        this.isLoading = false;
      },
    });
  }
}
