<p class="mb-5">Make sure you have your Azure subscription ready!</p>
<form clrForm [formGroup]="subscriptionInformationForm" (change)="validateForm()" autocomplete="off">
    <clr-input-container>
        <label for="subscriptionName" class="required-label">Subscription Name</label>
        <input type="text" formControlName="subscriptionName" placeholder="Subscription Name" clrInput />
        <clr-control-error>Enter your Azure subscription name.</clr-control-error>
    </clr-input-container>
    <clr-input-container>
        <label for="subscriptionId" class="required-label">Subscription ID</label>
        <input type="text" formControlName="subscriptionId" placeholder="00000000-0000-0000-0000-000000000000"
            clrInput />
        <clr-control-error *clrIfError="'required'">Enter your subscription ID.</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">Invalid subscription ID. Must be a valid GUID.</clr-control-error>
    </clr-input-container>
</form>