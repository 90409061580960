import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '../../shared/domain/action';
import { compareVersions } from '../../shared/utils/version-compare';

@Component({
  selector: 'ps-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent {
  @Output() onSubscribed = new EventEmitter<Action>();
  @Input() action: Action;

  lastVersion = () =>
    this.action.actionVersion.sort(
      (av1, av2) => -compareVersions(av1.version, av2.version)
    )[0];

  constructor() {}

  onSubscribe() {
    this.onSubscribed.emit(this.action);
  }
}
