<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <msp-dashboard-card header="{{newCustomers}} Customers" subheader="New Customers" icon="users" badge="info"
          badgeColor="var(--ndp-brand-color)"></msp-dashboard-card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <msp-dashboard-card header="{{totalCustomers}} Customers" subheader="Total Customers" icon="users">
        </msp-dashboard-card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <msp-dashboard-card header="{{totalActionCount}} Packages" subheader="Total Packages" icon="application">
        </msp-dashboard-card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <msp-dashboard-card header="{{commonActionCount}} Packages" subheader="Common Packages" icon="share">
        </msp-dashboard-card>
      </div>
    </div>

    <div class="d-flex gap-4 flex-lg-row flex-md-column">
      <div class="card table-container">
        <div class="card-header card-header-warning">
          <h4 class="card-title">Package Subscription Status</h4>
          <p class="card-category">
            Package subscription statuses as of today,
            {{ currentDate | date : "dd-MM-yyyy, h:mm a" }}
          </p>
        </div>
        <div class="p-3">
          <msp-package-subscription-status-table></msp-package-subscription-status-table>
        </div>
      </div>
      <div class="card table-container">
        <div class="card-header card-header-warning">
          <h4 class="card-title">Last modified Packages</h4>
          <p class="card-category">
            Last modified Packages as of today,
            {{ currentDate | date : "dd-MM-yyyy, h:mm a" }}
          </p>
        </div>
        <div class="p-3">
          <nd-last-modified-packages></nd-last-modified-packages>
        </div>
      </div>
    </div>
  </div>
</div>