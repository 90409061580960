import { CommandSpecification } from './models/command-specification';

const copyNdFileCommand: CommandSpecification = {
  name: 'Copy-ND-File',
  description: 'Copy a file to a directory.',
  icon: 'copy',
  parameters: [
    {
      name: 'FilePath',
      description: 'The path of the file that has to be copied.',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'Destination',
      description: 'The path where the file has to be copied to.',
      type: 'path',
      isRequired: true,
    },
  ],
};

const addNdShortcutCommand: CommandSpecification = {
  name: 'Add-ND-Shortcut',
  description: 'Add a public desktop and/or a start menu shortcut.',
  icon: 'pop-out',
  parameters: [
    {
      name: 'TargetPath',
      description: 'Path of the file that will get a shortcut.',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'Name',
      description: 'Name of the shortcut.',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'StartMenu',
      description: 'Whether or not to make a start menu shortcut.',
      type: 'boolean',
      isRequired: false,
    },
    {
      name: 'Desktop',
      description: 'Whether or not to make a desktop shortcut.',
      type: 'boolean',
      isRequired: false,
    },
    {
      name: 'WorkingDirectory',
      description:
        'Specifies the path in which the running executable will look for and read files.',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'IconLocation',
      description: 'Specifies the path of the icon for the new shortcut.',
      type: 'path',
      isRequired: false,
    },
  ],
};

const importNdFontsCommand: CommandSpecification = {
  name: 'Import-ND-Fonts',
  description: 'Copies and registers Fonts from a folder',
  icon: 'import',
  parameters: [
    {
      name: 'Path',
      description: 'Source Path containing the font files',
      type: 'path',
      isRequired: true,
    },
  ],
};

const importNdRegKeyFromFileCommand: CommandSpecification = {
  name: 'Import-ND-RegKeyFromFile',
  description: 'Silently import a registry file',
  icon: 'import',
  parameters: [
    {
      name: 'Path',
      type: 'path',
      isRequired: true,
    },
  ],
};

const installNdLanguagePackCommand: CommandSpecification = {
  name: 'Install-ND-LanguagePack',
  description: 'Installs a windows language pack',
  icon: 'install',
  parameters: [
    {
      name: 'Path',
      description: 'Path to the cabinet file',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'LanguageCode',
      description: 'Path to the cabinet file',
      type: 'path',
      isRequired: true,
    },
  ],
};

const invokeNdPsScriptCommand: CommandSpecification = {
  name: 'Invoke-ND-PSScript',
  description: 'Runs a powershell script file',
  icon: 'file-group',
  parameters: [
    {
      name: 'File',
      description: 'Path to the powershell script file',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'Arguments',
      description: 'Additional arguments you want to provide for the script',
      type: 'string',
      isRequired: false,
    },
  ],
};

const registerNdLibraryFileCommand: CommandSpecification = {
  name: 'Register-ND-LibraryFile',
  description: 'Registers OCX or DLL files from a single file or folder.',
  icon: 'file-group',
  parameters: [
    {
      name: 'Folder',
      description: 'Path to folder containing OCX or DLL files',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'File',
      description: 'Path to OCX or DLL files',
      type: 'path',
      isRequired: false,
    },
  ],
};

const runTaskCommand: CommandSpecification = {
  name: 'RunTask',
  description: 'Run the functions and output what the status of the task is.',
  icon: 'tasks',
  parameters: [
    {
      name: 'TaskName',
      description: 'Name of the Task that gets executed',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'TaskParameters',
      description: 'The Parameters required to execute the Task',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'UsedFunction',
      description:
        'The Internal fuction that gets used alongside the TaskParameters',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'ScriptBlock',
      type: 'scriptblock',
      isRequired: false,
    },
  ],
};

const setNdAclPermissionCommand: CommandSpecification = {
  name: 'Set-ND-AclPermission',
  description: 'Sets ACL Permissions to a file, folder or registry key',
  icon: 'pop-out',
  parameters: [
    {
      name: 'Action',
      description:
        'Specifies the action to add or remove an ACL Permission. Available Options: Add, Remove',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'Path',
      description: 'Specifies the file or path name',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'ADObject',
      description: 'Specifies the Active Directory Object',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'Permission',
      description:
        'Specifies the ACL permissions Available Options: FullControl, Modify, ReadAndExecute, Write, ReadKey Note: Only FullControl or ReadKey are accepted as paramater for a registry modification',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'AccessType',
      description: 'Specifies the Access Type',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'AccessRule',
      type: 'string',
      isRequired: false,
    },
  ],
};

const setNdDismCommand: CommandSpecification = {
  name: 'Set-ND-Dism',
  icon: 'pop-out',
  parameters: [
    {
      name: 'Arguments',
      type: 'string',
      isRequired: true,
    },
  ],
};

const setNdFileSystemCommand: CommandSpecification = {
  name: 'Set-ND-FileSystem',
  description: 'Create/delete a file or a folder',
  icon: 'pop-out',
  parameters: [
    {
      name: 'ActionType',
      description:
        'Determines whether a file/folder gets created or deleted. Options are: Create, Delete',
      type: 'string-select',
      isRequired: false,
      options: ['Create', 'Delete'],
    },
    {
      name: 'ItemType',
      description:
        'Directory or File can be specified as Type of the new object',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'Name',
      type: 'string',
      description: 'Name of the object',
      isRequired: false,
    },
    {
      name: 'Path',
      type: 'path',
      description: 'Destination Path',
      isRequired: false,
    },
  ],
};

const setNdOwnershipCommand: CommandSpecification = {
  name: 'Set-ND-OwnerShip',
  description: 'Takes ownership of a registry key, folder or file',
  icon: 'pop-out',
  parameters: [
    {
      name: 'SubKey',
      description: 'Sub Registry Key',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'BaseKey',
      description:
        'Base Registry Key : ClassesRoot, CurrentConfig, CurrentUser, LocalMachine, Users, PerformanceData',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'Path',
      description: 'Path to folder or file',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'Identity',
      type: 'string',
      description: 'Account or group to set the ownership to',
      isRequired: true,
    },
    {
      name: 'TargetType',
      type: 'string',
      description: 'Targets Registry or FileSystem',
      isRequired: true,
    },
  ],
};

const setNdPrintDriverCommand: CommandSpecification = {
  name: 'Set-ND-OwnerShip',
  description:
    'Installs the print package driver in the printer catalog and local print server',
  icon: 'pop-out',
  parameters: [
    {
      name: 'DriverName',
      description: 'Name of the driver to register in the local print server',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'Path',
      description: 'Path to .inf driver',
      type: 'path',
      isRequired: true,
    },
  ],
};

const setNdRegistryCommand: CommandSpecification = {
  name: 'Set-ND-Registry',
  description: 'Sets a registry value',
  icon: 'pop-out',
  parameters: [
    {
      name: 'Path',
      description: 'Path containing the Registry Key',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'Name',
      description: 'Value name',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'Data',
      description: 'Registry key Value',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'Action',
      description: 'Options: Add or Remove ',
      type: 'string-select',
      isRequired: false,
      options: ['Add', 'Remove'],
    },
  ],
};

const setNdScheduledTaskCommand: CommandSpecification = {
  name: 'Set-ND-ScheduledTask',
  description: 'Set a pre-existing Scheduled Task.',
  icon: 'pop-out',
  parameters: [
    {
      name: 'TaskName',
      description: 'Input Scheduled task name that has to be disabled',
      type: 'string',
      isRequired: false,
    },
    {
      name: 'TaskPath',
      description:
        'Input Scheduled task path that has to be disabled. This will disable every tasks in that path.',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'Disable',
      description:
        'Input Scheduled task path that has to be disabled. This will disable every tasks in that path.',
      type: 'boolean',
      isRequired: false,
    },
  ],
};

const registerNdScheduledTaskCommand: CommandSpecification = {
  name: 'Register-ND-ScheduledTask',
  icon: 'file-group',
  parameters: [
    {
      name: 'Name',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'Action',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'Trigger',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'Principal',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'LogonType',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'RunLevel',
      type: 'string',
      isRequired: true,
    },
  ],
};

const setNdServiceCommand: CommandSpecification = {
  name: 'Set-ND-Service',
  description: 'Change the status and the startup of a service.',
  icon: 'pop-out',
  parameters: [
    {
      name: 'Name',
      description: 'The name of the service',
      type: 'string',
      isRequired: true,
    },
    {
      name: 'StartupType',
      description:
        'The name of the file that has to be copied. Options: Automatic, Disabled, Manual',
      type: 'string-select',
      isRequired: false,
      options: ['Automatic', 'Disabled', 'Manual'],
    },
    {
      name: 'Status',
      description:
        'The name of the file that has to be copied. Options: Paused, Running, Stopped',
      type: 'string-select',
      isRequired: false,
      options: ['Paused', 'Running', 'Stopped'],
    },
  ],
};

const syncNdFolderCommand: CommandSpecification = {
  name: 'Sync-ND-Folder',
  description:
    'Checks all the files in the source and destination directory and will copy only the files not yet present in the destination directory. Function can handle UNC paths.',
  icon: 'sync',
  parameters: [
    {
      name: 'sourcePath',
      type: 'path',
      isRequired: true,
    },
    {
      name: 'destinationPath',
      type: 'path',
      isRequired: true,
    },
  ],
};

const unregisterNdLibraryFileCommand: CommandSpecification = {
  name: 'Unregister-ND-LibraryFile',
  description:
    'Unregisters OCX or DLL files from a single file or folder. Can be used interchangeably with the Alias Unregister-ND-DLL and Unregister-ND-OCX. Makes use of Regsvr32, description for regsvr tbd.',
  icon: 'uninstall',
  parameters: [
    {
      name: 'Folder',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'File',
      type: 'path',
      isRequired: false,
    },
  ],
};

export const installChocolateyInstallPackage: CommandSpecification = {
  name: 'Install-ChocolateyInstallPackage',
  description: 'Installs software into "Programs and Features".',
  icon: 'install',
  parameters: [
    {
      name: 'PackageName',
      description:
        "The name of the package - while this is an arbitrary value, it's recommended that it matches the package id.",
      type: 'string',
      isRequired: true,
    },
    {
      name: 'FileType',
      description:
        "This is the extension of the file. This can be 'exe', 'msi', or 'msu'.",
      type: 'string',
      isRequired: false,
    },
    {
      name: 'SilentArgs',
      description:
        "Try any of the to get the silent installer - /s /S /q /Q /quiet /silent /SILENT /VERYSILENT. With msi it is always /quiet. Please pass it in still but it will be overridden by Chocolatey to /quiet. If you don't pass anything it could invoke the installer with out any arguments. That means a nonsilent installer.",
      type: 'string',
      isRequired: false,
    },
    {
      name: 'File',
      description:
        'Full file path to native installer to run. If embedding in the package, you can get it to the path with "$(Split-Path -parent $MyInvocation.MyCommand.Definition)\\INSTALLER_FILE"',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'File64',
      description:
        'Full file path to a 64-bit native installer to run. If embedding in the package, you can get it to the path with "$(Split-Path -parent $MyInvocation.MyCommand.Definition)\\INSTALLER_FILE". Provide this when you want to provide both 32-bit and 64-bit installers or explicitly only a 64-bit installer (which will cause a package install failure on 32-bit systems).',
      type: 'path',
      isRequired: false,
    },
    {
      name: 'ValidExitCodes',
      description: 'Array of exit codes indicating success. Defaults to @(0).',
      type: 'array',
      isRequired: false,
    },
    {
      name: 'UseOnlyPackageSilentArguments',
      description:
        'Do not allow choco to provide/merge additional silent arguments and only use the ones available with the package.',
      type: 'switch',
      isRequired: false,
    },
    {
      name: 'IgnoredArguments',
      description:
        'Allows splatting with arguments that do not apply. Do not use directly.',
      type: 'string',
      isRequired: false,
    },
  ],
};

const installNdAppxPackageCommand: CommandSpecification = {
  name: 'Install-ND-AppxPackage',
  description:
    'Adds an app package that will install for each new user to a Windows image',
  icon: 'install',
  parameters: [
    {
      name: 'Path',
      description: 'Path to installer',
      type: 'path',
      isRequired: true,
    },
  ],
};

const removeNdInstallFilesCommand: CommandSpecification = {
  name: 'Remove-ND-InstallFiles',
  description:
    'Removes the install files from the nupkg extracted location and nupkg file',
  icon: 'trash',
  parameters: [
    {
      name: 'packageName',
      description: 'Name of the package',
      type: 'string',
      isRequired: false,
    },
  ],
};

export const commands: CommandSpecification[] = [
  installChocolateyInstallPackage,
  installNdAppxPackageCommand,
  addNdShortcutCommand,
  copyNdFileCommand,
  importNdFontsCommand,
  importNdRegKeyFromFileCommand,
  installNdLanguagePackCommand,
  invokeNdPsScriptCommand,
  registerNdLibraryFileCommand,
  removeNdInstallFilesCommand,
  unregisterNdLibraryFileCommand,
  runTaskCommand,
  setNdAclPermissionCommand,
  setNdDismCommand,
  setNdFileSystemCommand,
  setNdOwnershipCommand,
  setNdPrintDriverCommand,
  setNdRegistryCommand,
  setNdScheduledTaskCommand,
  registerNdScheduledTaskCommand,
  setNdServiceCommand,
  syncNdFolderCommand,
];
