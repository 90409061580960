import { Component, OnInit, ViewChild } from '@angular/core';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { PaginationModel } from 'src/app/domain/interfaces/paginationModel';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Stage } from '../../domain/enums/stage';
import { ActionVersionDto } from 'src/app/domain/dtos/action-version-dto';

@Component({
  selector: 'nd-last-modified-packages',
  templateUrl: './last-modified-packages.component.html',
  styleUrls: ['./last-modified-packages.component.scss'],
})
export class LastModifiedPackagesComponent implements OnInit {
  isMsp = environment.isMsp;
  mspDashboard = false;

  dataIsLoading: boolean;
  stagesEnum = Stage;
  actionVersions: ActionVersionDto[];
  actionVersionsCount: number;

  paginationModel: PaginationModel;
  pageSizeOptions = [10, 15, 20];
  currentPage = 1;
  itemsPerPage = 10;
  @ViewChild('lastModifiedTablePagination') paginator: { pageSize: number; };

  constructor(
    private actionVersionService: ActionversionService,
    private activatedRoute: ActivatedRoute
  ) {
    this.getActionVersions();
    this.paginationModel = { skip: 0, take: this.itemsPerPage };
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.url[0].path === 'msp' && this.isMsp) {
      this.mspDashboard = true;
    } else {
      this.mspDashboard = false;
    }
  }

  onChangePage(index) {
    this.paginationModel.skip = (index - 1) * this.paginator.pageSize;
    this.paginationModel.take = this.paginator.pageSize;
    this.itemsPerPage = this.paginator.pageSize;
    this.actionVersions = [];
    this.getActionVersions();
  }

  getActionVersions() {
    this.dataIsLoading = true;
    this.actionVersionService
      .GetActionVersions(this.paginationModel)
      .subscribe((data) => {
        console.log(data.actionVersions);
        this.actionVersions = data.actionVersions;
        this.actionVersionsCount = data.count;
        this.dataIsLoading = false;
      });
  }
}
