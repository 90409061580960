import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  lastToken?: string;

  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authenticationService.getToken().pipe(
      switchMap((token) => {
        this.lastToken = token;
        const newRequest = this.appendTokenToRequest(request, token);
        return next.handle(newRequest);
      }),
      catchError((_) => {
        const newRequest = this.appendTokenToRequest(
          request,
          this.lastToken ?? ''
        );
        return next.handle(newRequest);
      })
    );
  }

  appendTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }
}
