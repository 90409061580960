import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecycleBinComponent } from './components/recycle-bin/recycle-bin.component';
import { ClarityModule } from '@clr/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { ConfigureComponent } from './components/configure/configure.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [RecycleBinComponent, ConfigureComponent],
  imports: [
    CommonModule,
    ClarityModule,
    MatTooltipModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [RecycleBinComponent, ConfigureComponent],
})
export class PackageCleanupModule {}
