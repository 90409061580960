import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MspGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.isMsp) {
      this.localStorageService.apiUrl = environment.api.apiUrl;
      this.localStorageService.tenantName = environment.tenantName;
      return true;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
