import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClarityIcons, checkIcon, searchIcon } from '@cds/core/icon';
import { PackageSubscriptionPageComponent } from './package-subscription-page/package-subscription-page.component';
import { ApplicationCardComponent } from './application-card/application-card.component';
import { SharedModule } from '../shared/shared.module';

ClarityIcons.addIcons(searchIcon, checkIcon);
@NgModule({
  declarations: [PackageSubscriptionPageComponent, ApplicationCardComponent],
  imports: [FormsModule, SharedModule],
  exports: [PackageSubscriptionPageComponent],
})
export class PackageSubscriptionModule {}
