import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAssignmentAction } from '../domain/interfaces/iassignmentlist';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssignmentActionService {

  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  GetAssignmentAction() {
    return this.http.get<IAssignmentAction>(`${this.URL}/Assignmentaction`);
  }

  GetAssignmentActionByID(id) {
    return this.http.get<IAssignmentAction>(`${this.URL}/Assignmentaction/${id}`);
  }

  GetAssignmentActionByAssignmentID(id: number): Observable<IAssignmentAction[]> {
    return this.http.get<IAssignmentAction[]>(`${this.URL}/Assignmentaction/assignment/${id}`);
  }

  PutAssignmentActions(data, assignmentId) {
    return this.http.put<IAssignmentAction>(`${this.URL}/Assignmentaction/actions/${assignmentId}`, data);
  }

  RemoveSingleAssignmentActionByID(id) {
    return this.http.delete<IAssignmentAction>(`${this.URL}/Assignmentaction/${id}`);
  }

  PostSingleAssignmentAction(statusId, actionId, assignmentId, order) {
    var data = {
      "statusId": parseInt(statusId),
      "actionId": parseInt(actionId),
      "assignmentId": parseInt(assignmentId),
      "order": parseInt(order)
    };
    return this.http.post<IAssignmentAction>(`${this.URL}/Assignmentaction`, data);
  }

  UpdateAssignmentActionStatus(assignmentActionId, statusId) {
    return this.http.put<IAssignmentAction>(`${this.URL}/AssignmentAction/status/${assignmentActionId}/${statusId}`, {});
  }

  getAssignmentActionsWithActionId(actionId: number): Observable<IAssignmentAction[]> {
    return this.http.get<IAssignmentAction[]>(`${this.URL}/Assignmentaction/withAction/${actionId}`);
  }

  updateAssignmentAction(assignmentAction: IAssignmentAction): Observable<IAssignmentAction> {
    return this.http.put<IAssignmentAction>(`${this.URL}/Assignmentaction/${assignmentAction.id}`, assignmentAction);
  }
}
