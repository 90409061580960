import { Component, OnInit, ViewChild } from '@angular/core';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { HostService } from 'src/app/services/host.service';
import { AssignmentService } from 'src/app/services/tasklist.service';
import { Router } from '@angular/router';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { LocalStorageManager } from 'src/app/utils/LocalStorageManager';
import { TagService } from 'src/app/services/tag.service';
import { ITag, TagType } from 'src/app/modules/shared/domain/tag';
import _ from 'lodash';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { setEndpointLowestTag } from 'src/app/utils/tag-utils';
import { EndpointTableColumnVisibility } from 'src/app/domain/interfaces/endpoint-table-column-visibility';
import { LocalStorageService } from 'src/app/modules/shared/services/local-storage.service';
import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { ActionStatus } from 'src/app/domain/enums/action-status';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { Action } from 'src/app/modules/shared/domain/action';
import { ActionService } from 'src/app/modules/shared/services/action.service';

@Component({
  selector: 'app-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss'],
})
export class EndpointsComponent implements OnInit {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;
  @ViewChild(ContextMenuComponent) public itemMenu: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public tableMenu: ContextMenuComponent;
  detailState;

  endpoints;
  hostslist;
  endpointstatus;
  selectedStatus;
  AddEndpointStatus;
  AddEndPointVisible;

  currEndpoint;
  currAssignment;
  currAssignmentStatus;

  listOfStatus;
  selected = [];
  singleAction;
  multiAction;
  newEndpointName = '';
  addNewEndpointVisible = false;
  readyToDeploy = false;
  openDeleteModal = false;
  tags: ITag[] = [];
  systemTags: ITag[] = [];
  addEndpointsToTag = false;
  selectedTg = [];
  currentAssignmentActions = [];
  failedActions = false;
  actionsLoading: boolean = false;
  endpointDetailsLoading: boolean = false;
  actionStatus = ActionStatus;
  isLoadingEndpoints = true;
  actions: Action[] = [];

  showColumnVisibilityModal = false;
  tableColumnVisibility: EndpointTableColumnVisibility;

  constructor(
    private hSvc: HostService,
    private aASvc: AssignmentActionService,
    private aSvc: AssignmentService,
    private tagSvc: TagService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private actionService: ActionService
  ) { }

  ngOnInit(): void {
    this.GetEndPoints();
    this.GetTags();
    this.loadTableColumnsVisibility();
    this.actionService.getActionsWithoutRecycledVersions().subscribe({
      next: (actions) => {
        this.actions = actions;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  loadTableColumnsVisibility() {
    const tableColumnsObject =
      this.localStorageService.endpointTableColumnsVisibility;
    this.tableColumnVisibility = tableColumnsObject ?? {
      groupsColumnIsVisible: true,
      lifecycleColumnIsVisible: true,
      serialNumberColumnIsVisible: true,
      serviceVersionColumnIsVisible: true,
      trayappVersionColumnIsVisible: true,
      lastConnectionColumnIsVisible: true,
      installDateColumnIsVisible: true,
    };
  }

  //used to open the details of the endpoints
  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  //calls the notification component
  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  //used to change the status of the endpoint
  ToggleReadyToDeploy(readyToDeploy) {
    var assignmentStatusId;
    if (!readyToDeploy) {
      assignmentStatusId = 5;
    } else {
      assignmentStatusId = 1;
    }
    this.aSvc
      .UpdateAssignmentByID(
        this.currAssignment.id,
        this.currEndpoint.id,
        assignmentStatusId,
        ''
      )
      .subscribe((v) => {
        this.GetAssignmentsByEndpointId(this.currEndpoint.id);
        if (v.assignmentStatusId == 1) {
          this.notify('Your packages are ready to install.', 'success');
        } else {
          this.notify('Your Endpoint is now in Design mode.', 'info');
        }
      });
  }

  //used to change the status of an assignment of the endpoint.
  UpdateAssignmentDropDown(assignmentStatusName) {
    this.UpdateAssignment(
      this.currAssignment.id,
      this.currEndpoint.id,
      assignmentStatusName
    );
  }

  //used to change the status of an assignment of the endpoint.
  UpdateAssignment(id, endpointId, assignmentStatusName) {
    var blueprintId = '';
    var assignmentStatusId = this.listOfStatus.filter(
      (v) => v.name == assignmentStatusName
    )[0].id;

    this.aSvc
      .UpdateAssignmentByID(id, endpointId, assignmentStatusId, blueprintId)
      .subscribe((v) => {
        if (blueprintId != '') {
        } else {
          this.GetAssignmentsByEndpointId(endpointId);
        }
      });
  }

  //used to route to the status page of the endpoint
  RouteEndpointStatus(endpointId) {
    this.router.navigate(['/status', endpointId]);
  }

  //used to route to an assignment of the endpoint
  RouteAssignmentDetails(endpointId) {
    this.aSvc.GetAssignmentByEndpointID(endpointId).subscribe((v) => {
      if (v[0] != undefined) {
        this.router.navigate(['/assignmentdetails', 0]);
        LocalStorageManager.AddCurrAssignment({
          isGroup: false,
          assignmentId: v[0].id,
        });
      }
    });
  }

  //used to get endpoints and assigns them into the list of endpoints.
  async GetEndPoints() {
    this.hSvc.GetEndPoints().subscribe((v) => {
      this.endpoints = v.map(setEndpointLowestTag);
      this.hostslist = [];
      //@ts-ignore
      for (let index = 0; index < v.length; index++) {
        this.hostslist.push(v[index].name);
      }

      this.isLoadingEndpoints = false;
    });
  }

  //used to post new endpoints
  PostHost(name) {
    if (name.includes(',')) {
      var ar = name.split(',');

      ar.forEach((element) => {
        this.hSvc.PostSingleEndPoint(element, 1).subscribe((v) => { });
        this.GetEndPoints();
        this.AddEndPointVisible = false;
        //  this.message.create('success', `New Endpoints created.`);
      });
    } else {
      this.hSvc.PostSingleEndPoint(name, 1).subscribe((v) => {
        this.GetEndPoints();
        //  this.message.create('success', `New Endpoint '${name}' created.`);
        this.AddEndPointVisible = false;
      });
    }
  }

  async DeleteEndpoint(selectedEP) {
    for (let index = 0; index < selectedEP.length; index++) {
      this.hSvc
        .RemoveSingleEndPointByID(selectedEP[index].id)
        .subscribe((v) => {
          this.GetEndPoints();
        });
      if (selectedEP.length > 0) {
        if (selectedEP.length == 1) {
          this.notify('Your endpoint has been deleted.', 'info');
        }
        if (selectedEP.length > 1) {
          this.notify('Your endpoints have been deleted.', 'info');
        }
      }
    }
    this.openDeleteModal = false;
  }

  //called whenever a user checks/unchecks endpoints through the left checkboxes
  selectionChanged(e) {
    this.selected = e;

    if (e.length == 1) {
      this.singleAction = true;
    }
    if (e.length > 1) {
      this.multiAction = true;
      this.singleAction = false;
    }
    if (e.length == 0) {
      this.singleAction = false;
      this.multiAction = false;
    }
  }

  //called whenever the detail pane of an endpoint is opened. Loads the relevant data for it
  onDetailOpen(e) {
    if (e == null) {
    } else {
      this.readyToDeploy = false;
      this.currentAssignmentActions = [];
      this.currEndpoint = e;
      this.actionsLoading = true;
      this.endpointDetailsLoading = true;
      this.GetAssignmentsByEndpointId(e.id);
    }
  }

  onCronChanged(e: string) {
    this.hSvc
      .UpdateSingleEndpoint({
        ...this.currEndpoint,
        updateCron: e,
      })
      .subscribe((e) => {
        console.log(e);
      });
  }

  //used to fill the tags array from the endpoint
  GetTags() {
    this.tagSvc.GetTags().subscribe((v) => {
      this.tags = v;
      this.systemTags = this.tags.filter(
        (tag) => tag.type == TagType.DeployStage
      );
      this.tags = this.tags.filter((tag) => tag.type == TagType.User);
    });
  }

  //adds the selected tags to the endpoint when user presses ok on the modal.
  handleOkTagModal(newTags: ITag[]) {
    this.selected.forEach((endpoint: IEndPoint) => {
      endpoint.endpointTag = newTags.map((tag) => {
        return { tag: tag };
      });
      this.hSvc.AddTagToEndpointByEndpointID(endpoint.id, newTags).subscribe();
    });
    this.addEndpointsToTag = false;
    this.endpoints = this.endpoints.map(setEndpointLowestTag);
  }

  //closes the tag modal
  handleCancelTagModal() {
    this.addEndpointsToTag = false;
    this.selected = [];
    this.singleAction = false;
    this.multiAction = false;
  }

  //handles the changes in the tag modal table.
  selectionChangedTG(event) {
    this.selectedTg = event;
  }

  //unused right now, used to post new endpoints through the front-end
  handleOkNewEndpoint(): void {
    this.PostHost(this.newEndpointName);
    this.newEndpointName = '';
    this.addNewEndpointVisible = false;
  }

  //unused right now, closes the new endpoint modal
  handleCancelNewEndpoint(): void {
    this.newEndpointName = '';
    this.addNewEndpointVisible = false;
  }

  handleOkColumnVisibilityModal(): void {
    this.showColumnVisibilityModal = !this.showColumnVisibilityModal;
    this.setColumnVisibility();
  }

  setColumnVisibility(): void {
    this.localStorageService.endpointTableColumnsVisibility =
      this.tableColumnVisibility;
  }

  //Gets the package list of an endpoint. The list of packages in the details pane of an endpoint.
  async GetAssignmentActionsByAssignment(assignmentId) {
    this.aASvc
      .GetAssignmentActionByAssignmentID(assignmentId)
      .subscribe((v) => {
        //@ts-ignore
        v.sort((a, b) => (a.installDate > b.installDate ? -1 : 1));
        //@ts-ignore
        this.currentAssignmentActions = v.sort((a1, a2) => (a1.order ?? 0) - (a2.order ?? 0));
        this.failedActions =
          this.currentAssignmentActions.filter(
            (v) => v.actionStatus === ActionStatus.Failed
          ).length > 0;
      });

    this.endpointDetailsLoading = false;
    this.actionsLoading = false;
  }

  //Resets the status id of the failed packages to not started (the button next to the endpoint name in the details pane)
  FailedActions() {
    var failedActions = this.currentAssignmentActions.filter(
      (v) => v.actionStatus === ActionStatus.Failed
    ); //4 == Failed
    for (let index = 0; index < failedActions.length; index++) {
      this.aASvc
        .UpdateAssignmentActionStatus(
          failedActions[index].id,
          ActionStatus.NotStarted
        )
        .subscribe((v) => {
          this.GetAssignmentActionsByAssignment(this.currAssignment.id);
        });
    }
  }

  //used to get the details of the object that lists the packages under an endpoint.
  //we used to have multiple assignments in an endpoint in the past, which is why it's hardcoded to assignment id 0 right now,
  //because it likely still gets called as an array.
  GetAssignmentsByEndpointId(endpointId) {
    this.aSvc.GetAssignmentByEndpointID(endpointId).subscribe((v) => {
      if (v[0] != undefined) {
        this.currAssignment = v[0];
        this.currAssignmentStatus = v[0].assignmentStatus;
        switch (v[0].assignmentStatus.id) {
          case 1:
            this.readyToDeploy = true;
            break;
          case 5:
            this.readyToDeploy = false;
            break;
          default:
            this.readyToDeploy = true;
            break;
        }
        this.GetAssignmentActionsByAssignment(v[0].id);
      } else {
        this.currentAssignmentActions = [];
        this.endpointDetailsLoading = true;
        this.actionsLoading = true;
      }
    });
  }

  closeDetail() {
    this.detailState = null;
  }

  refreshActions() {
    this.actionsLoading = true;
    this.GetAssignmentsByEndpointId(this.currEndpoint.id);
  }

  updateActionOptionalStatus(assignmentAction: IAssignmentAction): void {
    this.aASvc
      .updateAssignmentAction(assignmentAction)
      .subscribe({ error: (e) => console.error(e) });
  }
}
