import { NgModule } from '@angular/core';
import '@cds/core/icon/register.js';
import { ClarityIcons, usersIcon } from '@cds/core/icon';

ClarityIcons.addIcons(usersIcon);

import { SharedModule } from '../../shared/shared.module';
import { CustomersTableComponent } from './components/customers-table/customers-table.component';
import { CustomersComponent } from './components/customers/customers.component';

@NgModule({
  declarations: [
    CustomersTableComponent,
    CustomersComponent,
  ],
  imports: [SharedModule],
})
export class CustomersModule {}
