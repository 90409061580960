import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { LocalStorageManager } from './utils/LocalStorageManager';
import '@cds/core/file/register.js';
import { SaveService } from './services/save.service';
import { VersionService } from './services/version.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { setMspColorScheme } from './utils/set-msp-color-scheme';
import { MSPAwareComponent } from './modules/shared/utils/msp-aware-component';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './modules/shared/services/local-storage.service';
import { Tenant } from './modules/shared/domain/tenant';
import { TenantService } from './modules/shared/services/tenant.service';
import { AuthenticationService } from './services/authentication.service';
import { User } from './domain/interfaces/user';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends MSPAwareComponent
  implements AfterViewInit, OnInit
{
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;
  @ViewChild('myInput')
  myInputVariable: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 575) {
      this.collapsed = true;
    } else {
      this.collapsed = false;
    }
  }
  @ViewChild('navBar') navBar;
  @ViewChild('mainCont') mainContainer;
  title = 'NimDeploy-FrontEnd';
  options = false;
  originalValue1;
  originalBackground1;
  openSaveModal = false;
  version = '';
  collapsed = false;
  isMsp = environment.isMsp;
  mspDashboard = false;
  tenants: Tenant[] = [];
  currentTenantName: string = this.localStorageService.tenantName ?? 'MSP';

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute,
    public versionSvc: VersionService,
    public saveSvc: SaveService,
    private titleService: Title,
    private localStorageService: LocalStorageService,
    private tenantService: TenantService
  ) {
    super();
    this.versionSvc.getVersion().subscribe((res: any) => {
      this.version = res.version;
    });
    this.originalValue1 = document.documentElement.style.getPropertyValue(
      '--clr-vertical-nav-bg-color'
    );
  }

  get currentUser(): User | null {
    return this.authenticationService.currentUserValue;
  }

  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  logout(): void {
    this.authenticationService.logOut();
    this.router.navigate(['/login']);
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route: ActivatedRouteSnapshot) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((d: ActivatedRouteSnapshot) => {
        if (d.url[0].path === 'msp' && this.isMsp) {
          setMspColorScheme();
          this.mspDashboard = true;
        } else {
          this.mspDashboard = false;
        }
      });
  }
  currTheme;

  ngOnInit(): void {
    if (environment.isMsp) {
      this.titleService.setTitle(`NimDeploy - ${this.currentTenantName}`);
      this.getAllTenants();
    } else {
      this.titleService.setTitle(`NimDeploy - ${environment.tenantName}`);
    }
  }

  routeTo(pageName) {
    this.router.navigate([pageName], { relativeTo: this.route });
  }

  async switchDashboard(options: { tenantIndex: number; toMsp: boolean }) {
    if (!options.toMsp) {
      const tenant = this.tenants[options.tenantIndex];
      this.localStorageService.apiUrl = tenant.azureInformation.apiUrl;
      this.localStorageService.tenantName = tenant.name;
    }
    await this.router.navigate([
      options.toMsp ? '/msp/dashboard' : '/dashboard',
    ]);
    location.reload();
  }

  getAllTenants() {
    this.tenantService.getAllTenants().subscribe({
      next: (data) => {
        this.tenants = data;
      },
      error: (err) => console.error(err),
    });
  }

  handleDontSave() {
    this.saveSvc.currentSave.unsavedChanges = false;
    this.saveSvc.currentSave.pageName = '';
    this.routeTo(this.saveSvc.currentSave.routeTo);
    this.openSaveModal = false;
  }

  handleSaveModal(event: MouseEvent, pageName) {
    if (this.saveSvc.currentSave.unsavedChanges) {
      event.stopPropagation();
      event.preventDefault();
      this.openSaveModal = true;
      this.saveSvc.currentSave.routeTo = pageName;
      return false;
    } else {
      this.routeTo(pageName);
    }
  }

  bugReportModal = false;

  openBugReportModal() {
    this.bugReportModal = true;
  }

  handleCancelBugReport() {
    this.bugReportModal = false;
  }

  bugTitle = '';
  bugBody = '';
  bugReporter = '';
  bugImage: any = null;

  loadFile(file) {
    this.bugImage = file[0];
  }

  handleSaveAndExit() {
    switch (this.saveSvc.currentSave.pageName) {
      case 'Assignment':
        // this.aDetailsComponent.PostAssignmentDetails();
        // this.handleDontSave();
        break;
      default:
        break;
    }
  }

  async switchTheme(boolean) {
    if (!boolean) {
      //Dark
      // document.documentElement.classList.remove('components');
      document.documentElement.classList.add('dark-mode');
      var x = document.getElementsByClassName('content-area');
      //@ts-ignore
      // document.getElementsByClassName("content-container")[0].style.background = "red"
      document.documentElement.style.setProperty('--container-box', '#21333B');
      document.documentElement.style.setProperty(
        '--container-box-border',
        '#495865'
      );
      document.documentElement.style.setProperty(
        '--container-box-border-enabled',
        'solid'
      );

      document.documentElement.style.setProperty(
        '--container-header',
        '#1B2A32'
      );
      document.documentElement.style.setProperty(
        '--container-header-text',
        'none'
      );
      document.documentElement.style.setProperty('--item-box', '#61b715');
      document.documentElement.style.setProperty('--item-box-text', '#ACBAC3');
      document.documentElement.style.setProperty(
        '--item-box-border',
        '#495865'
      );
      document.documentElement.style.setProperty(
        '--item-box-border-enabled',
        'solid'
      );

      //@ts-ignore
      x[0].style.background = '#1B2A32';
      LocalStorageManager.SetLastUsedTheme('Dark');
      this.options = true;
      this.currTheme = LocalStorageManager.GetLastUsedTheme();
    } else {
      //White
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.style.setProperty('--container-box', '#F2F2F2');
      document.documentElement.style.setProperty(
        '--container-box-border',
        'transparent'
      );
      // document.documentElement.style.setProperty('--container-box-border-enabled', "none");
      document.documentElement.style.setProperty(
        '--container-header',
        '#242E40'
      );
      document.documentElement.style.setProperty(
        '--container-header-text',
        'white'
      );
      document.documentElement.style.setProperty(
        '--item-box',
        'var(--clr-btn-success-bg-color)'
      );
      document.documentElement.style.setProperty('--item-box-text', '#666666');

      document.documentElement.style.setProperty(
        '--item-box-border',
        '#495865'
      );
      document.documentElement.style.setProperty(
        '--item-box-border-enabled',
        'solid'
      );

      // 06352D

      var x = document.getElementsByClassName('content-area');
      //@ts-ignore
      x[0].style.background = '#F2F2F2';
      LocalStorageManager.SetLastUsedTheme('White');
      this.options = false;
      this.currTheme = LocalStorageManager.GetLastUsedTheme();
    }
  }
}
