<div *ngIf="creationInProgress" class="d-flex flex-column align-items-center">
    <div class="progress mt-1 w-50">
        <clr-progress-bar clrLoop></clr-progress-bar>
    </div>
    <div>
        <h2>Creating new customer...</h2>
    </div>
    <div class="w-50">
        <h5 class="text-center text-dark mt-0">
            This could require more than 15 minutes, so consider getting a cup of coffee to ensure you're energized and
            ready to welcome the new customer.
        </h5>
        <p class="text-center">
            You can view the initial run of your new pipeline on
            <a target="_blank" href="https://dev.azure.com/{{organizationName}}/{{projectName}}/_build">
                <u>Azure DevOps.</u>
            </a>
        </p>
    </div>
    <div class="mt-5">
        <img src="assets/tenant-creation-logo.svg" />
    </div>
</div>

<div *ngIf="creationSuccess" class="d-flex flex-column align-items-center">
    <div id="success">
        <cds-icon shape="check" id="checkmark" size="xxl"></cds-icon>
    </div>
    <div>
        <h2>Successfully created customer!</h2>
    </div>
    <div class="mt-3">
        <img src="assets/tenant-creation-success.svg" />
    </div>
    <div class="w-75">
        <p class="text-center text-dark"><strong>NOTE</strong></p>
        <p class="text-center text-dark mt-0">
            Please be aware that we require an additional 15 minutes to ensure that MSI Installer and
            Service TrayApp are prepared for the newly created customer. During this time, feel free to access and
            engage with the new customer’s dashboard.
        </p>
    </div>
</div>

<div *ngIf="creationError" id="error">
    <cds-icon shape="error-standard" id="error-standard" size="xxl"></cds-icon>
    <h5 class="text-center">{{ errorMessage }}</h5>
</div>