<div class="customers-container">
    <div class="customers-header">
        <div class="customers-title">
            <cds-icon shape="users" size="md" style="--color: black;"></cds-icon>
            <h3>Customers</h3>
        </div>
        <div>
            <button type="button" routerLink="add" class="btn btn-icon btn-primary btn-md">
                Add Customer
            </button>
        </div>
    </div>
    <div>
        <msp-customers-table></msp-customers-table>
    </div>
</div>