<clr-datagrid id="datagrid">
  <clr-dg-column>Customer</clr-dg-column>
  <clr-dg-column>Endpoints</clr-dg-column>
  <clr-dg-column>Packages</clr-dg-column>
  <!-- <clr-dg-column>NimDeploy Version</clr-dg-column> -->
  <clr-dg-column>Stage</clr-dg-column>
  <clr-dg-row *clrDgItems="let customer of customers">
    <clr-dg-cell>{{customer.name}}</clr-dg-cell>
    <clr-dg-cell>{{customer.endpointCount}}</clr-dg-cell>
    <clr-dg-cell>{{customer.packageCount}}</clr-dg-cell>
    <clr-dg-cell class="p-1">
      <select class="clr-select" clrSelect [ngModel]="customer.stage"
        (change)="onTenantStageUpdate(+$event.target.value, customer.id)">
        <option *ngFor="let stage of stageEnum | enumToList; let i = index" value="{{i}}" [selected]="i === customer.stage">
          {{stage}}
        </option>
      </select>
    </clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>