import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { BlueprintActionService } from 'src/app/services/blueprint-action.service';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { AssignmentService } from 'src/app/services/tasklist.service';
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
  CdkDragExit,
  CdkDragEnter,
} from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { SaveService } from 'src/app/services/save.service';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { ActionTypeService } from 'src/app/services/actiontype.service';
import { LocalStorageManager } from 'src/app/utils/LocalStorageManager';
import { GroupsService } from 'src/app/services/groups.service';
import { ICurrentassignment } from 'src/app/domain/interfaces/icurrentassignment';
import { IEndPointGroup } from 'src/app/domain/interfaces/iendpointgroup';
import compareVersions from 'compare-versions';
import { GroupactionsService } from 'src/app/services/groupactions.service';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { TagType } from 'src/app/modules/shared/domain/tag';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { HostService } from 'src/app/services/host.service';
import { setEndpointLowestTag } from 'src/app/utils/tag-utils';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs/internal/Observable';
import { ActionStatus } from 'src/app/domain/enums/action-status';

@Component({
  selector: 'app-assignment-details',
  templateUrl: './assignment-details.component.html',
  styleUrls: ['./assignment-details.component.scss'],
})
export class AssignmentDetailsComponent implements OnInit {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  selectedInputValue: string = '';
  inputValue: string = '';
  Actions: any;
  SelectedActions: any[] = [];
  originalSelectedActions: any[] = [];
  data: any;
  listofBlueprints: IBluePrint[] = [];
  selectedBlueprint;
  blueprintsInAssignment: any[] = [];
  openBlueprintModal = false;
  openActionSettingsModal = false;
  readyToDeploy = false;
  openForceModal = false;
  currentAssignment: ICurrentassignment;
  groupData: IEndPointGroup;
  availableVersions = [];
  uninstallSetting = false;
  versionFixedSetting = false;
  forceInstallSetting = false;
  selectedVersion;
  actionSettings;
  templateId = 0;
  selectables;
  actionTypes;
  singleAction;
  multiAction;
  isCurrentStateGroup;
  selectedBlueprintsToAdd: any[] = [];
  isAccepted: boolean = false;
  saveButtonState = ClrLoadingState.DEFAULT;

  isLoadingPage = true;

  constructor(
    private tSvc: ActionService,
    private aSvc: AssignmentService,
    private aASvc: AssignmentActionService,
    private bSvc: BlueprintService,
    private bASvc: BlueprintActionService,
    private router: Router,
    private aTSvc: ActionTypeService,
    private gSvc: GroupsService,
    private avSvc: ActionversionService,
    private gaSvc: GroupactionsService,
    private hostService: HostService,
    public saveSvc: SaveService
  ) {}

  ngOnInit(): void {
    this.currentAssignment = LocalStorageManager.GetCurrAssignment();
    this.isCurrentStateGroup = this.currentAssignment.isGroup;

    if (!this.currentAssignment.isGroup) {
      this.templateId = this.currentAssignment.assignmentId;
      this.GetAssignmentData(this.templateId);
    } else {
      this.GetGroupData(this.currentAssignment);
      this.bSvc.GetBluePrint().subscribe((v) => {
        this.listofBlueprints = v;
      });
    }
  }

  public shouldRemoveButtonShow(item): boolean {
    if (this.isCurrentStateGroup) {
      if (item.blueprint == null) return true;
    }
    if (!this.isCurrentStateGroup) {
      if (item.endpointGroupId == null) return true;
    }

    return false;
  }

  RouteEndpointStatus(endpointId) {
    if (this.saveSvc.currentSave.unsavedChanges) {
    } else {
      this.router.navigate(['/status', endpointId]);
    }
  }

  HandleOkActionSettings() {
    this.openActionSettingsModal = false;
    const selectedAction = this.SelectedActions.find(
      (i) => i.id == this.actionSettings.id
    );
    selectedAction.isInstall = this.uninstallSetting;
    selectedAction.isAccepted = this.forceInstallSetting;
    selectedAction.isVersionFixed = this.versionFixedSetting;

    if (
      selectedAction.selectedVersion.version != this.selectedVersion['version']
    ) {
      selectedAction['versionChanged'] = true;
    }
    selectedAction.selectedVersion = this.selectedVersion;
    this.GetIcons(this.SelectedActions);
    this.selectedVersion = {};
  }
  HandleCancelActionSettings() {
    this.openActionSettingsModal = false;
  }

  ActionSettings(actionSettings) {
    this.actionSettings = actionSettings;
    this.uninstallSetting = actionSettings.isInstall;
    this.forceInstallSetting = actionSettings.isAccepted;
    this.versionFixedSetting = actionSettings.isVersionFixed;
    this.avSvc.GetActionVersionByActionID(actionSettings.id).subscribe((v) => {
      //@ts-ignore
      this.availableVersions = v;
      this.openActionSettingsModal = true;
      const x = this.availableVersions.filter(
        (v) => v.id == actionSettings.actionVersion[0].id
      )[0];
      this.selectedVersion = x; //To be changed with latest version query
    });
  }

  notify(text: string, status: string) {
    const data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  GetActionTypes() {
    this.aTSvc.GetActionType().subscribe((v) => {
      this.actionTypes = v;
    });
  }

  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  handleUnsaved() {
    if (!this.saveSvc.currentSave.unsavedChanges) {
      this.saveSvc.currentSave.unsavedChanges = true;
      this.saveSvc.currentSave.pageName = 'Assignment';
    }
  }
  handleSave() {
    this.saveSvc.currentSave.unsavedChanges = false;
    this.saveSvc.currentSave.pageName = '';
  }

  selectionChanged(e) {
    this.selectedBlueprintsToAdd = e;
    if (e.length == 1) {
      this.singleAction = true;
    }
    if (e.length > 1) {
      this.multiAction = true;
      this.singleAction = false;
    }
    if (e.length == 0) {
      this.singleAction = false;
      this.multiAction = false;
    }
  }

  blueprintModalDone() {
    for (let index = 0; index < this.selectedBlueprintsToAdd.length; index++) {
      this.UpdateAssignment(this.selectedBlueprintsToAdd[index]);
    }
    if (this.selectedBlueprintsToAdd.length > 0) {
      this.handleUnsaved();
    }
    this.openBlueprintModal = false;
    this.selectedBlueprintsToAdd = [];
  }

  listOfStatus;
  currAssignmentStatus;
  GetAssignmentStatus() {
    this.aSvc.GetAssignmentStatus().subscribe((v) => {
      this.listOfStatus = v;
    });
  }

  ToggleReadyToDeploy(readyToDeploy) {
    let assignmentStatusId;
    if (!readyToDeploy) {
      assignmentStatusId = 5;
    } else {
      assignmentStatusId = 1;
      this.openForceModal = true;
    }
    this.aSvc
      .UpdateAssignmentByID(
        this.data.id,
        this.data.endpointId,
        assignmentStatusId,
        ''
      )
      .subscribe((v) => {
        if (v.assignmentStatusId == 1) {
          this.notify('Your packages are ready to install.', 'success');
        } else {
          this.notify('Your Endpoint is now in Design mode.', 'info');
        }
        this.aSvc.GetAssignmentByID(this.templateId).subscribe((va) => {
          this.data = va;
        });
      });
  }

  UpdateAssignmentDropDown(assignmentStatusName) {
    this.UpdateAssignmentStatus(
      this.data.id,
      this.data.endpoint.id,
      assignmentStatusName
    );
  }

  UpdateAssignmentStatus(id, endpointId, assignmentStatusName) {
    const blueprintId = '';
    const assignmentStatusId = this.listOfStatus.filter(
      (v) => v.name == assignmentStatusName
    )[0].id;

    this.aSvc
      .UpdateAssignmentByID(id, endpointId, assignmentStatusId, blueprintId)
      .subscribe((v) => {
        if (blueprintId != '') {
        } else {
          this.aSvc.GetAssignmentByID(this.templateId).subscribe((va) => {
            this.data = va;
          });
        }
      });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.container.data.indexOf(event.item.data), event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //       event.container.data,
  //       event.previousContainer.data.indexOf(event.item.data),
  //       event.currentIndex);
  //   }
  // }

  onSourceListExited(event: CdkDragExit<any>) {
    const findItem = this.Actions.filter(
      (n) =>
        n.name == event.item.data.name &&
        n.actionType.id == event.item.data.actionType.id
    )[0];
    this.Actions.splice(this.Actions.indexOf(findItem) + 1, 0, {
      ...event.item.data,
      temp: true,
    });
  }

  ReturnLatestActionVersion(arr) {
    let latestVersion = '';
    const versions = [];
    arr.actionVersion.forEach((element) => {
      versions.push(element.version);
    });
    latestVersion = versions.sort(compareVersions).reverse()[0];
    return arr.actionVersion.filter((v) => v.version == latestVersion)[0];
  }

  onSourceListEntered(event: CdkDragEnter<any>) {
    _.remove(this.Actions, { temp: true });
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.container.data.indexOf(event.item.data),
        event.currentIndex
      );
    } else {
      // transferArrayItem(
      //   event.previousContainer.data,
      //   event.container.data,
      //   event.previousContainer.data.indexOf(event.item.data),
      //   event.currentIndex);
      if (event.item.data.actionVersion.length > 0) {
        event.item.data['selectedVersion'] = this.ReturnLatestActionVersion(
          event.item.data
        );
      } else {
        event.item.data['selectedVersion'] = {
          version: '0.0.0.0',
        };
      }

      copyArrayItem(
        _.cloneDeep(event.previousContainer.data),
        event.container.data,
        event.previousContainer.data.indexOf(event.item.data),
        event.currentIndex
      );
    }
    if (event.previousContainer.data) {
      _.remove(this.Actions, { temp: true });
    }
    this.UpdateBlueprintTags(this.SelectedActions);
    this.handleUnsaved();
  }
  dropSelected(event: CdkDragDrop<string[]>) {
    // if(!event.isPointerOverContainer){ //To be added: If dragged item is not over another container
    //   this.SelectedActions.splice(event.previousIndex,1);
    // }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.container.data.indexOf(event.item.data),
        event.currentIndex
      );
    } else {
      this.SelectedActions.splice(event.previousIndex, 1);
    }
    this.UpdateBlueprintTags(this.SelectedActions);
    this.handleUnsaved();
  }

  GetAssignmentData(id) {
    this.aSvc
      .GetAssignmentByID(id)
      .pipe(
        mergeMap((v) => {
          this.data = v;
          this.GetAssignmentActionsByAssignment();
          switch (v.assignmentStatus.id) {
            case 1:
              this.readyToDeploy = true;
              break;
            case 5:
              this.readyToDeploy = false;
              break;
            default:
              this.readyToDeploy = true;
              break;
          }
          return this.hostService.GetSingleEndPoint(v.endpoint.id);
        })
      )
      .subscribe({
        next: (endpoint) => {
          const tag = setEndpointLowestTag(endpoint).lowestTag;
          this.GetActions(tag.id);
        },
        error: (e) => console.log(e),
      });
  }

  ChangeForceInstall(pkg) {
    pkg.isAccepted = !pkg.isAccepted;
  }

  GetGroupData(cA) {
    this.gSvc.GetSingleEndPointGroup(cA.groupId).subscribe((v) => {
      this.groupData = v;
      this.GetActions(
        v.endpointGroupTag.find((t) => t.tag.type === TagType.DeployStage).tag
          .id
      );
    });
  }

  async GetCurrentBlueprint() {
    if (this.data) {
      const filter = this.listofBlueprints.filter(
        (blue) => blue.id === this.data.blueprintId
      )[0];
      if (filter != undefined) {
        this.selectedBlueprint = filter;
      } else {
        this.selectedBlueprint = this.listofBlueprints[0];
      }
    }
  }

  viewListOfActions;
  onDetailOpen(e) {
    if (e == null) {
    } else {
      this.showTemplateDataListModal(e.id);
    }
  }

  showTemplateDataListModal(id) {
    this.bASvc.GetBlueprintActionsByBlueprint(id).subscribe((v) => {
      this.viewListOfActions = v;
    });
  }

  addBlueprintModal(): void {
    if (this.isCurrentStateGroup && this.blueprintsInAssignment.length > 0) {
      alert(
        'You can only add one blueprint to a group. Please remove the current blueprint before adding a new one.'
      );
      return;
    }
    this.openBlueprintModal = true;
  }

  async GetAssignmentActionsByAssignment() {
    this.aASvc
      .GetAssignmentActionByAssignmentID(this.templateId)
      .subscribe((v) => {
        let data = [];
        // @ts-ignore
        v.forEach((element) => {
          // @ts-ignore
          element.action.blueprint = this.listofBlueprints.filter(
            (blu) => blu.id === element.blueprintId
          )[0];
          // @ts-ignore
          element.action.order = element.order;
          // @ts-ignore
          element.action.assignmentActionId = element.id;
          // @ts-ignore
          element.action.actionStatus = element.actionStatus;
          // @ts-ignore
          element.action.isInstall = element.isInstall;
          // @ts-ignore
          element.action.isAccepted = element.isAccepted;
          // @ts-ignore
          element.action.selectedVersion = element.actionVersion;
          // @ts-ignore
          element.action.isVersionFixed = element.isVersionFixed;
          // @ts-ignore
          element.action.endpointGroupId = element.endpointGroupId;
          // @ts-ignore
          element.action.endpointGroup = element.endpointGroup;
          data.push(element.action);
        });
        this.GetIcons(data);
        this.SelectedActions = data;
        this.SelectedActions.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.UpdateBlueprintTags(this.SelectedActions);
        this.originalSelectedActions = _.cloneDeep(this.SelectedActions);
      });
  }

  RevertToOriginal() {
    this.SelectedActions = _.cloneDeep(this.originalSelectedActions);
    this.UpdateBlueprintTags(this.SelectedActions);
    this.handleSave();
    this.notify(`Your changes have been reverted`, 'info');
  }

  UpdateBlueprintTags(selectedActions) {
    this.blueprintsInAssignment = [];
    const distinctThings = selectedActions.filter(
      (thing, i, arr) =>
        arr.findIndex((t) => t.blueprint === thing.blueprint) === i
    );
    for (let index = 0; index < distinctThings.length; index++) {
      if (distinctThings[index].blueprint != null) {
        this.blueprintsInAssignment.push(distinctThings[index].blueprint);
      }
    }
  }

  RemoveBlueprintTag(blueprint) {
    this.SelectedActions = this.SelectedActions.filter(
      (obj) => obj.blueprint != blueprint
    );
    this.UpdateBlueprintTags(this.SelectedActions);
    this.notify(
      `${blueprint.name} has been removed from your assignment.`,
      'info'
    );
    this.handleUnsaved();
  }

  PostAssignmentDetails() {
    const data = [];
    let index = 1;
    this.SelectedActions.forEach((element) => {
      element.versionChanged = false;
      let blueprintId = null;
      if (element.blueprint != null) {
        blueprintId = element.blueprint.id;
      }
      const temp = {
        // "assignmentId" : this.templateId,
        order: index,
        actionId: element.id,
        blueprintId: blueprintId,
        isInstall: element.isInstall,
        isVersionFixed: element.isVersionFixed,
        actionVersionId: element.selectedVersion.id,
      };
      if (!this.currentAssignment.isGroup) {
        (temp['actionStatus'] = element.actionStatus),
          (temp['assignmentId'] = this.templateId);
        (temp['isAccepted'] = this.isAccepted),
          (temp['id'] = element.assignmentActionId);
        temp['endpointGroupId'] = element.endpointGroupId;
      } else {
        temp['endpointGroupId'] = this.currentAssignment.groupId;
        // temp["id"] = this.currentAssignment.groupId
      }
      data.push(temp);
      index++;
    });

    this.saveButtonState = ClrLoadingState.LOADING;
    const save$: Observable<Object> = this.currentAssignment.isGroup
      ? this.gaSvc.PutGroupActions(
          data,
          this.currentAssignment.groupId,
          this.isAccepted
        )
      : this.aASvc.PutAssignmentActions(data, this.templateId);

    save$.subscribe({
      next: () => {
        this.saveButtonState = ClrLoadingState.SUCCESS;
        this.notify('Your changes have been saved!', 'success');
        this.handleSave();
      },
      error: () => {
        this.saveButtonState = ClrLoadingState.ERROR;
        this.notify('An error occured trying to save your changes!', 'danger');
      },
    });
  }

  originalActions;

  GetActions(tagId: number) {
    this.tSvc.GetActionsForTag(tagId).subscribe((v) => {
      this.GetIcons(v);
      this.Actions = v;
      this.originalActions = _.cloneDeep(this.Actions);
      this.isLoadingPage = false;
    });
  }

  GetFixedBordColour(bool) {
    if (!bool) {
      return '#3C8500';
    } else {
      return '#808080';
    }
  }

  GetIcons(actions) {
    actions.forEach((element) => {
      if (element.isInstall == null) {
        element['isInstall'] = true;
        // element.actionIcon.name = "download"
      }
      if (element.isVersionFixed == null) {
        element['isVersionFixed'] = false;
      }
      if (element.isAccepted == null) {
        element.isAccepted = false;
      }
      element.actionIcon = {};
      if (element.actionStatus == null) {
        element.actionStatus = ActionStatus.NotStarted;
      }
      if (element.isInstall == true) {
        element.actionIcon.name = 'download';
      }
      if (element.isInstall == false) {
        element.actionIcon.name = 'trash';
      }
      if (element.actionType.id > 2) {
        //temporary op ID 2, if double actions deleted, moet ID>1
        element.actionIcon.name = 'info-standard';
      }
    });
  }

  AddAction(action, isInstall: boolean) {
    const temp = _.cloneDeep(action);
    temp['isInstall'] = isInstall;
    if (temp.isInstall == true) {
      temp.actionIcon.name = 'download';
    }
    if (temp.isInstall == false) {
      temp.actionIcon.name = 'trash';
    }
    if (temp.isInstall == false && temp.actionType.id > 2) {
      //temporary op ID 2, if double actions deleted, moet ID>1
      temp.actionIcon.name = 'info-standard';
    }

    if (temp.actionVersion.length > 0) {
      temp['selectedVersion'] = this.ReturnLatestActionVersion(temp);
    }

    this.SelectedActions.push(temp);
    this.handleUnsaved();
    this.notify(`${temp.name} has been added!`, 'success');
  }

  RemoveAction(action) {
    this.SelectedActions = this.SelectedActions.filter((v) => v != action);
    this.notify(`${action.name} has been removed!`, 'info');
  }

  AddEverything() {
    this.Actions.forEach((element, index) => {
      if (element.actionVersion.length > 0) {
        element['selectedVersion'] = this.ReturnLatestActionVersion(element);
        this.SelectedActions.push(element);
      }
    });
    this.notify('Every Package with a version has been added!', 'success');
    // this.Actions = [];
    this.handleUnsaved();
  }

  DeleteEverything() {
    // this.SelectedActions.forEach((element, index) => {
    //   this.Actions.push(element);
    // }
    // );
    if (this.isCurrentStateGroup) {
      this.SelectedActions = this.SelectedActions.filter(
        (p) => p.blueprint != null
      );
    } else {
      this.SelectedActions = this.SelectedActions.filter(
        (p) => p.endpointGroupId != null
      );
    }
    this.UpdateBlueprintTags(this.SelectedActions);
    this.notify('Every Package has been removed!', 'info');
    this.handleUnsaved();
  }

  UpdateAssignment(blueprint) {
    this.selectedBlueprint = blueprint;
    if (blueprint.id != '') {
      this.bASvc
        .GetBlueprintActionsByBlueprint(blueprint.id)
        .subscribe((bp) => {
          let data = [];
          // @ts-ignore
          // The list of actions is tag-aware so any actions that aren't compatible with the endpoint/group's tag aren't processed
          const filteredActions = bp.filter((e) =>
            this.Actions.find((a) => a.id === e.action.id)
          );
          let actionsAlreadyAddedCount = 0;
          filteredActions.forEach((element) => {
            element.action.blueprint = blueprint;

            if (
              !this.SelectedActions.find(
                (v) =>
                  v.name === element.action.name &&
                  v.actionTypeId === element.action.actionType.id
              )
            ) {
              element.action['isInstall'] = element.isInstall;
              element.action['isVersionFixed'] = element.isVersionFixed;
              if (element.isVersionFixed) {
                element.action['selectedVersion'] = element.actionVersion;
              } else {
                //Selects the latest version if VersionFixed is not checked.
                const x = this.Actions.find((v) => v.id === element.action.id);
                element.action['selectedVersion'] =
                  this.ReturnLatestActionVersion(x);
              }
              data.push(element.action);
            } else {
              actionsAlreadyAddedCount++;
            }
          });
          this.GetIcons(data);
          this.SelectedActions.push(...data);
          //@ts-ignore
          const count = bp.length;
          const currAssignmentObjectName = this.currentAssignment.isGroup
            ? 'group'
            : 'endpoint';
          if (
            filteredActions.length &&
            actionsAlreadyAddedCount === filteredActions.length
          ) {
            this.notify(
              `All of the assignable actions from "${blueprint?.name}" are already added to this ${currAssignmentObjectName}.`,
              'info'
            );
          } else if (actionsAlreadyAddedCount) {
            this.notify(
              `Some of the blueprint's actions are already assigned to this ${currAssignmentObjectName}.`,
              'info'
            );
          } else if (
            filteredActions.length &&
            filteredActions.length === count
          ) {
            this.notify(
              `Blueprint "${blueprint?.name}" added successfully!`,
              'success'
            );
          } else if (
            filteredActions.length &&
            filteredActions.length !== count
          ) {
            this.notify(
              `Some of the blueprint's actions couldn't be assigned to this ${currAssignmentObjectName}.`,
              'info'
            );
          } else if (!filteredActions.length) {
            this.notify(
              `Blueprint "${blueprint?.name}" has no assignable actions for this ${currAssignmentObjectName}.`,
              'warning'
            );
          }

          this.UpdateBlueprintTags(this.SelectedActions);
        });
    }
  }

  ClearAvailableFilterValue() {
    let input;
    this.inputValue = '';
    input = document.getElementById('availableActionInput');
    input.value = '';
    this.filterAvailableActions();
  }

  filterAvailableActions() {
    let input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('availableActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('AvailableActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }
  ClearSelectedFilterValue() {
    let input;
    this.selectedInputValue = '';
    input = document.getElementById('selectedActionInput');
    input.value = '';
    this.FilterSelectedActions();
  }

  FilterSelectedActions() {
    let input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('selectedActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('selectedActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }

  previousSelectedElement;

  ClearSelected() {
    if (this.previousSelectedElement != null) {
      this.previousSelectedElement.style = 'background:#545454;';
      this.previousSelectedElement = null;
      this.selectedActionData = null;
    }
  }

  selectedActionData = 'No Action selected';
  SelectAction(actionData, refEl?: any) {
    actionData.isInstall = !actionData.isInstall;
    this.GetIcons(this.SelectedActions);
  }

  hashCode(str) {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  handleForce() {
    this.aSvc.AcceptInstall(this.templateId).subscribe((v) => {});
    this.openForceModal = false;
  }

  handleDontForce() {
    this.openForceModal = false;
  }
}
