<div class="card">
    <div class="app-card-header">
        <img class="app-icon"
            src="{{lastVersion().iconUri ? lastVersion().iconUri : 'assets/nimdeploy-logo-shadow.svg'}}">
        <div>
            <h4 class="mt-0">{{action.name}}</h4>
            <p class="mt-0">{{lastVersion().version}}</p>
        </div>
    </div>
    <div class="app-card-content">
        <div class="app-card-body">
            <p>{{lastVersion().description}}</p>
        </div>
        <div class="d-flex author-field">
            <b>Author:</b>
            <h5 class="m-0 ms-1">{{lastVersion().authors}}</h5>
        </div>
        <div class="app-card-actions">
            <button class="btn" [class.btn-primary]="!action.isSubscribed" [class.btn-outline]="action.isSubscribed"
                (click)="onSubscribe()">{{action.isSubscribed ? 'Subscribed' : 'Subscribe'}}
                <cds-icon *ngIf="action.isSubscribed" shape="check"></cds-icon>
            </button>
        </div>
    </div>
</div>