<form clrForm autocomplete="off">

    <p class="mt-4 mb-3 fs-6">Subscription Information</p>
    <clr-input-container>
        <label>Subscription Name</label>
        <input type="text" [value]="subscriptionInformation?.subscriptionName" clrInput readonly />
    </clr-input-container>
    <clr-input-container>
        <label>Subscription ID</label>
        <input type="text" [value]="subscriptionInformation?.subscriptionId" clrInput readonly />
    </clr-input-container>

    <p class="mt-4 mb-3 fs-6">General Information</p>
    <clr-input-container>
        <label>Name</label>
        <input type="text" [value]="generalInformation?.name" clrInput readonly />
    </clr-input-container>
    <clr-input-container>
        <label>Domain Name</label>
        <input type="text" [value]="generalInformation?.domain" clrInput readonly />
    </clr-input-container>
    <clr-input-container>
        <label>Chocolatey Repository</label>
        <input type="text" [value]="generalInformation?.chocolateyRepositoryUrl" clrInput readonly />
    </clr-input-container>
    <clr-input-container>
        <label>Deployment Branch</label>
        <input type="text" [value]="generalInformation?.deploymentBranch" clrInput readonly />
    </clr-input-container>

    <p class="mt-4 mb-3 fs-6">NuGet Information</p>
    <clr-input-container>
        <label>NuGet Repository</label>
        <input type="text" [value]="nugetInformation?.nugetRepositoryUrl" clrInput readonly />
    </clr-input-container>
    <p class="mt-4 mb-3" style="font-size: 15px;">Read-Write Credentials</p>
    <clr-input-container>
        <label>NuGet Username</label>
        <input type="text" [value]="nugetInformation?.nugetRwUsername" clrInput readonly />
    </clr-input-container>
    <clr-password-container>
        <label>NuGet Password</label>
        <input clrPassword [value]="nugetInformation?.nugetRwPassword" readonly />
    </clr-password-container>
    <p class="mt-4 mb-3" style="font-size: 15px;">Read-Only Credentials</p>
    <clr-input-container>
        <label>NuGet Username</label>
        <input type="text" [value]="nugetInformation?.nugetRoUsername" clrInput readonly />
    </clr-input-container>
    <clr-password-container>
        <label>NuGet Password</label>
        <input clrPassword [value]="nugetInformation?.nugetRoPassword" readonly />
    </clr-password-container>

</form>