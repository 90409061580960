import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipEvent, MatChipInputEvent } from '@angular/material/chips';

export interface SelectionChip {
  value: string;
}

@Component({
  selector: 'pb-input-with-chips',
  templateUrl: './input-with-chips.component.html',
  styleUrls: ['./input-with-chips.component.scss'],
})
export class InputWithChipsComponent {
  @Output() onSelectionChangedEvent = new EventEmitter<SelectionChip[]>();
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  @Input() selections: SelectionChip[] = [];
  selection: string;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selections.push({ value });
    }

    event.input.value = '';
    this.onSelectionChangedEvent.emit(this.selections);
  }

  remove(item: SelectionChip): void {
    const index = this.selections.indexOf(item);

    if (index >= 0) {
      this.selections.splice(index, 1);
    }
    this.onSelectionChangedEvent.emit(this.selections);
  }

  edit(item: SelectionChip, event: MatChipEvent) {
    const value = event.chip.value.trim();

    if (!value) {
      this.remove(item);
      return;
    }

    const index = this.selections.indexOf(item);
    if (index) {
      this.selections[index].value = value;
    }
    this.onSelectionChangedEvent.emit(this.selections);
  }
}
