import { NgModule } from '@angular/core';

import '@cds/core/icon/register.js';
import {
  ClarityIcons,
  usersIcon,
  applicationIcon,
  shareIcon,
  angleIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(usersIcon, applicationIcon, shareIcon, angleIcon);

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [DashboardComponent, DashboardCardComponent],
  imports: [SharedModule],
})
export class MspDashboardModule {}
