import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from 'src/app/modules/shared/domain/action';
import { IAssignment } from 'src/app/domain/interfaces/iassignment';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { IEndpointStatus } from 'src/app/domain/interfaces/istatus';
import { EndpointStatusService } from 'src/app/services/endpoint-status.service';
import { HostService } from 'src/app/services/host.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { ActionType } from 'src/app/modules/shared/domain/action-type';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  formHostName: string = "";
  AddEndPointVisible: boolean = false;
  endpoints: IEndPoint[];
  endpointstatus: IEndpointStatus;
  taskList: IAssignment;
  taskListDetails: IAssignmentAction;
  actions: Action[];
  taskType: ActionType;
  selectedType;
  selectedStatus;
  hostslist = []

  constructor(private hSvc: HostService,
    private epSSvc: EndpointStatusService,
    private tSvc: ActionService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.GetEndPoints();
    this.GetEndpointStatus();
    this.GetActionList()
  }

  RouteHostDetails(hostid) {
    this.router.navigate(['/hostdetails', hostid, "null", "null"]);
  }

  async GetEndPoints() {
    this.hSvc.GetEndPoints().subscribe(v => {
      this.endpoints = v;
      this.hostslist = [];
      //@ts-ignore
      for (let index = 0; index < v.length; index++) {
        this.hostslist.push(v[index].name)
      }
    })
  }

  GetEndpointStatus() {
    this.epSSvc.GetEndpointStatus().subscribe(v => {
      this.endpointstatus = v;
      this.selectedStatus = this.endpointstatus[0];
    })
  }

  //Functie om een nieuwe host te posten. Roept GetEndPoints om te vernieuwen
  PostHost(name) {
    if (name.includes(",")) {
      var ar = name.split(",");

      ar.forEach(element => {
        this.hSvc.PostSingleEndPoint(element, this.selectedStatus.id).subscribe(v => {
        })
        this.GetEndPoints();
        this.AddEndPointVisible = false;
        //  this.message.create('success', `New Endpoints created.`);

      });
    }
    else {
      this.hSvc.PostSingleEndPoint(name, this.selectedStatus.id).subscribe(v => {
        this.GetEndPoints();
        //  this.message.create('success', `New Endpoint '${name}' created.`);
        this.AddEndPointVisible = false;
      })
    }

  }

  newLength

  GetActionList() {
    this.tSvc.getActions().subscribe(v => {
      this.actions = v;
      //@ts-ignore
      this.newLength = v.length;
    })
  }
  DeleteActionById(id) {
    this.tSvc.RemoveSingleActionByID(id).subscribe(v => {
      this.GetActionList();
    })
  }
  UpdateActionList() {
    //@ts-ignore
    var currLength = this.actions.length;
    this.tSvc.GetNewActions().subscribe(v => {
      this.GetActionList();
      //@ts-ignore
      var newLength = this.actions.length;

      //@ts-ignore
      if (v.length > 0 && this.actions.filter(act => act.name === v.name).length == 0) {
        //@ts-ignore
        //  this.message.success(`${v.length} new action(s) added`)

      }
      else {
        //  this.message.info("No new actions added.")
      }

    })
  }

  handleCancel() {
    this.AddEndPointVisible = false;
  }


  //Main functie om Hosts te Getten in Code. Dient ook om pagina te vernieuwen (psuedo update code)


  changeStatus(status) {
    this.selectedStatus = status;
  }

  //Functie om een bestaande host te verwijderen. Roept GetEndPoints om te vernieuwen
  RemoveEndPointByID(id) {
    this.hSvc.RemoveSingleEndPointByID(id).subscribe(v => {
      //  this.message.success(`${v.name} removed.`)
      this.GetEndPoints();
    })
  }

  resetCache() {
    //  LocalStorageManager.ResetEverything();
    location.reload();
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.hostslist, event.previousIndex, event.currentIndex);
    let index;

    //@ts-ignore
    this.endpoints.forEach(element => {
      index = this.hostslist.findIndex(x => x == element.name)
      index += 1;
    });
  }

}

