import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tenant } from '../domain/tenant';
import { environment } from 'src/environments/environment';
import { Stage } from '../domain/enums/stage';
import { CreateNewTenantRequest } from '../../msp/customer-builder/models/create-new-tenant-request';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  baseUrl: string = `${environment.api.apiUrl}/tenant`;

  constructor(private http: HttpClient) {}

  getAllTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(this.baseUrl);
  }

  setTenantStage(tenantId: number, stage: Stage): Observable<Tenant> {
    const url = `${this.baseUrl}/update-stage/${tenantId}`;
    return this.http.put<Tenant>(url, undefined, {
      params: new HttpParams({ fromObject: { stage: stage.toString() } }),
    });
  }

  createNewTenant(tenant: CreateNewTenantRequest): Observable<Tenant> {
    return this.http.post<Tenant>(this.baseUrl, tenant);
  }
}
