import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { PagedData } from '../domain/interfaces/pages-data';
import { InstalledApplicationToPackageLink } from '../domain/interfaces/installed-application-to-package-link';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationPackageLinkServiceService {
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  URL: string = this.localStorageService.apiUrl;

  getApplicationToPackageLinksForEndpoint(endpointId: number, skip: number, take: number): Observable<PagedData<InstalledApplicationToPackageLink[]>> {
    return this.http.get<PagedData<InstalledApplicationToPackageLink[]>>(`${this.URL}/ApplicationPackageLink/${endpointId}`, {
      params: new HttpParams()
        .append('skip', skip.toString())
        .append('take', take.toString())
    });
  }

  acceptApplicationToPackageLink(link: InstalledApplicationToPackageLink): Observable<void> {
    return this.http.post(`${this.URL}/ApplicationPackageLink/accept`, link).pipe(
      mapTo(undefined)
    );
  }
}
