import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionInformation } from '../../models/subscription-information';
import { GeneralInformation } from '../../models/general-information';
import { NugetInformation } from '../../models/nuget-information';

@Component({
  selector: 'cb-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
})
export class SummaryStepComponent implements OnInit {
  @Input() subscriptionInformation: SubscriptionInformation;
  @Input() generalInformation: GeneralInformation;
  @Input() nugetInformation: NugetInformation;

  constructor() {}

  ngOnInit(): void {}
}
