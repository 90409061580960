import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ClrWizard } from '@clr/angular';
import { SubscriptionInformation } from '../../models/subscription-information';
import { GeneralInformation } from '../../models/general-information';
import { NugetInformation } from '../../models/nuget-information';
import { CreateNewTenantRequest } from '../../models/create-new-tenant-request';
import { TenantService } from 'src/app/modules/shared/services/tenant.service';
import { ErrorCodes } from 'src/app/modules/shared/domain/enums/error-codes';

@Component({
  selector: 'cb-customer-builder',
  templateUrl: './customer-builder.component.html',
  styleUrls: ['./customer-builder.component.scss'],
})
export class CustomerBuilderComponent implements OnInit {
  @ViewChild('wizard') wizard: ClrWizard;

  isSubscriptionInformationFormValid: boolean = false;
  subscriptionInformation: SubscriptionInformation;

  isGeneralInformationFormValid: boolean = false;
  generalInformation: GeneralInformation;

  isNugetInformationFormValid: boolean = false;
  nugetInformation: NugetInformation;

  creationInProgress: boolean = true;
  creationSuccess: boolean = false;

  creationError: boolean = false;
  errorMessage: string =
    'Something went wrong, your customer has not been created!';

  constructor(private router: Router, private tenantService: TenantService) {}

  ngOnInit(): void {}

  async doCustomClick(buttonType: 'welcome-next' | 'create'): Promise<void> {
    switch (buttonType) {
      case 'welcome-next':
        this.wizard.next();
        break;
      case 'create':
        this.wizard.next();
        this.createNewTenant();
        break;
    }
  }

  setSubscriptionInformation(subscriptionInformationForm: FormGroup): void {
    this.isSubscriptionInformationFormValid = subscriptionInformationForm.valid;

    if (this.isSubscriptionInformationFormValid) {
      this.subscriptionInformation = subscriptionInformationForm.value;
    }
  }

  setGeneralInformation(generalInformationForm: FormGroup): void {
    this.isGeneralInformationFormValid = generalInformationForm.valid;

    if (this.isGeneralInformationFormValid) {
      this.generalInformation = generalInformationForm.value;
    }
  }

  setNugetInformation(nugetInformationForm: FormGroup): void {
    this.isNugetInformationFormValid = nugetInformationForm.valid;

    if (this.isNugetInformationFormValid) {
      this.nugetInformation = nugetInformationForm.value;
    }
  }

  createNewTenant() {
    const tenant: CreateNewTenantRequest = {
      subscriptionName: this.subscriptionInformation.subscriptionName,
      subscriptionId: this.subscriptionInformation.subscriptionId,
      tenantName: this.generalInformation.name,
      customDomainName: this.generalInformation.domain,
      chocolateyRepository: this.generalInformation.chocolateyRepositoryUrl,
      deploymentBranch: this.generalInformation.deploymentBranch,
      nugetRepositoryUrl: this.nugetInformation.nugetRepositoryUrl,
      nugetRwUsername: this.nugetInformation.nugetRwUsername,
      nugetRwPassword: this.nugetInformation.nugetRwPassword,
      nugetRoUsername: this.nugetInformation.nugetRoUsername,
      nugetRoPassword: this.nugetInformation.nugetRoPassword,
    };

    this.tenantService.createNewTenant(tenant).subscribe({
      next: (response) => {
        // TODO: Send newly created tenant to creating-step to show info when success.
        this.creationInProgress = false;
        this.creationError = false;
        this.creationSuccess = true;
      },
      error: (response) => {
        this.creationInProgress = false;
        this.creationSuccess = false;
        this.creationError = true;

        switch (response.error.errorCode) {
          case ErrorCodes.InitialPipelineRunException: {
            this.errorMessage = response.error.message;
            break;
          }
          case ErrorCodes.GenericException: {
            this.errorMessage = response.error.message;
            break;
          }
          default: {
            console.error(response);
            break;
          }
        }
      },
    });
  }

  handleCancel() {
    if (confirm('Are you sure? All changes will be lost.')) {
      this.router.navigate(['/msp/customers']);
    }
  }

  handleClose() {
    this.router.navigate(['/msp/customers']);
  }
}
