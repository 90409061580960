import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { LocalStorageService } from 'src/app/modules/shared/services/local-storage.service';
import { saveAs } from 'file-saver';

const COPY_TO_CLIPBOARD = 'copy to clipboard';
const COPIED = 'copied!';
const MAX_CHARACTERS = 100_000;
@Component({
  selector: 'nd-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent {
  copyButtonText = COPY_TO_CLIPBOARD;

  @ViewChild('consoleBody', { static: true }) consoleBodyRef: ElementRef;

  private _text: string;

  @Input() set text(value: string) {
    // Reverse the logs so that the latest ones appear on top.
    // Any line that is immediately followed by a non-empty line (i.e., a line with leading whitespace)
    // will not be split. This is done so that nested information like exceptions are not reversed!
    this._text = this.reverseText(value);
    this.copyButtonText = COPY_TO_CLIPBOARD;
    this.consoleBodyRef.nativeElement.scrollTop = 0;
  }

  get text(): string {
    return this._text;
  }

  get shouldDisplayText(): boolean {
    return (this.text?.length ?? 0) < MAX_CHARACTERS;
  }

  constructor(
    private clipboard: Clipboard,
    public localStorageService: LocalStorageService
  ) {}

  onToggleMode(): void {
    this.localStorageService.isLightTheme =
      !this.localStorageService.isLightTheme;
  }

  onCopy(): void {
    const pending = this.clipboard.beginCopy(this.text);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.copyButtonText = COPIED;
        pending.destroy();
      }
    };
    attempt();
  }

  onOpenInNewTab() {
    var file = new Blob([this.reverseText(this.text)], { type: 'text/plain' });
    saveAs(file, 'nimdeploy.log');
  }

  reverseText(text: string): string {
    return text
      .split(/\n(?!\s+)/)
      .reverse()
      .join('\n');
  }
}
