import { Pipe, PipeTransform } from '@angular/core';


/**
 * transform - Takes in an enum and returns an array of strings, listing its values.
 *
 * @param {any} value - The enum whose values are to be listed.
 * @returns {string[]} - An array of the enum values as a string.
 *
 * @example
 * enum Color {
 *   Red = 'RED',
 *   Green = 'GREEN',
 *   Blue = 'BLUE',
 *   Yellow = 'YELLOW'
 * }
 * 
 * result ['Red', 'Green', 'Blue', 'Yellow']
 */
@Pipe({
  name: 'enumToList'
})
export class EnumToListPipe implements PipeTransform {
  transform(value: any): string[] {
    return Object.keys(value).slice(-Object.keys(value).length / 2);
  }
}
