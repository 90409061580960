<div class="card rounded-0">
    <clr-progress-bar *ngIf="isLoadingActions" clrValue="75" clrFade="true" clrLoop></clr-progress-bar>
    <div class="row g-0">
        <div class="col-md-3">
            <nd-log-list [listItems]="listItems" (onItemSelected)="onItemSelected($event)"></nd-log-list>
        </div>
        <div class="col-md-9">
            <nd-terminal [text]="logText"></nd-terminal>
        </div>
    </div>
</div>

<div *ngIf="selectedAction" id="status" class="py-2 ps-1">
    <cds-icon shape="angle" direction="right" size="md" solid></cds-icon>
    <img
        [src]="selectedAction.action.actionVersion[0].iconUri ? selectedAction.action.actionVersion[0].iconUri : '../../../../assets/nimdeploy-logo-shadow.svg'">
    <div>
        <span>
            <span [ngSwitch]="selectedAction.actionStatus">
                <cds-icon *ngSwitchCase="actionStatus.UpToDate" shape="success-standard" status="success" size="sm"
                    solid></cds-icon>
                <cds-icon *ngSwitchCase="actionStatus.InProgress" shape="" status="info" size="sm" solid></cds-icon>
                <cds-icon *ngSwitchCase="actionStatus.Failed" shape="error-standard" solid="true" status="danger"
                    size="sm" solid></cds-icon>
                <cds-icon *ngSwitchCase="actionStatus.NotUpToDate" shape="warning-standard" solid="true"
                    status="warning" size="sm" solid></cds-icon>
                <cds-icon *ngSwitchDefault shape="minus-circle" solid="true" status="default" size="sm"
                    solid></cds-icon>
            </span>
            <h4>{{selectedAction.action.name}}</h4>
        </span>
        <div [ngSwitch]="selectedAction.actionStatus">
            <span *ngSwitchCase="actionStatus.UpToDate">Installed</span>
            <span *ngSwitchCase="actionStatus.InProgress">Installation in Progress</span>
            <span *ngSwitchCase="actionStatus.Failed">Installation Failed</span>
            <span *ngSwitchCase="actionStatus.NotUpToDate">Pending Update</span>
            <span *ngSwitchDefault>Not Installed</span>
        </div>
    </div>
</div>
