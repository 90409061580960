import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { BlueprintActionService } from 'src/app/services/blueprint-action.service';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { IBlueprintActions } from 'src/app/domain/interfaces/iblueprintactions';
import { debounce, debounceTime, switchMap } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-blueprints',
  templateUrl: './blueprints.component.html',
  styleUrls: ['./blueprints.component.scss'],
})
export class BlueprintsComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public itemMenu: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public tableMenu: ContextMenuComponent;
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  addNewTemplateVisible: boolean = false;
  viewTemplateDataVisible: boolean = false;
  renameTemplateVisible: boolean = false;
  openDeleteModal: boolean = false;

  searchValue: string = '';
  visible: boolean = false;
  blueprints: IBluePrint[] = [];
  listOfDisplayData: IBluePrint[] = [...this.blueprints];
  newBlueprintName: string = '';
  renameBlueprintName: string = '';

  viewListOfActions; // If you strongly type this to IBlueprintAction[] you will open a can of worms 🪱
  currentBlueprintViewed: number = 0;

  openModal: boolean = false;
  selectedBlueprints: IBluePrint[] = [];

  newBlueprintDescription: string = '';
  renameBlueprintDescription: string = '';

  blueprintsLoading: boolean = true;
  isLoadingDetails: boolean = false;
  detailState: IBluePrint;

  constructor(
    private router: Router,
    private blueprintService: BlueprintService,
    private blueprintActionService: BlueprintActionService
  ) {}

  ngOnInit(): void {
    this.GetBlueprints();
  }

  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  RouteTemplateDetails(templateId: number) {
    this.router.navigate(['/blueprintdetails', templateId]);
  }

  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  closeDeleteModal() {
    this.openDeleteModal = false;
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    this.listOfDisplayData = this.blueprints.filter(
      (item: any) => item.name.indexOf(this.searchValue) !== -1
    );
  }

  showAddNewBlueprintModal(): void {
    this.addNewTemplateVisible = true;
  }

  showTemplateDataListModal(id) {
    this.currentBlueprintViewed = id;
    this.isLoadingDetails = true;
    this.viewListOfActions = [];
    this.blueprintActionService.GetBlueprintActionsByBlueprint(id).subscribe({
      next: (v) => {
        this.viewListOfActions = v;
        this.isLoadingDetails = false;
      },
      error: (_) => {
        this.isLoadingDetails = false;
      },
    });
  }

  handleOkNewBlueprint(): void {
    this.PostNewBlueprint(this.newBlueprintName, this.newBlueprintDescription);
    this.newBlueprintName = '';
    this.newBlueprintDescription = '';
    this.addNewTemplateVisible = false;
  }

  handleCancelNewBlueprint(): void {
    this.newBlueprintName = '';
    this.newBlueprintDescription = '';
    this.addNewTemplateVisible = false;
  }

  handleOkTemplateActions(): void {
    this.viewTemplateDataVisible = false;
    this.router.navigate(['/blueprintdetails', this.currentBlueprintViewed]);
  }

  handleCancelTemplateActions(): void {
    this.viewTemplateDataVisible = false;
  }

  showRenameTemplateModal(selectedBP: IBluePrint[]) {
    this.currentBlueprintViewed = selectedBP[0].id;
    var desc = this.blueprints.filter((curr) => curr.id === selectedBP[0].id)[0]
      .description;
    this.renameBlueprintName = this.blueprints.filter(
      (curr) => curr.id === selectedBP[0].id
    )[0].name;
    desc != null
      ? (this.renameBlueprintDescription = desc)
      : (this.renameBlueprintDescription = '');
    this.renameTemplateVisible = true;
  }

  handleOkRenameBlueprint(): void {
    this.blueprintService
      .UpdateSingleBlueprint(
        this.currentBlueprintViewed,
        this.renameBlueprintName,
        this.renameBlueprintDescription
      )
      .subscribe((v) => {
        this.GetBlueprints();
      });
    this.renameBlueprintName = '';
    this.renameBlueprintDescription = '';
    this.renameTemplateVisible = false;
  }

  handleCancelRenameBlueprint(): void {
    this.renameBlueprintName = '';
    this.renameBlueprintDescription = '';
    this.renameTemplateVisible = false;
  }

  onDetailOpen(blueprint: IBluePrint) {
    if (blueprint) {
      this.showTemplateDataListModal(blueprint.id);
    }
  }

  blueprintSelectionChanged(blueprints: IBluePrint[]) {
    this.selectedBlueprints = blueprints;
  }

  updateBlueprintAction(blueprint: IBlueprintActions): void {
    this.blueprintActionService.updateBlueprintAction(blueprint).subscribe({
      error: (error) =>
        console.error(`Error updating blueprint action: ${error}`),
    });
  }

  GetBlueprints() {
    this.blueprintService.GetBluePrint().subscribe((v) => {
      this.blueprints = v;
      this.listOfDisplayData = [...this.blueprints];
      this.blueprintsLoading = false;
    });
  }

  PostNewBlueprint(name: string, description: string) {
    this.blueprintService
      .PostSingleBlueprint(name, description)
      .subscribe((v) => {
        this.GetBlueprints();
      });
  }

  CloneBlueprint(selectedBP: IBluePrint[]) {
    for (let index = 0; index < selectedBP.length; index++) {
      var currBP = this.blueprints.filter(
        (curr) => curr.id === selectedBP[index].id
      )[0];
      this.blueprintService
        .PostSingleBlueprint('Clone of: ' + currBP.name)
        .subscribe((newBlueprint) => {
          this.blueprintActionService
            .GetBlueprintActionsByBlueprint(selectedBP[index].id)
            .subscribe(async (cloneActions) => {
              var data = [];
              var index = 1;
              //@ts-ignore
              await cloneActions.forEach((element) => {
                var temp = {
                  order: index,
                  actionId: element.actionId,
                  blueprintId: newBlueprint.id,
                };
                data.push(temp);
                index++;
                //@ts-ignore
                if (data.length == cloneActions.length) {
                  this.blueprintActionService
                    .PostBluePrintActions(data)
                    .subscribe((v) => {
                      this.GetBlueprints();
                    });
                }
              });
            });
        });
    }
  }

  DeleteBlueprint(selectedBP: IBluePrint[]) {
    for (let index = 0; index < selectedBP.length; index++) {
      this.blueprintService
        .DeleteSingleBlueprintByID(selectedBP[index].id)
        .subscribe({
          next: (v) => {
            this.GetBlueprints();
            this.closeDeleteModal();
          },
          error: (e) => {
            this.closeDeleteModal();
            this.notify(
              "One or more blueprints couldn't be deleted as they're applied to at least one group or endpoint.",
              'danger'
            );
          },
        });
    }
  }

  closeDetail() {
    this.detailState = null;
  }
}
