import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { toArray } from 'src/app/utils/to-array';
import { Stack } from 'src/app/utils/stack';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DirectoryNode,
  FileNode,
  isDirectoryNode,
} from '../../models/file-system-tree';
import { FilePathHandle } from '../../models/file-system-path-handle';
import { BuilderData } from '../../models/builder';

@Component({
  selector: 'pb-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss'],
})
export class FileManagementComponent implements OnChanges {
  @Output() packageFilesEmitter = new EventEmitter<FilePathHandle[]>();
  @Input() builderData?: BuilderData;
  fileSystemTreeRoot: DirectoryNode = { children: [], name: '.' };
  previousDir: DirectoryNode;
  breadcrumbs: string[] = [this.fileSystemTreeRoot.name];
  stack: Stack<DirectoryNode> = new Stack<DirectoryNode>();
  allFiles: FileNode[] = [];

  constructor(private snackBarService: MatSnackBar) {
    this.stack.push(this.fileSystemTreeRoot);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.builderData && this.builderData) {
      this.builderData.files.forEach((packageFile) => {
        const directory = this.getParentDirectoryOfFile(packageFile.path);
        const splitPath = packageFile.path.split('/');
        const fileNode: FileNode = {
          name: splitPath[splitPath.length - 1],
          data: {
            path: packageFile.path,
            file: {
              downloadUri: packageFile.downloadUri,
              hash: packageFile.hash,
            },
          },
        };
        directory.children.push(fileNode);
        this.allFiles.push(fileNode);
      });

      this.emitAllFiles();
    }
  }

  getParentDirectoryOfFile(path: string): DirectoryNode {
    const normalizedPath = path
      .replace(/^(\.\/|\.\\|\/|\\)/, '')
      .replace(/(\/|\\)/, '/');
    const segments = normalizedPath.split('/').slice(0, -1);

    if (segments.length) {
      let current = this.fileSystemTreeRoot;

      for (let segment of segments) {
        const foundDir = current.children.find(
          (c) => isDirectoryNode(c) && c.name === segment
        );
        if (foundDir) {
          current = foundDir as DirectoryNode;
          continue;
        }
        const newDir: DirectoryNode = { name: segment, children: [] };
        current.children.push(newDir);
        current = newDir;
      }

      return current;
    } else return this.fileSystemTreeRoot;
  }

  async dropHandler(event): Promise<void> {
    event.preventDefault();

    const droppedItems: DataTransferItem[] = [...event.dataTransfer.items];

    const handles = await Promise.all(
      droppedItems.map((item) => item.getAsFileSystemHandle())
    );

    await this.populateTree(
      this.stack.peek(),
      handles,
      this.breadcrumbs.join('/')
    );
    this.emitAllFiles();
  }

  dragOverHandler(event: DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  async browseDirectory(): Promise<void> {
    const options = {
      multiple: true,
    };

    const fileHandles: FileSystemFileHandle[] = await window.showOpenFilePicker(
      options
    );

    await this.populateTree(
      this.stack.peek(),
      fileHandles,
      this.breadcrumbs.join('/')
    );
    this.emitAllFiles();
  }

  async populateTree(
    root: DirectoryNode,
    children: FileSystemHandle[],
    currentPath: string,
    maxDepth = 100
  ): Promise<void> {
    if (!maxDepth) return;

    const files = children.filter(
      (c) => c.kind === 'file'
    ) as FileSystemFileHandle[];

    const directories = children.filter(
      (c) => c.kind === 'directory'
    ) as FileSystemDirectoryHandle[];

    const childFileNodes = files.map((f) => {
      return {
        name: f.name,
        data: {
          path: [currentPath, f.name].join('/'),
          file: f,
        },
      } as FileNode;
    });
    root.children = root.children.concat(childFileNodes);
    childFileNodes.forEach((fileNode) => {
      this.allFiles.push(fileNode);
    });

    const childDirectoryNodes = directories.map((d) => {
      return {
        name: d.name,
        children: [],
      } as DirectoryNode;
    });
    root.children = root.children.concat(childDirectoryNodes);

    for (let i = 0; i < directories.length; i++) {
      const dirChildren: FileSystemHandle[] = await toArray(
        directories[i].values()
      );
      await this.populateTree(
        childDirectoryNodes[i],
        dirChildren,
        [currentPath, directories[i].name].join('/'),
        maxDepth - 1
      );
    }
  }

  listDirectoryContents(dir: DirectoryNode) {
    this.previousDir = this.stack.peek();
    this.stack.push(dir);
    this.breadcrumbs.push(dir.name);
  }

  goBack(): void {
    if (this.stack.size() <= 1) return;
    this.stack.pop();
    this.previousDir = this.stack.atIndex(this.stack.size() - 2);
    this.breadcrumbs.pop();
  }

  goTo(index: number): void {
    let numberOfPops = this.stack.size() - index - 1;
    while (numberOfPops--) {
      this.stack.pop();
      this.breadcrumbs.pop();
    }
  }

  softDeleteHandle(node: DirectoryNode | FileNode): void {
    node.isPruned = true;
    if ('children' in node) {
      node.children.forEach((childNode) => (childNode.isPruned = true));
    }
    const dir = this.stack.pop();
    const filteredChildren = dir.children.filter(
      (childNode) => childNode.name !== node.name
    );
    dir.children = filteredChildren;
    this.stack.push(dir);
    this.showDeleteSnackbar(node);
    this.emitAllFiles();
  }

  showDeleteSnackbar(node: DirectoryNode | FileNode): void {
    const snackBarRef = this.snackBarService.open(
      `"${node.name}" has been deleted.`,
      'Undo',
      { duration: 5000 }
    );

    snackBarRef.onAction().subscribe(() => {
      node.isPruned = false;
      const dir = this.stack.pop();
      dir.children.push(node);
      this.stack.push(dir);
    });
  }

  emitAllFiles(): void {
    const filesToUpload = this.allFiles
      .filter((fileNode) => !fileNode.isPruned)
      .map((fileNode) => fileNode.data);
    this.packageFilesEmitter.emit(filesToUpload);
  }
}
