import { Component, OnInit } from '@angular/core';
import { Action } from '../../shared/domain/action';
import { ActionService } from '../../shared/services/action.service';
import { debounce } from 'rxjs/operators';
import { Subject, interval } from 'rxjs';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { environment } from 'src/environments/environment';

enum ShowApplications {
  AllApplications = 'all-applications',
  Subscribed = 'subscribed',
  NotSubscribed = 'not-subscribed',
}

@Component({
  selector: 'ps-package-subscription-page',
  templateUrl: './package-subscription-page.component.html',
  styleUrls: ['./package-subscription-page.component.scss'],
})
export class PackageSubscriptionPageComponent implements OnInit {
  private readonly querySubject = new Subject();
  actions: Action[] = [];
  subscribedActions: Action[] = [];
  filteredActions: Action[] = [];
  isLoading = true;
  searchQuery = '';
  isSubscriptionExpanded = true;
  showApps = ShowApplications.AllApplications;

  filterCallback = (a: Action): boolean => {
    return (
      a.actionVersion.length &&
      this.shouldShowApp(a) &&
      (!this.searchQuery.trim() ||
        (a.name
          ?.toUpperCase()
          .includes(this.searchQuery.trim().toUpperCase()) ??
          false))
    );
  };

  shouldShowApp = (a: Action): boolean => {
    switch (this.showApps) {
      case ShowApplications.AllApplications:
        console.log(true);
        return true;
      case ShowApplications.NotSubscribed:
        console.log(!!!a.isSubscribed);
        return !!!a.isSubscribed;
      default:
        console.log(!!a.isSubscribed);
        return !!a.isSubscribed;
    }
  };

  constructor(
    private actionService: ActionService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const tenantName =
      this.localStorageService.tenantName ?? environment.tenantName;

    this.actionService.getActionsForTenant().subscribe({
      next: (actions) => {
        this.actions = actions;
        this.filteredActions = [...actions];
        actions
          .filter(
            (a) => !!a.subscribedTenants.find((t) => t.name == tenantName)
          )
          .forEach((a) => (a.isSubscribed = true));
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.querySubject.pipe(debounce(() => interval(300))).subscribe(() => {
      this.filteredActions = this.actions.filter(this.filterCallback);
    });
  }

  onQueryChanged(query: string): void {
    this.searchQuery = query;
    this.querySubject.next();
  }

  onSubscribed(action: Action): void {
    this.actionService.subscribeToAction(action.id).subscribe();

    const isSubscribing = !action.isSubscribed;
    action.isSubscribed = isSubscribing;
  }

  toggleSubscriptions() {
    this.isSubscriptionExpanded = !this.isSubscriptionExpanded;
  }

  onShowChanged(show: ShowApplications): void {
    this.showApps = show;
    this.filteredActions = this.actions.filter(this.filterCallback);
  }
}
