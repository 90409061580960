import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Command, Parameter } from '../../models/command';

@Component({
  selector: 'pb-expandable-command-item',
  templateUrl: './expandable-command-item.component.html',
  styleUrls: ['./expandable-command-item.component.scss'],
})
export class ExpandableCommandItemComponent implements OnChanges {
  @Input() command: Command;
  @Input() isReadOnly = false;
  @Input() filePaths: string[] = [];
  @Input() defaultValues: {
    [key: string]: { [key: string]: string | boolean };
  } = {};
  expanded = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValues && this.defaultValues) {
      this.command.parameters.forEach((parameter) => {
        const defaultValuesForThisCommand =
          this.defaultValues[this.command.specification.name];
        if (!defaultValuesForThisCommand) return;
        const defaultValue = defaultValuesForThisCommand[parameter.name];
        if (!defaultValue) return;
        parameter.value = defaultValue;
      });
    }
  }

  expand(): void {
    this.expanded = !this.expanded;
  }

  onCommandParamChange(
    value: string | boolean | null,
    parameter: Parameter
  ): void {
    parameter.value = value;
    this.handleParamChangeForChocolateyInstallPackage(parameter);
  }

  handleParamChangeForChocolateyInstallPackage(parameter: Parameter) {
    if (
      this.command.specification.name !== 'Install-ChocolateyInstallPackage' ||
      parameter.name !== 'File'
    )
      return;

    const fileTypeParameter = this.command.parameters.find(
      (p) => p.name === 'FileType'
    );
    if (!fileTypeParameter) return;

    const splitValue = (parameter.value as string).split('.');
    if (splitValue.length <= 1) return;

    fileTypeParameter.value = splitValue[splitValue.length - 1];
  }
}
