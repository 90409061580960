import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cb-subscription-information-step',
  templateUrl: './subscription-information-step.component.html',
  styleUrls: ['./subscription-information-step.component.scss'],
})
export class SubscriptionInformationStepComponent implements OnInit {
  @Output() subscriptionInformationFormEmitter = new EventEmitter<FormGroup>();
  @Output() isFormValid = new EventEmitter<boolean>();

  subscriptionInformationForm: FormGroup = new FormGroup({
    subscriptionName: new FormControl('', Validators.required),
    subscriptionId: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      ),
    ]),
  });

  constructor() {}

  ngOnInit(): void {}

  validateForm(): void {
    this.isFormValid.emit(this.subscriptionInformationForm.valid);
    this.subscriptionInformationFormEmitter.emit(
      this.subscriptionInformationForm
    );
  }
}
