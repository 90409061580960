import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cb-nuget-information-step',
  templateUrl: './nuget-information-step.component.html',
  styleUrls: ['./nuget-information-step.component.scss'],
})
export class NugetInformationStepComponent implements OnInit {
  @Output() nugetInformationFormEmitter = new EventEmitter<FormGroup>();
  @Output() isFormValid = new EventEmitter<boolean>();

  nugetInformationForm: FormGroup = new FormGroup({
    nugetRepositoryUrl: new FormControl('', Validators.required),
    nugetRwUsername: new FormControl('', Validators.required),
    nugetRwPassword: new FormControl('', Validators.required),
    nugetRoUsername: new FormControl('', Validators.required),
    nugetRoPassword: new FormControl('', Validators.required),
  });

  constructor() {}

  ngOnInit(): void {}

  validateForm(): void {
    this.isFormValid.emit(this.nugetInformationForm.valid);
    this.nugetInformationFormEmitter.emit(this.nugetInformationForm);
  }
}
