<div class="card table-container-card">
    <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
        <div class="table-title">
            <cds-icon shape="recycle" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
            <h3>Recyle Bin</h3>
        </div>
        <div class="action-group">
            <ng-container *ngIf="!isLoadingPackages && !configuration.isAutomaticCleanupOn">
                <div class="me-2"><clr-icon shape="info"></clr-icon>Automatic cleanup is <strong>not</strong> turned on
                </div>
            </ng-container>

            <button type="button" class="btn btn-icon btn-success btn-md" #tooltip="matTooltip"
                matTooltip="Restore selected packages" [matTooltipPosition]="'above'" matTooltipHideDelay="300"
                (click)="confirmModalOpen = true">
                <clr-icon shape="backup-restore"></clr-icon>
            </button>
            <button type="button" class="btn btn-icon btn-primary btn-md"
                [routerLink]="isMspDashboard ? '/msp/recycle-bin/configure' : '/recycle-bin/configure'"
                #tooltip="matTooltip" matTooltip="Configure the automatic cleanup" [matTooltipPosition]="'above'"
                matTooltipHideDelay="300">
                <clr-icon shape="cog"></clr-icon>
                Configure Recyle Bin
            </button>
        </div>
    </div>

    <clr-datagrid id="datagrid" [(clrDgSelected)]="selectedActions" [clrDgLoading]="isLoadingPackages"
        class="datagrid-compact" style="width: 100%;">
        <clr-dg-column [clrDgField]="'name'">Package</clr-dg-column>
        <clr-dg-column>Deleted Versions</clr-dg-column>
        <clr-dg-row *clrDgItems="let action of actions" [clrDgItem]="action" style="cursor: pointer;">
            <clr-dg-cell #refEl (click)="clickRow(refEl)" style="display: flex; height: 40px;
        justify-content: space-between;">{{action.name}}
            </clr-dg-cell>
            <clr-dg-cell #refEl (click)="clickRow(refEl)">
                {{action.versionCount}}
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="100">
                <clr-dg-page-size [clrPageSizeOptions]="[15,30,45,60,75,90,100]">Packages per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Packages
            </clr-dg-pagination>
        </clr-dg-footer>
        <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
            <clr-dg-detail>
                <clr-dg-detail-header>
                    <div style="display: flex; justify-content: space-between;">
                        <span>{{detail.name}}</span>
                        <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
                            <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
                        </button>
                    </div>
                </clr-dg-detail-header>
                <clr-dg-detail-body>
                    <clr-tabs>
                        <clr-tab>
                            <button clrTabLink>Versions</button>
                            <clr-tab-content *clrIfActive="true">
                                <clr-datagrid [clrDgLoading]="versionsAreLoading" class="datagrid-compact"
                                    id="datagrid">
                                    <clr-dg-column>Deleted Version</clr-dg-column>
                                    <clr-dg-column>Days remaining</clr-dg-column>
                                    <clr-dg-column>Action</clr-dg-column>
                                    <clr-dg-row *clrDgItems="let version of actionVersions" [clrDgItem]="version">
                                        <clr-dg-cell>{{version.actionVersion.version}}</clr-dg-cell>
                                        <clr-dg-cell>{{version.daysRemaining}} day(s)</clr-dg-cell>
                                        <clr-dg-cell>
                                            <button [disabled]="isRestored(version.actionVersion)" type="button"
                                                class="btn btn-icon btn-success btn-sm m-0" #tooltip="matTooltip"
                                                matTooltip="Restore this version" [matTooltipPosition]="'above'"
                                                matTooltipHideDelay="100" (click)="onRestore(version.actionVersion)">
                                                <cds-icon
                                                    [shape]="isRestored(version.actionVersion) ? 'check' : 'backup-restore'"></cds-icon>
                                                {{isRestored(version.actionVersion) ? 'Restored' : 'Restore'}}
                                            </button>
                                        </clr-dg-cell>
                                    </clr-dg-row>
                                    <clr-dg-footer>
                                        <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Versions per
                                                page</clr-dg-page-size>
                                            {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of
                                            {{paginationBP.totalItems}}
                                            versions
                                        </clr-dg-pagination>
                                    </clr-dg-footer>
                                </clr-datagrid>

                            </clr-tab-content>
                        </clr-tab>
                    </clr-tabs>
                </clr-dg-detail-body>
            </clr-dg-detail>
        </ng-template>
    </clr-datagrid>
</div>

<clr-modal [(clrModalOpen)]="confirmModalOpen">
    <h3 class="modal-title">Are you sure?</h3>
    <div class="modal-body">
        <p>This will restore all of the versions in the selected actions.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="confirmModalOpen = false">Cancel</button>
        <button type="button" class="btn btn-primary"
            (click)="confirmModalOpen = false; restoreSelectedPackages();">Ok</button>
    </div>
</clr-modal>