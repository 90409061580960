<app-notify-me #notification></app-notify-me>

<nd-back-button></nd-back-button>

<div class="card p-3">
    <div *ngIf="isLoading">
        <div class="row">
            <div class="col-sm">
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
            </div>
            <div class="col-sm">
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
            </div>
            <div class="col-sm">
                <div class="skeleton"></div>
                <div class="skeleton"></div>
                <div class="skeleton"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading">
        <div class="row">
            <div class="col-sm">
                <div id="testing" [class.msp]="isAnMspDashboard">
                    <span>{{isAnMspDashboard ? 'Sandbox' : 'Testing'}} ({{(testingActions | filter:
                        filterActionsWithoutVersions).length}})</span>
                </div>
                <nd-package-lifecycle-column [actions]="testingActions" id="testing-list" cdkDropListSortingDisabled
                    [cdkDropListConnectedTo]="['acceptance-list', 'production-list']" cdkDropList
                    (dropEvent)="drop($event)" (moveEvent)="move($event, 'testing-list')"
                    [moveAllButtonText]="!isAnMspDashboard ? 'Move all versions to Acceptance' : 'Move all versions to Quality Assurance'">
                </nd-package-lifecycle-column>
            </div>
            <div class="col-sm">
                <div id="acceptance" [class.msp]="isAnMspDashboard">
                    <span>{{isAnMspDashboard ? 'Quality Assurance' : 'Acceptance'}} ({{(acceptanceActions | filter:
                        filterActionsWithoutVersions).length}})</span>
                </div>
                <nd-package-lifecycle-column [actions]="acceptanceActions" id="acceptance-list"
                    cdkDropListSortingDisabled [cdkDropListConnectedTo]="['testing-list', 'production-list']"
                    cdkDropList (dropEvent)="drop($event)" (moveEvent)="move($event, 'acceptance-list')"
                    [moveAllButtonText]="!isAnMspDashboard ? 'Move all versions to Production' : 'Move all versions to Operational'">
                </nd-package-lifecycle-column>
            </div>
            <div class="col-sm">
                <div id="production" [class.msp]="isAnMspDashboard">
                    <span>{{isAnMspDashboard ? 'Operational' : 'Production'}} ({{(productionActions | filter:
                        filterActionsWithoutVersions).length}})</span>
                </div>
                <nd-package-lifecycle-column [hasButtons]="false" [actions]="productionActions" id="production-list"
                    cdkDropListSortingDisabled [cdkDropListConnectedTo]="['testing-list', 'acceptance-list']"
                    cdkDropList (dropEvent)="drop($event)"
                    (moveEvent)="move($event, 'production-list')"></nd-package-lifecycle-column>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button [clrLoading]="saveButtonState" (click)="saveChanges()"
                class="btn btn-success px-5 flex-end">Save</button>
        </div>
    </div>
</div>