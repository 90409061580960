import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActionStatus } from 'src/app/domain/enums/action-status';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { LogListItem } from 'src/app/domain/interfaces/log-list-item';
import { BlobService } from 'src/app/modules/package-builder/services/blob.service';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { AssignmentService } from 'src/app/services/tasklist.service';

@Component({
  selector: 'nd-package-logs',
  templateUrl: './package-logs.component.html',
  styleUrls: ['./package-logs.component.scss'],
})
export class PackageLogsComponent implements OnInit {
  listItems: LogListItem[] = [];
  assignmentActions: IAssignmentAction[] = [];
  isLoadingActions = true;
  endpointId: number;
  logText: string;
  selectedAction: IAssignmentAction;
  actionStatus = ActionStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private assignmentActionService: AssignmentActionService,
    private assignmentService: AssignmentService,
    private blobService: BlobService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        tap(() => (this.isLoadingActions = true)),
        switchMap((params) => {
          this.endpointId = +params.get('endpointId');
          if (this.endpointId) return of(this.endpointId);
          return EMPTY;
        }),
        switchMap((endpointId) =>
          this.assignmentService.GetAssignmentByEndpointID(endpointId)
        ),
        switchMap((assignment) =>
          this.assignmentActionService.GetAssignmentActionByAssignmentID(
            assignment[0].id
          )
        )
      )
      .subscribe((assignmentActions) => {
        this.isLoadingActions = false;
        this.assignmentActions = assignmentActions;
        this.listItems = assignmentActions.map((a) => ({
          name: a.action.name,
          icon: a.action?.actionVersion[0].iconUri,
        }));
      });
  }

  onItemSelected(name: string): void {
    if (this.selectedAction?.action?.name === name) return;
    
    const selected = this.assignmentActions.find((a) => a.action.name === name);
    this.selectedAction = selected;

    this.logText = 'Loading...';

    this.blobService
      .getLog(this.endpointId, `${selected.action.parameters}.log`)
      .subscribe({
        next: (logs) => (this.logText = logs),
        error: (err) => {
          console.error(err);
          if (err.status === 404) {
            this.logText = '[!] No logs for this package uploaded.';
          } else {
            this.logText = '[!] Failed to get logs.';
          }
        },
      });
  }
}
