import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlueprintActionService } from 'src/app/services/blueprint-action.service';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
  CdkDragExit,
  CdkDragEnter,
} from '@angular/cdk/drag-drop';
import _ from 'lodash';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import compareVersions from 'compare-versions';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { ActionStatus } from 'src/app/domain/enums/action-status';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-blueprint-details',
  templateUrl: './blueprint-details.component.html',
  styleUrls: ['./blueprint-details.component.scss'],
})
export class BlueprintDetailsComponent implements OnInit {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  SelectedPackage;
  ChocOutputReady: boolean = true;
  selectedInputValue: string = '';
  inputValue: string = '';
  Actions: any;
  ActionsBackup: any;
  SelectedActions: any[] = [];
  data: any;
  saveButtonState = ClrLoadingState.DEFAULT;
  isLoadingPage = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tSvc: ActionService,
    private bSvc: BlueprintService,
    private bASvc: BlueprintActionService,
    private avSvc: ActionversionService
  ) {}

  templateId = 0;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.templateId = +params['blueprintId'];
      // this.loadComponentAgain();
      this.GetBlueprintData(+params['blueprintId']);
    });
    this.GetActions();
  }
  onSourceListExited(event: CdkDragExit<any>) {
    var findItem = this.Actions.filter(
      (n) =>
        n.name == event.item.data.name &&
        n.actionType.id == event.item.data.actionType.id
    )[0];
    this.Actions.splice(this.Actions.indexOf(findItem) + 1, 0, {
      ...event.item.data,
      temp: true,
    });
  }

  onSourceListEntered(event: CdkDragEnter<any>) {
    _.remove(this.Actions, { temp: true });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.container.data.indexOf(event.item.data),
        event.currentIndex
      );
    } else {
      if (event.item.data.actionVersion.length > 0) {
        event.item.data['selectedVersion'] = this.ReturnLatestActionVersion(
          event.item.data
        );
      } else {
        event.item.data['selectedVersion'] = {
          version: '0.0.0.0',
        };
      }

      copyArrayItem(
        _.cloneDeep(event.previousContainer.data),
        event.container.data,
        event.previousContainer.data.indexOf(event.item.data),
        event.currentIndex
      );
    }
    if (event.previousContainer.data) {
      _.remove(this.Actions, { temp: true });
    }
    if (event.previousContainer.data) {
      _.remove(this.Actions, { temp: true });
    }
  }
  dropSelected(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.container.data.indexOf(event.item.data),
        event.currentIndex
      );
    } else {
      this.SelectedActions.splice(event.previousIndex, 1);
    }
  }

  GetBlueprintData(id) {
    this.bSvc.GetSingleBlueprintById(id).subscribe((v) => {
      this.data = v;
    });
  }

  GetBlueprintActionsByBlueprint() {
    this.bASvc
      .GetBlueprintActionsByBlueprint(this.templateId)
      .subscribe((v) => {
        var data = [];
        //@ts-ignore
        v.forEach((element) => {
          element.action.order = element.order;
          element.action.assignmentActionId = element.id;
          element.action.actionStatus = element.actionStatus;
          element.action.isInstall = element.isInstall;
          element.action.selectedVersion = element.actionVersion;
          element.action.isVersionFixed = element.isVersionFixed;
          data.push(element.action);
        });

        this.GetIcons(data);
        this.SelectedActions = data;

        this.isLoadingPage = false;
      });
  }

  GetFixedBordColour(bool) {
    if (!bool) {
      return '#3C8500';
    } else {
      return '#808080';
    }
  }

  RemoveAction(action) {
    this.SelectedActions = this.SelectedActions.filter((v) => v != action);
    this.notify(`${action.name} has been removed!`, 'info');
  }

  PostBlueprintDetails() {
    var data = [];
    var index = 1;
    this.SelectedActions.forEach((element) => {
      var temp = {
        order: index,
        actionId: element.id,
        blueprintId: this.templateId,
        isInstall: element.isInstall,
        actionVersionId: element.selectedVersion.id,
        isVersionFixed: element.isVersionFixed,
      };

      data.push(temp);
      index++;
    });

    this.saveButtonState = ClrLoadingState.LOADING;

    this.bASvc.PutBlueprintActions(data, this.templateId).subscribe({
      next: (_) => {
        this.notify('Your blueprint has been saved!', 'success');
        this.saveButtonState = ClrLoadingState.SUCCESS;
      },
      error: (err) => {
        this.notify('Your blueprint failed to save', 'error');
        console.error(err);
        this.saveButtonState = ClrLoadingState.ERROR;
      },
    });
  }

  ReturnLatestActionVersion(arr) {
    var latestVersion = '';
    var versions = [];
    arr.actionVersion.forEach((element) => {
      versions.push(element.version);
    });
    latestVersion = versions.sort(compareVersions).reverse()[0];
    return arr.actionVersion.filter((v) => v.version == latestVersion)[0];
  }

  GetActions() {
    this.tSvc.getActions().subscribe((v) => {
      this.GetIcons(v);
      this.Actions = v;
      this.ActionsBackup = _.cloneDeep(this.Actions);
      this.GetBlueprintActionsByBlueprint();
    });
  }

  AddAction(action, isInstall: boolean) {
    var temp = _.cloneDeep(action);
    temp['isInstall'] = isInstall;
    if (temp.isInstall == true) {
      temp.actionIcon.name = 'download';
    }
    if (temp.isInstall == false) {
      temp.actionIcon.name = 'trash';
    }
    if (temp.isInstall == false && temp.actionType.id > 2) {
      //temporary op ID 2, if double actions deleted, moet ID>1
      temp.actionIcon.name = 'info-standard';
    }

    if (temp.actionVersion.length > 0) {
      temp['selectedVersion'] = this.ReturnLatestActionVersion(temp);
    }

    this.SelectedActions.push(temp);
    // this.handleUnsaved();
    this.notify(`${temp.name} has been added!`, 'success');
  }

  GetIcons(actions) {
    actions.forEach((element) => {
      if (element.isInstall == null) {
        element['isInstall'] = true;
        // element.actionIcon.name = "download"
      }

      element.actionIcon = {};
      if (element.actionStatus == null) {
        element.actionStatus = ActionStatus.NotStarted;
      }

      if (element.isInstall == true) {
        element.actionIcon.name = 'download';
      }
      if (element.isInstall == false) {
        element.actionIcon.name = 'trash';
      }
      if (element.actionType.id > 2) {
        //temporary op ID 2, if double actions deleted, moet ID>1
        element.actionIcon.name = 'info-standard';
      }
    });
  }

  AddEverything() {
    this.Actions.forEach((element, index) => {
      if (element.actionVersion.length > 0) {
        element['selectedVersion'] = this.ReturnLatestActionVersion(element);
        this.SelectedActions.push(element);
      }
    });
    this.notify('Every Package with a version has been added!', 'success');
  }

  DeleteEverything() {
    this.SelectedActions = [];
    this.notify('Every Package has been removed!', 'info');
  }

  ClearAvailableFilterValue() {
    var input;
    this.inputValue = '';
    input = document.getElementById('availableActionInput');
    input.value = '';
    this.filterAvailableActions();
  }

  filterAvailableActions() {
    var input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('availableActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('AvailableActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }
  ClearSelectedFilterValue() {
    var input;
    this.selectedInputValue = '';
    input = document.getElementById('selectedActionInput');
    input.value = '';
    this.FilterSelectedActions();
  }

  FilterSelectedActions() {
    var input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('selectedActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('selectedActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }
  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  SelectAction(actionData) {
    actionData.isInstall = !actionData.isInstall;
    this.GetIcons(this.SelectedActions);

    // this.Actions = this.ActionsBackup.slice()
    // this.Actions = [];
    // this.Actions = x.slice();
    // this.Actions =  this.ActionsBackup.slice();

    // this.PackageDescription = "Loading";
    // this.CurrentPackage = packageData;

    // this.SelectedPackage = this.ChocOutput.filter(x => x.name == packageData.name)[0];
    // // var x = this.ChocOutput.filter(x => x.name == packageData.name);

    // if (this.SelectedPackage == undefined) {
    //   this.SelectedPackage = this.SelectedChocOutput.filter(x => x.name == packageData.name)[0];
    // }
    // // if(x.length == 0){
    // //   x = this.SelectedChocOutput.filter(x => x.name == packageData.name);
    // // }

    // var exec = this._electronService.remote.require('child_process').exec;
    // var cmd = 'choco info ' + packageData.name;
    // var _this = this;

    // exec(cmd, function (error, stdout, stderr) {
    //   _this.PackageDescription = stdout;
    //   _this._cdr.detectChanges();
    // });
  }

  openActionSettingsModal = false;
  availableVersions = [];
  uninstallSetting = false;
  versionFixedSetting = false;
  forceInstallSetting = false;
  selectedVersion;
  actionSettings;

  HandleOkActionSettings() {
    this.openActionSettingsModal = false;
    var selectedAction = this.SelectedActions.find(
      (i) => i.id == this.actionSettings.id
    );
    selectedAction.isInstall = this.uninstallSetting;

    if (
      selectedAction.selectedVersion.version != this.selectedVersion['version']
    ) {
      selectedAction['versionChanged'] = true;
    }
    selectedAction.selectedVersion = this.selectedVersion;
    selectedAction.isVersionFixed = this.versionFixedSetting;
    this.GetIcons(this.SelectedActions);
    // this.selectedVersion = {};
  }
  HandleCancelActionSettings() {
    this.openActionSettingsModal = false;
  }

  ActionSettings(actionSettings) {
    this.actionSettings = actionSettings;
    this.uninstallSetting = actionSettings.isInstall;
    this.versionFixedSetting = actionSettings.isVersionFixed;
    this.avSvc.GetActionVersionByActionID(actionSettings.id).subscribe((v) => {
      //@ts-ignore
      this.availableVersions = v;
      this.openActionSettingsModal = true;
      this.selectedVersion = this.availableVersions[0]; //To be changed with latest version query
    });
    // this.availableVersions = this.Actions.find(i=> i.id == actionSettings.id).actionVersion;
    // this.openActionSettingsModal = true;
    // this.selectedVersion = this.availableVersions[0]; //To be changed with latest version query
  }
}
