import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';
import { EndpointLogsService } from 'src/app/services/endpoint-logs.service';
import { EndpointLog } from 'src/app/domain/interfaces/endpointlog';
import { TerminalTabData } from 'src/app/domain/interfaces/terminal-tab-data';

@Component({
  selector: 'nd-endpoint-logs',
  templateUrl: './endpoint-logs.component.html',
  styleUrls: ['./endpoint-logs.component.scss'],
})
export class EndpointLogsComponent implements OnChanges, OnDestroy {
  @Input() endpointId: number;

  isLoading = true;
  isContentReady = false;
  endpointLog: EndpointLog = null;
  error?: string = null;
  tabs: TerminalTabData[];

  repeatableLogSubscription?: Subscription;

  constructor(private endpointLogsService: EndpointLogsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.endpointId && this.endpointId) {
      this.endpointLogsService.getLogs(this.endpointId).subscribe({
        next: (data) => {
          if (data?.isContentReady) this.prepareData(data);
          else this.error = 'There are no uploaded logs yet';
        },
        error: (err) => this.handleError(err),
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.repeatableLogSubscription?.unsubscribe();
  }

  requestLogs() {
    this.isLoading = true;
    this.endpointLog = null;
    this.tabs = null;
    this.isContentReady = false;
    this.error = null;
    this.endpointLogsService
      .requestLogs(this.endpointId)
      .subscribe({ next: () => this.tryGetLogs() });
  }

  private tryGetLogs() {
    this.repeatableLogSubscription?.unsubscribe();
    this.repeatableLogSubscription = this.repeatableGetLog(
      this.endpointId
    ).subscribe({
      next: (data) => {
        if (data.isContentReady) this.prepareData(data);
        else this.error = "The endpoint didn't respond, please try again later";
      },
      error: (err) => this.handleError(err),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  private repeatableGetLog(
    endpointId: number,
    response?: EndpointLog,
    count = 1,
    delayTime = 3000
  ): Observable<EndpointLog> {
    console.log(response);
    if ((response && response.isContentReady) || count > 10) {
      return of(response);
    }
    return this.endpointLogsService.getLogs(endpointId).pipe(
      delay(delayTime),
      concatMap((response) => this.repeatableGetLog(endpointId, response, count + 1))
    );
  }

  private prepareData(data: EndpointLog) {
    this.endpointLog = data;
    this.isContentReady = true;
    this.error = null;
    this.tabs = this.endpointLog.logs.map((l) => {
      return { title: l.title, content: l.content };
    });
  }

  private handleError(err: any) {
    console.log(err);
    this.error = "Couldn't retrieve logs, please try again later";
  }
}
