<div class=".container-fluid">
    <div class="col-lg-3"> 
        <div class="card clickable" routerLink="/setting">
          <div class="card-header">
            <div class="card-icon card-icon__blue">
              <clr-icon shape="dashboard" style="width: 56px; height: 56px;"></clr-icon>
            </div>
            <p class="card-header__head">All Result</p>
            <h3 class="card-header__data">2012469756</h3>
          </div>
          <div class="card-footer">
            <p class="card-footer__head">
              <clr-icon class="card-footer__icon" shape="angle" size="22"></clr-icon>Today's Result
            </p>
            <h3 class="card-footer__data">1280</h3>
          </div>
        </div>
      </div>
    <div class="row g-1">
        <div class="col-md-7">

            <div class="section-heading" style="border-bottom: none;">Available Endpoints: 
            </div>

            <div class="drag-container" style="height: 700px;" (contextmenu)="ClearSelected()">
            
                <div class="endpoints-parent">
           
                <div id="AvailableActionParent" cdkDropList #pendingList="cdkDropList" [cdkDropListData]="endpoints"
                    style="min-height: 500px;" class="item-list" >
                    <dts-select-container
                    #container="dts-select-container"
                    [(selectedItems)]="selectedDocuments"
                    (select)="someMethod($event)"
        
                    data-cy="select-container"
                    #selectContainer="dts-select-container"
                    [selectMode]="selectMode"
                    [(selectedItems)]="selectedDocuments"
                    [disabled]="disable"
                    [disableRangeSelection]="disableRangeSelection"
                    [selectOnDrag]="selectOnDrag"
                    [selectWithShortcut]="selectWithShortcut"
                    [dragOverItems]="dragOverItems"
                    >
                    <div>
                    <div #refEl class="item-box" *ngFor="let item of endpoints" [dtsSelectItem]="item" (click)="SelectAction(item, refEl)"
                        [cdkDragDisabled]="true" [cdkDragData]="item" (cdkDragStarted)="SelectAction(item)" cdkDrag>
                        <i class="desktop-icon" nz-icon nzType="desktop" nzTheme="outline"></i>
                        <p class="desktop-text">{{item.name}}</p>
                    </div>
                </div>
            </dts-select-container>

                </div>

            </div>

            </div>

            <!-- <div class="section-heading" style="border-top: none;" >Footer
            </div> -->

            <!-- <dts-select-container
  #container="dts-select-container"
  [(selectedItems)]="selectedDocuments"
  (select)="someMethod($event)"
>
  <ul>
    <li [dtsSelectItem]="document" *ngFor="let document of documents">{{ document.name }}</li>
  </ul>
</dts-select-container> -->
            

        </div>
        <div class="col-md-5">
            <div class="section-heading" style="border-bottom: none;">
                <div *ngIf="!selected">Select an Endpoint to get started.</div>
                <div *ngIf="selected">Selected Endpoint: {{selected.name}}</div>
            </div>
            <div class="drag-container" style="height: 750px;">

            <!-- <button [disabled]="!selected" (click)="AddNewAssignmentToEndpoint()" nz-button nzType="primary" nz-tooltip
                nzTooltipTitle="Add a new Assignment"><i nz-icon nzType="plus" nzTheme="outline"></i></button> -->

                
                <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
                    <div style="float:left;">
                      <button [disabled] type="button" (click)="AddNewAssignmentToEndpoint()" class="btn btn-icon btn-success btn-sm" title="Add new Assignment" ><clr-icon shape="plus"></clr-icon></button>
                      <button [disabled]="!multiAction" class="action-item" class="btn btn-icon btn-danger  btn-sm" title="Delete Blueprint" (click)="DeleteAssignments()"><clr-icon shape="trash"></clr-icon></button>

                    </div>  
                  </div>
                <clr-datagrid class="datagrid-compact" [clrDgSelected]="selectedEpAssignments" (clrDgSelectedChange)="selectionChanged($event)"  style="width: 100%;">
                    <clr-dg-column >ID</clr-dg-column>
                    <clr-dg-column >Status</clr-dg-column>
                    <!-- <clr-dg-column >Blueprint Name</clr-dg-column>
                    <clr-dg-column >Actions</clr-dg-column> -->
                  
                    <clr-dg-row *clrDgItems="let data of endpointAssignment"  style="cursor: pointer;" [clrDgItem]="data">
                      <clr-dg-cell (click)="RouteAssignmentDetails(data.id)">{{data.id}}</clr-dg-cell>
                      <clr-dg-cell (click)="RouteAssignmentDetails(data.id)">{{data.assignmentStatus.name}}</clr-dg-cell>
                      <!-- <clr-dg-cell *ngIf="data.blueprint" >{{data.blueprint.name}}</clr-dg-cell>
                      <clr-dg-cell  *ngIf="!data.blueprint">No Blueprints</clr-dg-cell> -->
                      <!-- <clr-dg-cell>  -->
                        <!-- <button (click)="RouteAssignmentDetails(data.id)"   nz-tooltip nzTooltipTitle="Edit Assignment Actions" nz-button nzType="primary" nzShape="circle"><i nz-icon nzType="expand-alt"></i></button>
                        <button (click)="showUpdateAssignment(data.id, data.endpointId, data.assignmentStatusId, data.blueprintId)"  nz-button nzType="primary" nzShape="circle" nz-tooltip nzTooltipTitle="Edit Assignment"><i nz-icon nzType="edit"></i></button>
                        <button (click)="DeleteAssignmentById(data.id)" nz-button nzDanger nzShape="circle" nz-tooltip nzTooltipTitle="Delete Assignment"><i nz-icon nzType="delete"></i></button> -->
                      <!-- </clr-dg-cell> -->
                    </clr-dg-row>
                  
                    <clr-dg-footer>
                      <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Actions per page</clr-dg-page-size>
                        {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}} Actions
                      </clr-dg-pagination>
          
                    </clr-dg-footer>
                  </clr-datagrid>
            <!-- <nz-table [nzSize]="'small'" style="min-height: 340px;" #basicTable [nzData]="endpointAssignment" nzTitle="Assignments of Endpoint: {{selectedEndpointName}}" [nzScroll]="{ y: '340px' }">
                <thead>
                    <tr>
                        <th nzWidth="60px">ID</th>
                        <th nzWidth="100px">Status</th>
                        <th nzWidth="200px">Blueprint Name</th>
                        <th nzWidth="190px">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td>{{data.id}}</td>
                        <td>{{data.assignmentStatus.name}}.</td>
                        <td *ngIf="data.blueprint">{{data.blueprint.name}}</td>
                        <td *ngIf="!data.blueprint">No Blueprint</td>
                        <td>
                            <button (click)="RouteAssignmentDetails(data.id)"   nz-tooltip nzTooltipTitle="Edit Assignment Actions" nz-button nzType="primary" nzShape="circle"><i nz-icon nzType="expand-alt"></i></button>
                            <button (click)="showUpdateAssignment(data.id, data.endpointId, data.assignmentStatusId, data.blueprintId)"  nz-button nzType="primary" nzShape="circle" nz-tooltip nzTooltipTitle="Edit Assignment"><i nz-icon nzType="edit"></i></button>
                            <button (click)="DeleteAssignmentById(data.id)" nz-button nzDanger nzShape="circle" nz-tooltip nzTooltipTitle="Delete Assignment"><i nz-icon nzType="delete"></i></button>
                        </td>
                    </tr>
                </tbody>
            </nz-table> -->
        </div>
        <!-- <div class="section-heading" style="border-top: none;" >Footer
        </div> -->
    </div>
    </div>
</div>

<!-- <app-notify-me [notif]="'notif'"></app-notify-me> -->
<app-notify-me #cmp></app-notify-me>

<button (click)="notify('lol','success')"> dismiss</button>