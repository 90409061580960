<nd-back-button></nd-back-button>

<div *ngIf="isLoadingPage" class="spinner-wrapper">
  <span class="spinner">
    Loading...
  </span>
</div>

<div *ngIf="!isLoadingPage" class="card details-container-card">
  <div class="details-title">
    <img src="../../../assets/icons/right-arrow.svg" />
    <h5 *ngIf="data">{{data.name}}</h5>
  </div>

  <div class="center" *ngIf="!ChocOutputReady">
    <i nz-icon [nzType]="'loading'" style="font-size: 100px;"></i>
  </div>

  <div *ngIf="ChocOutputReady" class="main-container row">
    <div class="col-md-6">
      <div class="section-heading">
        <div class="filter">
          <div>Available Packages: </div>
          <nz-input-group [nzSuffix]="inputClearTpl">
            <input type="text" [(ngModel)]="inputValue" id="availableActionInput" class="form-control"
              placeholder="Filter" (keyup)="filterAvailableActions()" />
          </nz-input-group>
        </div>
        <div class="btn-group btn-icon">
          <button type="button" (click)="AddEverything()" [disabled]="!ChocOutputReady" aria-label="settings"
            #tooltip="matTooltip" class="btn btn-icon btn-primary btn-md" matTooltip="Add all packages"
            [matTooltipPosition]="'below'" matTooltipHideDelay="100">
            <img src="../../../assets/icons/skip.svg" />
          </button>
        </div>
      </div>
      <div cdkScrollable class="scroll-container">
        <div id="AvailableActionParent" cdkDropList #pendingList="cdkDropList" [cdkDropListData]="Actions"
          (cdkDropListExited)="onSourceListExited($event)" (cdkDropListEntered)="onSourceListEntered($event)"
          cdkDropListSortingDisabled [cdkDropListConnectedTo]="[doneList,reviewList]" class="item-list"
          (cdkDropListDropped)="dropSelected($event)">
          <div #refEl class="draggable-item item-box" *ngFor="let item of Actions" [cdkDragData]="item" cdkDrag>
            <div class="item-name">
              {{item.name}}
            </div>
            <button (click)="AddAction(item, true)" type="button" #tooltip="matTooltip"
              matTooltip="Add Installer Package" [matTooltipPosition]="'below'" matTooltipHideDelay="100">
              <cds-icon shape="angle" direction="right"></cds-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="section-heading">
        <div class="btn-group btn-icon">
          <button type="button" (click)="DeleteEverything()" [disabled]="!ChocOutputReady"
            class="btn btn-icon btn-primary btn-md" aria-label="settings" #tooltip="matTooltip"
            matTooltip="Remove every selected package" [matTooltipPosition]="'below'" matTooltipHideDelay="100">
            <img src="../../../assets/icons/rewind.svg" />
          </button>
          <button type="button" (click)="PostBlueprintDetails()" [disabled]="!ChocOutputReady"
            class="btn btn-icon btn-success btn-md" aria-label="settings" #tooltip="matTooltip"
            matTooltip="Save blueprint" [matTooltipPosition]="'below'" matTooltipHideDelay="100">
            <img src="../../../assets/icons/diskette.svg" height="15px" />
          </button>
        </div>
        <div class="filter">
          <div>Selected Packages:</div>
          <input type="text" nz-input [(ngModel)]="selectedInputValue" id="selectedActionInput" class="form-control"
            placeholder="Filter" (keyup)="FilterSelectedActions()" />
        </div>
      </div>
      <div cdkScrollable class="scroll-container">
        <div id="selectedActionParent" cdkDropList #doneList="cdkDropList" [cdkDropListData]="SelectedActions"
          [cdkDropListConnectedTo]="[pendingList,reviewList]" class="item-list" (cdkDropListDropped)="drop($event)">
          <div cdkDrag #refEl class="draggable-item selected-item" *ngFor="let item of SelectedActions"
            [cdkDragData]="item">
            <button (click)="RemoveAction(item)" type="button" #tooltip="matTooltip" matTooltip="Remove Package"
              [matTooltipPosition]="'below'" matTooltipHideDelay="100">
              <cds-icon shape="angle" direction="left"></cds-icon>
            </button>
            <div class="selected-item-details">
              <div class="item-name">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <button class="btn btn-icon btn-primary rounded" (click)="PostBlueprintDetails()" aria-label="settings"
      [clrLoading]="saveButtonState" #tooltip="matTooltip" matTooltip="Save your Blueprint"
      [matTooltipPosition]="'below'" matTooltipHideDelay="100">
      Save
    </button>
  </div>

</div>

<app-notify-me #cmp></app-notify-me>