import { Component, OnInit, ViewChild } from '@angular/core';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { BlueprintActionService } from 'src/app/services/blueprint-action.service';
import { BlueprintService } from 'src/app/services/blueprint.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { AssignmentService } from 'src/app/services/tasklist.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { SaveService } from 'src/app/services/save.service';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { LocalStorageManager } from 'src/app/utils/LocalStorageManager';
import { GroupsService } from 'src/app/services/groups.service';
import { ICurrentassignment } from 'src/app/domain/interfaces/icurrentassignment';
import { IEndPointGroup } from 'src/app/domain/interfaces/iendpointgroup';
import { GroupactionsService } from 'src/app/services/groupactions.service';
import { TagType } from 'src/app/modules/shared/domain/tag';
import { ClrLoadingState } from '@clr/angular';
import { GroupAssignmentItem } from 'src/app/domain/interfaces/group-assignment-item';
import { Action } from 'src/app/modules/shared/domain/action';
import { GroupAssignmentDetailDto } from 'src/app/domain/dtos/group-assignment-detail-dto';

@Component({
  selector: 'nd-group-assignment-details',
  templateUrl: './group-assignment-details.component.html',
  styleUrls: ['./group-assignment-details.component.scss'],
})
export class GroupAssignmentDetailsComponent implements OnInit {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;

  selectedInputValue: string = '';
  inputValue: string = '';
  SelectedActions: any[] = [];
  data: any;
  listofBlueprints: IBluePrint[] = [];
  selectedBlueprint;
  blueprintsInAssignment: any[] = [];
  openBlueprintModal = false;
  openActionSettingsModal = false;
  readyToDeploy = false;
  openForceModal = false;
  currentAssignment: ICurrentassignment;
  groupData: IEndPointGroup;
  uninstallSetting = false;
  versionFixedSetting = false;
  forceInstallSetting = false;
  selectedVersion;
  actionSettings;
  templateId = 0;
  selectables;
  actionTypes;
  listOfStatus;
  currAssignmentStatus;
  singleAction;
  multiAction;
  viewListOfActions;
  originalActions;

  isLoadingPage = true;
  groupAssignmentItems: GroupAssignmentItem[] = [];
  actions: Action[];
  selectedBlueprintsToAdd: IBluePrint[] = [];
  isAccepted = false;
  saveButtonState = ClrLoadingState.DEFAULT;

  constructor(
    private tSvc: ActionService,
    private aSvc: AssignmentService,
    private aASvc: AssignmentActionService,
    private bSvc: BlueprintService,
    private bASvc: BlueprintActionService,
    private gSvc: GroupsService,
    private gaSvc: GroupactionsService,
    public saveSvc: SaveService
  ) {}

  ngOnInit(): void {
    this.currentAssignment = LocalStorageManager.GetCurrAssignment();

    this.GetGroupData(this.currentAssignment);
    this.bSvc.GetBluePrint().subscribe((v) => {
      this.listofBlueprints = v;
    });
  }

  notify(text: string, status: string) {
    const data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  handleUnsaved() {
    if (!this.saveSvc.currentSave.unsavedChanges) {
      this.saveSvc.currentSave.unsavedChanges = true;
      this.saveSvc.currentSave.pageName = 'Assignment';
    }
  }

  handleSave() {
    this.saveSvc.currentSave.unsavedChanges = false;
    this.saveSvc.currentSave.pageName = '';
  }

  selectionChanged(blueprintSelection: IBluePrint[]) {
    this.selectedBlueprintsToAdd = blueprintSelection;
  }

  blueprintModalDone() {
    const validBlueprintsToAdd = this.selectedBlueprintsToAdd.filter(
      (bp) =>
        !this.groupAssignmentItems.find(
          (items) => items.blueprint?.id === bp.id
        )
    );

    this.groupAssignmentItems.push(
      ...validBlueprintsToAdd.map((bp) => ({
        order: -1,
        blueprint: bp,
      }))
    );

    if (validBlueprintsToAdd.length !== this.selectedBlueprintsToAdd.length) {
      this.notify(
        'Some or all of the blueprints were already added to this group.',
        'warning'
      );
    }

    this.openBlueprintModal = false;
    this.selectedBlueprintsToAdd = [];
    this.handleUnsaved();
  }

  saveGroupAssignment(): void {
    this.saveButtonState = ClrLoadingState.LOADING;

    var dtos: GroupAssignmentDetailDto[] = this.groupAssignmentItems.map(
      (item, index) => ({
        order: index,
        actionId: item.action?.id,
        blueprintId: item.blueprint?.id,
      })
    );

    this.gaSvc
      .updateGroupAssignment(
        dtos,
        this.currentAssignment.groupId,
        this.isAccepted
      )
      .subscribe({
        next: () => {
          this.saveButtonState = ClrLoadingState.SUCCESS;
          this.notify('Your changes have been saved!', 'success');
          this.handleSave();
        },
        error: () => {
          this.saveButtonState = ClrLoadingState.ERROR;
          this.notify(
            'An error occured trying to save your changes!',
            'danger'
          );
        },
      });
  }

  GetAssignmentStatus() {
    this.aSvc.GetAssignmentStatus().subscribe((v) => {
      this.listOfStatus = v;
    });
  }

  ToggleReadyToDeploy(readyToDeploy) {
    let assignmentStatusId;
    if (!readyToDeploy) {
      assignmentStatusId = 5;
    } else {
      assignmentStatusId = 1;
      this.openForceModal = true;
    }
    this.aSvc
      .UpdateAssignmentByID(
        this.data.id,
        this.data.endpointId,
        assignmentStatusId,
        ''
      )
      .subscribe((v) => {
        if (v.assignmentStatusId == 1) {
          this.notify('Your packages are ready to install.', 'success');
        } else {
          this.notify('Your Endpoint is now in Design mode.', 'info');
        }
        this.aSvc.GetAssignmentByID(this.templateId).subscribe((va) => {
          this.data = va;
        });
      });
  }

  UpdateAssignmentDropDown(assignmentStatusName) {
    this.UpdateAssignmentStatus(
      this.data.id,
      this.data.endpoint.id,
      assignmentStatusName
    );
  }

  UpdateAssignmentStatus(id, endpointId, assignmentStatusName) {
    const blueprintId = '';
    const assignmentStatusId = this.listOfStatus.filter(
      (v) => v.name == assignmentStatusName
    )[0].id;

    this.aSvc
      .UpdateAssignmentByID(id, endpointId, assignmentStatusId, blueprintId)
      .subscribe((v) => {
        if (blueprintId != '') {
        } else {
          this.aSvc.GetAssignmentByID(this.templateId).subscribe((va) => {
            this.data = va;
          });
        }
      });
  }

  selectItemDrop(event: CdkDragDrop<Action[] | GroupAssignmentItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data as GroupAssignmentItem[],
        event.container.data.indexOf(event.item.data),
        event.currentIndex
      );
    } else {
      this.groupAssignmentItems = [
        ...this.groupAssignmentItems.slice(0, event.currentIndex),
        {
          order: -1,
          action: event.item.data,
        },
        ...this.groupAssignmentItems.slice(event.currentIndex),
      ];
    }

    this.handleUnsaved();
  }

  removeSelectedItemDrop(event: CdkDragDrop<string[]>) {
    this.groupAssignmentItems.splice(event.previousIndex, 1);
    this.handleUnsaved();
  }

  GetGroupData(cA) {
    this.gSvc.GetSingleEndPointGroup(cA.groupId).subscribe((v) => {
      this.groupData = v;
      this.GetGroupActionsByGroupID(cA.groupId);
      this.getActionsForTag(
        v.endpointGroupTag.find((t) => t.tag.type === TagType.DeployStage).tag
          .id
      );
    });
  }

  onDetailOpen(e) {
    if (e == null) {
    } else {
      this.showTemplateDataListModal(e.id);
    }
  }

  showTemplateDataListModal(id) {
    this.bASvc.GetBlueprintActionsByBlueprint(id).subscribe((v) => {
      this.viewListOfActions = v;
    });
  }

  addBlueprintModal(): void {
    this.openBlueprintModal = true;
  }

  getAssignmentActionsByAssignmentId() {
    this.aASvc
      .GetAssignmentActionByAssignmentID(this.templateId)
      .subscribe((response) => {
        const data = response
          .map((element) => ({
            order: element.order,
            action: !element.blueprint ? element.action : null,
            blueprint: element.blueprint,
          }))
          .sort((item) => item.order);

        this.groupAssignmentItems = data;
      });
  }

  GetGroupActionsByGroupID(endpointGroupId: number) {
    this.gaSvc
      .GetEndpointGroupActionsByEndpointGroupId(endpointGroupId)
      .subscribe((response) => {
        const data = response
          .sort((item1, item2) => item1.order - item2.order)
          .filter((value, i, array) => {
            if (!value.blueprintId) return true;
            return (
              i === array.findIndex((v) => v.blueprintId === value.blueprintId)
            );
          })
          .map((element, index) => ({
            order: index,
            action: !element.blueprint ? element.action : null,
            blueprint: element.blueprint,
          }));

        this.groupAssignmentItems = data;
      });
  }

  getActionsForTag(tagId: number) {
    this.tSvc.GetActionsForTag(tagId).subscribe((actions) => {
      this.actions = actions;
      this.originalActions = _.cloneDeep(this.actions);
      this.isLoadingPage = false;
    });
  }

  addItem(item: Action) {
    this.groupAssignmentItems.push({
      order: -1,
      action: item,
    });

    this.notify(`${item.name} has been added!`, 'success');
  }

  removeItemFromIndex(index: number) {
    this.groupAssignmentItems.splice(index, 1);
    this.notify(`Item has been removed!`, 'info');
  }

  DeleteEverything() {
    this.groupAssignmentItems = [];

    this.notify('Every item has been removed!', 'info');
    this.handleUnsaved();
  }

  ClearAvailableFilterValue() {
    let input;
    this.inputValue = '';
    input = document.getElementById('availableActionInput');
    input.value = '';
    this.filterAvailableActions();
  }

  filterAvailableActions() {
    let input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('availableActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('AvailableActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }

  ClearSelectedFilterValue() {
    let input;
    this.selectedInputValue = '';
    input = document.getElementById('selectedActionInput');
    input.value = '';
    this.FilterSelectedActions();
  }

  FilterSelectedActions() {
    let input, filter, divParent, divChild, i, txtValue;
    input = document.getElementById('selectedActionInput');
    filter = input.value.toUpperCase();
    divParent = document.getElementById('selectedActionParent');
    divChild = divParent.getElementsByTagName('div');

    for (i = 0; i < divChild.length; i++) {
      txtValue = divChild[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        divChild[i].style.display = '';
      } else {
        divChild[i].style.display = 'none';
      }
    }
  }

  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i: number) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }
}
