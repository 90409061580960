<clr-wizard #wizard class="clr-wizard--inline" [clrWizardOpen]="true" [clrWizardClosable]="false">
    <clr-wizard-title>New Customer</clr-wizard-title>

    <clr-wizard-button (click)="handleCancel()">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>

    <clr-wizard-page (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageNavTitle>Welcome</ng-template>
        <ng-template clrPageTitle>New Customer</ng-template>
        <cb-welcome-step></cb-welcome-step>
        <ng-template clrPageButtons>
            <clr-wizard-button (click)="handleCancel()">Cancel</clr-wizard-button>
            <clr-wizard-button class="btn btn-primary" [type]="'welcome-next'">Continue</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!isSubscriptionInformationFormValid">
        <ng-template clrPageNavTitle>Subscription Information</ng-template>
        <ng-template clrPageTitle>Subscription Information</ng-template>
        <cb-subscription-information-step (subscriptionInformationFormEmitter)="setSubscriptionInformation($event)">
        </cb-subscription-information-step>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!isGeneralInformationFormValid">
        <ng-template clrPageNavTitle>General Information</ng-template>
        <ng-template clrPageTitle>General Information</ng-template>
        <cb-general-information-step (generalInformationFormEmitter)="setGeneralInformation($event)">
        </cb-general-information-step>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!isNugetInformationFormValid">
        <ng-template clrPageNavTitle>NuGet Information</ng-template>
        <ng-template clrPageTitle>NuGet Information</ng-template>
        <cb-nuget-information-step (nugetInformationFormEmitter)="setNugetInformation($event)">
        </cb-nuget-information-step>
    </clr-wizard-page>

    <clr-wizard-page (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageTitle>Summary</ng-template>
        <cb-summary-step [subscriptionInformation]="subscriptionInformation" [generalInformation]="generalInformation"
            [nugetInformation]="nugetInformation">
        </cb-summary-step>
        <ng-template clrPageButtons>
            <clr-wizard-button (click)="handleCancel()">Cancel</clr-wizard-button>
            <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
            <clr-wizard-button class="btn btn-primary" [type]="'create'">Create New Customer</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageTitle>Status</ng-template>
        <cb-creating-step [(creationInProgress)]="creationInProgress" [(creationSuccess)]="creationSuccess"
            [(creationError)]="creationError" [(errorMessage)]="errorMessage"
            [subscriptionInformation]="subscriptionInformation" [generalInformation]="generalInformation"
            [nugetInformation]="nugetInformation">
        </cb-creating-step>
        <ng-template clrPageButtons>
            <clr-wizard-button *ngIf="!creationInProgress" (click)="handleClose()">Close</clr-wizard-button>
        </ng-template>
    </clr-wizard-page>
</clr-wizard>