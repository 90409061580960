import { IEndPoint } from '../domain/interfaces/iendpoint';
import { ITag } from '../modules/shared/domain/tag';

const ProductionTagName = 'Production';
const AcceptanceTagName = 'Acceptance';
const TestingTagName = 'Testing';

export function getLowestTag(tags: ITag[]): ITag | null {
  if (tags.length < 1) return null;
  const testingTag = tags.filter((t) => t.name == TestingTagName)[0];
  if (testingTag) return testingTag;
  const acceptanceTag = tags.filter((t) => t.name == AcceptanceTagName)[0];
  if (acceptanceTag) return acceptanceTag;
  return tags.filter((t) => t.name == ProductionTagName)[0];
}

export function setEndpointLowestTag(endpoint: IEndPoint): IEndPoint {
  const tags = [
    endpoint.endpointTag[0].tag,
    ...endpoint.endpointGrouping.map(
      (g) => g.endpointGroup.endpointGroupTag[0].tag
    ),
  ];
  const lowestTag = getLowestTag(tags);
  endpoint.lowestTag = lowestTag;
  return endpoint;
}
