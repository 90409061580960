import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAssignment } from '../domain/interfaces/iassignment';
import { IAssignmentStatus } from '../domain/interfaces/istatus';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  GetAssignmentStatus() {
    return this.http.get<IAssignmentStatus>(`${this.URL}/assignmentstatus`);
  }

  GetAssignment() {
    return this.http.get<IAssignment>(`${this.URL}/assignment`);
  }

  GetAssignmentByID(id: number) {
    return this.http.get<IAssignment>(`${this.URL}/assignment/${id}`);
  }

  GetAssignmentByEndpointID(id: number) {
    return this.http.get<IAssignment>(`${this.URL}/assignment/endpoint/${id}`);
  }

  PutBlueprintActionsIntoAssignmentActions(assignmentId, blueprintId) {
    return this.http.put<IAssignment>(`${this.URL}/assignment/blueprint/${assignmentId}/${blueprintId}`, {});
  }

  PostSingleAssignment(endpointId, statusId) {
    var data = {
      "endpointId": parseInt(endpointId),
      "assignmentStatusId": parseInt(statusId)
    };
    return this.http.post<IAssignment>(`${this.URL}/assignment`, data);
  }

  UpdateAssignmentByID(id, endpointId, statusId, blueprintId) {
    var data = {
      "id": parseInt(id),
      "endpointId": parseInt(endpointId),
      "assignmentStatusId": parseInt(statusId),
      "blueprintId": parseInt(blueprintId)
    };
    return this.http.put<IAssignment>(`${this.URL}/assignment/${id}`, data);
  }

  AcceptInstall(id) {
    return this.http.put<IAssignment>(`${this.URL}/assignment/accept/${id}`, {});
  }

  RemoveAssignmentByID(id) {
    return this.http.delete<IAssignment>(`${this.URL}/assignment/${id}`);
  }
}