<div class="welcome">
    <h3>Welcome to our Application Gallery!</h3>
    <p>These pre-packaged applications are diligently managed and continuously maintained, providing an effortless
        software experience for you. Once you subscribe, each application becomes readily available for deployment
        across all your devices through NimDeploy.</p>
</div>

<div class="card gallery mt-4">
    <div class="title-and-search">
        <div class="d-flex gap-2">
            <span>Show:</span>
            <select [disabled]="isLoading" clrSelect name="show" (change)="onShowChanged($event.target.value)">
                <option value="all-applications">All applications</option>
                <option value="subscribed">Subscribed</option>
                <option value="not-subscribed">Not subscribed</option>
            </select>
            <p class="mt-0">Viewing {{filteredActions.length}} application{{filteredActions.length === 1 ? '' : 's'}}.
            </p>
        </div>
        <div class="search-wrapper">
            <cds-icon class="icon" shape="search"></cds-icon>
            <input clrInput placeholder="Search here" [ngModel]="searchQuery"
                (ngModelChange)="onQueryChanged($event)" />
        </div>
    </div>
    <div class="content">
        <p *ngIf="!isLoading && !filteredActions.length">Nothing here.</p>
        <div *ngIf="!isLoading" class="app-grid">
            <ps-application-card *ngFor="let action of filteredActions" [action]="action"
                (onSubscribed)="onSubscribed($event)"></ps-application-card>
        </div>
        <div *ngIf="isLoading" class="app-grid">
            <div *ngFor="let _ of [1, 2, 3, 4, 5, 6]" class="skeleton"></div>
        </div>
    </div>
</div>