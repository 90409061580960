import { Component, OnInit } from '@angular/core';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { TenantService } from 'src/app/modules/shared/services/tenant.service';

@Component({
  selector: 'msp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  currentDate: Date = new Date();
  totalCustomers: number;
  newCustomers: number;
  commonActionCount: number;
  totalActionCount: number;

  constructor(
    private tenantService: TenantService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.tenantService.getAllTenants().subscribe({
      next: (tenants) => {
        const fourteenDaysInMs = 12096e5;
        this.totalCustomers = tenants.length;
        this.newCustomers = tenants.filter(
          (t) => t.createdAt > new Date(Date.now() - fourteenDaysInMs)
        ).length;
        this.totalActionCount = tenants
          .map((t) => t.packageCount)
          .reduce((sum, next) => sum + next, 0);
      },
    });
    this.actionService.getActions().subscribe({
      next: (actions) => {
        this.commonActionCount = actions.length;
      },
    });
  }
}
