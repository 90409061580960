import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cb-general-information-step',
  templateUrl: './general-information-step.component.html',
  styleUrls: ['./general-information-step.component.scss'],
})
export class GeneralInformationStepComponent implements OnInit {
  @Output() generalInformationFormEmitter = new EventEmitter<FormGroup>();
  @Output() isFormValid = new EventEmitter<boolean>();

  generalInformationForm: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9- ]+$/),
    ]),
    domain: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9-]+$/),
    ]),
    chocolateyRepositoryUrl: new FormControl('', Validators.required),
    deploymentBranch: new FormControl('develop', Validators.required),
  });

  constructor() {}

  ngOnInit(): void {}

  validateForm(): void {
    this.isFormValid.emit(this.generalInformationForm.valid);
    this.generalInformationFormEmitter.emit(this.generalInformationForm);
  }
}
