import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { ActionType } from '../modules/shared/domain/action-type';

@Injectable({
  providedIn: 'root'
})
export class ActionTypeService {

  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  GetActionType() {
    return this.http.get<ActionType>(`${this.URL}/ActionType`);
  }

  GetActionTypeByID(id: number) {
    return this.http.get<ActionType>(`${this.URL}/ActionType/${id}`);
  }

  PostSingleActionType(name: string) {
    var data = { "name": name };
    return this.http.post<ActionType>(`${this.URL}/ActionType`, data);
  }

  UpdateSingleActionType(id, name: string) {
    var data = { "id": parseInt(id), "name": name };
    return this.http.put<ActionType>(`${this.URL}/ActionType/${id}`, data);
  }

  RemoveSingleActionTypeByID(id) {
    return this.http.delete<ActionType>(`${this.URL}/ActionType/${id}`);
  }
}
