<app-notify-me #cmp></app-notify-me>
<div>
    <div class="mt-2 small-title">Endpoint Schedule</div>
    <div class="card schedule-card mb-2 mt-1" [class.schedule-card-picked]="selectedGroupId == '-1'">
        <div class="card-body">
            <input type="radio" name="selected-schedule" value="-1" [(ngModel)]="selectedGroupId"
                (click)="onChooseSchedule($event.target.value)" clrRadio />
            <h4 class="m-0 mt-2 text-dark">Endpoint Schedule</h4>
            <app-cron-edit *ngIf="endpointScheduleEnabled" (cronChanged)="onCronChanged($event)"
                [cron]="endpoint.updateCron"></app-cron-edit>
            <p *ngIf="!endpointScheduleEnabled" class="card-text text-muted">Set a custom update schedule for this
                endpoint.</p>
        </div>
    </div>
</div>
<ng-container *ngIf="(endpoint.endpointGrouping | filter: filterGroupingsThatHaveSchedules) as filteredGroupings">
    <ng-container *ngIf="filteredGroupings.length > 0">
        <div class="mb-3">
            <div class="mt-4 small-title">Other available schedules</div>
            <div class="row">
                <div *ngFor="let grouping of filteredGroupings" class="col-xl-4">
                    <div class="card schedule-card mt-1"
                        [class.schedule-card-picked]="selectedGroupId == grouping.endpointGroup.id">
                        <div class="card-body">
                            <input type="radio" clrRadio name="selected-schedule" [(ngModel)]="selectedGroupId"
                                value="{{grouping.endpointGroup.id}}" (click)="onChooseSchedule(grouping.endpointGroup.id)" />
                            <h4 class="m-0 mt-2 text-dark">{{grouping.endpointGroup.name | truncate: 20}}</h4>
                            <h6 class="card-subtitle mb-2 mt-0 text-muted">Group schedule</h6>
                            <h5>Update {{grouping.endpointGroup.updateCron | humanizeCron}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>