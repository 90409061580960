import { IEndPoint } from "../domain/interfaces/iendpoint";
import { ICurrentassignment } from "../domain/interfaces/icurrentassignment";

export class LocalStorageManager {
    private static LastUsedEndPoints: IEndPoint[] = [];
    private static lastUpdated: number = 0;
    private static currAssignmentData: ICurrentassignment;



    public static AddLastUsedEndPoints(EndPoint: IEndPoint) {
        var index = this.LastUsedEndPoints.findIndex(x => x.name == EndPoint.name);

        index === -1 ? this.LastUsedEndPoints.push(EndPoint) : console.log("object already exists")

        localStorage.setItem('LastUsedEndPoints', JSON.stringify(this.LastUsedEndPoints));
    }

    public static GetLastUsedEndPoints() {

        var tempuid = JSON.parse(localStorage.getItem('LastUsedEndPoints'));

        if (tempuid == null) {
            this.LastUsedEndPoints = [];
        } else {
            this.LastUsedEndPoints = tempuid;
        }
        return this.LastUsedEndPoints;
    }
    public static AddCurrAssignment(cA: ICurrentassignment) {
        localStorage.setItem('CurrAssignment', JSON.stringify(cA));
    }

    public static GetCurrAssignment() {
        var currAs = JSON.parse(localStorage.getItem('CurrAssignment'));
        if (currAs == null) {
            this.currAssignmentData = {};
        } else {
            this.currAssignmentData = currAs;
        }
        return this.currAssignmentData;
    }

    public static SetLastUpdated(lastUpdated: number) {

        if (this.lastUpdated != lastUpdated) {
            localStorage.setItem('LastUpdated', lastUpdated.toString());
        }
    }
    public static GetLastUpdated() {
        var tempvar = Number(localStorage.getItem('LastUpdated'));

        return tempvar;
    }

    public static SetLastUsedTheme(lastUsedTheme: string) {

        localStorage.setItem('LastUsedTheme', lastUsedTheme);

    }
    public static GetLastUsedTheme() {
        var tempvar = localStorage.getItem('LastUsedTheme');
        return tempvar;
    }


    public static ResetEverything() {
        this.LastUsedEndPoints = [];
        this.currAssignmentData = {}
        localStorage.setItem('LastUsedEndPoints', JSON.stringify(this.LastUsedEndPoints));
        localStorage.setItem('CurrAssignment', JSON.stringify(this.currAssignmentData));
    }

}