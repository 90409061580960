import { Element, ElementCompact } from 'xml-js';

export function updateXmlNuspecMedatadata(
  xmlRoot: Element | ElementCompact,
  name: string,
  text: string
): void {
  const metadataRoot: Element = xmlRoot.elements[0].elements[0];
  const metadataElements: Element[] = metadataRoot.elements;
  const metadataForUpdate = metadataElements.filter((e) => e.name === name)[0];
  const textElement = { type: 'text', text: text };

  if (metadataForUpdate) {
    metadataForUpdate.elements = [textElement];
  } else {
    metadataElements.push({
      name: name,
      type: 'element',
      elements: [textElement],
    });
  }
}