<div class="card table-container-card">
  <div class="table-heading">
    <div class="table-title">
      <img
        style='filter: brightness(0) saturate(100%) invert(13%) sepia(74%) saturate(3041%) hue-rotate(232deg) brightness(77%) contrast(117%);'
        src="../../../assets/icons/tag.svg" />
      <h3>Tags</h3>
    </div>
    <div class="btn-group btn-icon">
      <button #tooltip="matTooltip" matTooltip="Add Tag" [matTooltipPosition]="'above'" matTooltipHideDelay="100"
        type="button" class="btn btn-icon btn-success btn-md" title="Add new Tag" (click)="showModal()">
        <img src="../../../assets/icons/plus.svg"/>
      </button>
      <button [disabled]="selected.length!=1" class="action-item" class="btn btn-icon btn-primary  btn-md"
        (click)="showRenameTagModal(selected)" #tooltip="matTooltip" matTooltip="Rename Tag"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../assets/icons/edit.svg" />
      </button>
      <button [disabled]="selected.length==0" class="action-item" class="btn btn-icon btn-danger  btn-md"
        (click)="openDeleteModal = true" #tooltip="matTooltip" matTooltip="Delete Tag" [matTooltipPosition]="'above'"
        matTooltipHideDelay="100">
        <img src="../../../assets/icons/trash.svg"/>
      </button>
    </div>
  </div>

  <clr-datagrid class="datagrid-compact" [clrDgSelected]="selected" (clrDgSelectedChange)="selectionChanged($event)"
    [clrDgLoading]="loading" id="my-datagrid" style="margin-top: 0px !important; ">
    <!-- <clr-dg-column>ID</clr-dg-column> -->
    <clr-dg-column [clrDgField]="'name'" [style.width.px]="250">Tag</clr-dg-column>
    <clr-dg-column>Description</clr-dg-column>
    <!-- <clr-dg-column>Actions</clr-dg-column> -->
    <!-- <clr-dg-column>Favorite color</clr-dg-column> -->
    <clr-dg-row *clrDgItems="let data of listOfDisplayData" [clrDgItem]="data">
      <!-- style="cursor: pointer;" (click)="RouteTemplateDetails(data.id)" -->
      <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{data.name}}</clr-dg-cell>
      <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{data.description}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[10,15,20,30,45,60,75,90,100]">Tags per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} tags
      </clr-dg-pagination>
    </clr-dg-footer>

    <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">

      <clr-dg-detail>
        <clr-dg-detail-header>
          <div class="btn-group btn-icon">
            <!-- <button (click)="RouteTemplateDetails(detail.id)" type="button" class="btn btn-icon btn-success btn-md"
              #tooltip="matTooltip" matTooltip="Edit Tag Actions" [matTooltipPosition]="'above'"
              matTooltipHideDelay="100">
              <clr-icon shape="pencil"></clr-icon>
            </button> -->
          </div>
          {{detail.name}}
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-datagrid class="datagrid-compact">
            <clr-dg-column>Package</clr-dg-column>
            <clr-dg-column [style.width.px]="50">Type</clr-dg-column>

            <clr-dg-row *clrDgItems="let tag of viewListOfActions" [clrDgItem]="tag.action">
              <clr-dg-cell>{{tag.action.name}}</clr-dg-cell>
              <clr-dg-cell *ngIf="tag.isInstall==true">
                <cds-icon shape="install" status="success" size="md" solid></cds-icon>
              </clr-dg-cell>
              <clr-dg-cell *ngIf="tag.isInstall==false && 2>tag.action.actionType.id">
                <cds-icon shape="uninstall" status="danger" size="md" solid></cds-icon>
              </clr-dg-cell>
              <clr-dg-cell *ngIf="tag.action.actionType.name=='Command Prompt'">Command Prompt</clr-dg-cell>
              <clr-dg-cell *ngIf="tag.action.actionType.name=='Powershell'">Powershell</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination #paginationBP [clrDgPageSize]="100">
                <clr-dg-page-size [clrPageSizeOptions]="[10,15,30,45,60,75,90,100]">Packages per page</clr-dg-page-size>
                {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}} Packages
              </clr-dg-pagination>

            </clr-dg-footer>
          </clr-datagrid>

        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>


<clr-modal [(clrModalOpen)]="addNewTagVisible">
  <h3 class="modal-title">New Tag</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Tag Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="newTagName" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelNewTag()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkNewTag()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="renameTagVisible">
  <h3 class="modal-title">Rename tag</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Tag Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="renameTagName" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelRenameTag()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkRenameTag()">Ok</button>
  </div>
</clr-modal>

<!-- <clr-modal [(clrModalOpen)]="viewTemplateDataVisible">
  <h3 class="modal-title">View Tag Actions</h3>
  <div class="modal-body">
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>ID</clr-dg-column>
      <clr-dg-column>Name</clr-dg-column>

      <clr-dg-row *clrDgItems="let tag of viewListOfActions" [clrDgItem]="tag.action">
        <clr-dg-cell>{{tag.action.id}}</clr-dg-cell>
        <clr-dg-cell>{{tag.action.name}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
          <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Actions per page</clr-dg-page-size>
          {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of {{paginationBP.totalItems}} Actions
        </clr-dg-pagination>

      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelTemplateActions()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkTemplateActions()">Ok</button>
  </div>
</clr-modal> -->


<clr-modal [(clrModalOpen)]="openDeleteModal">
  <h3 class="modal-title">Delete Tags?</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">No</button>
    <button type="button" class="btn btn-primary" (click)="DeleteTag(selected)">Yes</button>
  </div>
</clr-modal>