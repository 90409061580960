<div class="download-container">
    <div class="download-msi-card-title">
        <h6>NimDeploy Installer</h6>
    </div>
    <div class="download-msi-card-body">
        <p>Get the latest version of the NimDeploy installer.</p>
    </div>
    <div class="download-msi-card-footer">
        <a [href]="downloadUrl" download>
            <button class="btn btn-sm btn-primary download-button">
                <span *ngIf="!isLoading"><cds-icon shape="download"></cds-icon> Download</span>
                <span *ngIf="isLoading" class="spinner spinner-inline"></span>
            </button>
        </a>
    </div>
</div>