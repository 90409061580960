import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommandSpecification } from '../../models/command-specification';

@Component({
  selector: 'pb-command-spec-item',
  templateUrl: './command-spec-item.component.html',
  styleUrls: ['./command-spec-item.component.scss'],
})
export class CommandSpecItemComponent {
  @Input() commandSpecification: CommandSpecification;
  @Output() onAdd = new EventEmitter<CommandSpecification>();

  constructor() {}

  itemOnClicked(): void {
    this.onAdd.emit(this.commandSpecification);
  }
}
