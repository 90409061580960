import { Component, Input } from '@angular/core';

@Component({
  selector: 'pb-building-step',
  templateUrl: './building-step.component.html',
  styleUrls: ['./building-step.component.scss'],
})
export class BuildingStepComponent {
  @Input() imageUri: string;
  @Input() packageName: string;
  @Input() message: string;
  @Input() isDone = false;
  @Input() isError = false;

  constructor() {}
}
