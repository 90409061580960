<div class="card rounded-0">
    <clr-progress-bar *ngIf="isLoadingActions" clrValue="75" clrFade="true" clrLoop></clr-progress-bar>
    <div class="row g-0">
        <div class="col-md-3">
            <nd-log-list [listItems]="listItems" (onItemSelected)="onItemSelected($event)"></nd-log-list>
        </div>
        <div class="col-md-9">
            <nd-terminal [text]="logText"></nd-terminal>
        </div>
    </div>
</div>
