import { Injectable } from '@angular/core';
import { CommandSpecification } from '../models/command-specification';
import { Observable, of } from 'rxjs';
import { commands } from '../commands';

@Injectable({
  providedIn: 'root',
})
export class CommandService {
  constructor() {}

  getCommandSpecifications(): Observable<CommandSpecification[]> {
    return of(commands);
  }
}
