import { Component, Input } from '@angular/core';
import { LogListItem } from 'src/app/domain/interfaces/log-list-item';
import { BlobService } from 'src/app/modules/package-builder/services/blob.service';

@Component({
  selector: 'nd-tabbed-terminal',
  templateUrl: './tabbed-terminal.component.html',
  styleUrls: ['./tabbed-terminal.component.scss'],
})
export class TabbedTerminalComponent {
  @Input() endpointId: number;
  selectedTab?: LogListItem;
  logListItems: LogListItem[] = [
    {
      name: 'choco.log',
    },
    {
      name: 'choco.summary.log',
    },
    {
      name: 'NimDeploy.log',
    },
  ];
  selectedContent?: string;

  constructor(private blobService: BlobService) {}

  selectTab(itemName: string) {
    if (itemName === this.selectedTab?.name) return;

    this.selectedTab = this.logListItems.find((tab) => tab.name === itemName);

    this.selectedContent = 'Loading...';
    this.blobService.getLog(this.endpointId, itemName).subscribe({
      next: (logs) => (this.selectedContent = logs),
      error: (_) => (this.selectedContent = '[!] Failed to read logs.'),
    });
  }
}
