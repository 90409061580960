<div class="log-list">
    <div *ngFor="let tab of listItems" (click)="selectTab(tab)">
        <div class="log-card" [class.selected-card]="selectedItem === tab">
            <div class="img-square-wrapper">
                <img [src]="tab.icon ? tab.icon : '../../../../assets/nimdeploy-logo-shadow.svg'">
            </div>
            <div class="card-body" id="cardBody">
                <p class="card-text">{{tab.name}}</p>
            </div>
        </div>
    </div>
</div>