import { Component, OnInit } from '@angular/core';
import { PackageCleanupConfiguration } from '../../models/package-cleanup-configuration';
import { PackageCleanupService } from '../../services/package-cleanup.service';
import { VersionCleanupStatusDto } from '../../models/version-cleanup-status-dto';
import { technologyCollectionAliases } from '@cds/core/icon';

const promise = async () => {
  await setTimeout(() => {}, 1);

  return promise();
};

@Component({
  selector: 'nd-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.scss'],
})
export class ConfigureComponent implements OnInit {
  isLoading = true;
  isLoadingPreview = false;
  versionCleanupStatuses: VersionCleanupStatusDto[] = [];

  configuration: PackageCleanupConfiguration = {
    recycleBinRetentionPeriodInDays: 30,
    versionCountToKeep: 3,
    isAutomaticCleanupOn: false,
  };

  constructor(private configurationService: PackageCleanupService) {}

  ngOnInit(): void {
    this.configurationService.getPackageCleanupConfiguration().subscribe({
      next: (value) => (this.configuration = value),
      error: (err) => console.error(err),
      complete: () => (this.isLoading = false),
    });
  }

  saveChanges(): void {
    if (this.configuration.versionCountToKeep > 10)
      this.configuration.versionCountToKeep = 10;

    if (this.configuration.versionCountToKeep < 1)
      this.configuration.versionCountToKeep = 1;

    this.isLoading = true;
    this.configurationService
      .updatePackageCleanupConfiguration(this.configuration)
      .subscribe({
        error: (err) => console.error(err),
        complete: () => (this.isLoading = false),
      });
  }

  getWhatif(): void {
    this.isLoading = this.isLoadingPreview = true;

    this.configurationService
      .getWhatIfConfiguration(this.configuration)
      .subscribe({
        next: (val) => (this.versionCleanupStatuses = val),
        complete: () => (this.isLoading = this.isLoadingPreview = false),
        error: (error) => {
          this.isLoading = this.isLoadingPreview = false;
          console.error(error);
          this.versionCleanupStatuses = [];
        },
      });
  }
}
