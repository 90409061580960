import { Component, OnInit } from '@angular/core';
import { INotify } from '../../domain/interfaces/notify';

@Component({
  selector: 'app-notify-me',
  templateUrl: './notify-me.component.html',
  styleUrls: ['./notify-me.component.scss'],
})
export class NotifyMeComponent implements OnInit {
  defaultValues: INotify = {
    defaultNotificationText: 'This is a placeholder.',
    standardStatus: 'success',
    notificationShow: false,
  };

  constructor() { }

  ngOnInit(): void { }

  timeOut: NodeJS.Timeout;

  autoDismiss() {
    if (this.timeOut != null) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.defaultValues.notificationShow = false;
    }, 5000);
  }

  showNotification(data: INotify) {
    this.defaultValues = data;
    this.autoDismiss();
  }

  showSuccess(message: string): void {
    this.showNotification({
      defaultNotificationText: message,
      standardStatus: 'success',
      notificationShow: true,
    });
  }

  showInfo(message: string): void {
    this.showNotification({
      defaultNotificationText: message,
      standardStatus: 'info',
      notificationShow: true,
    });
  }

  showError(message: string): void {
    this.showNotification({
      defaultNotificationText: message,
      standardStatus: 'danger',
      notificationShow: true,
    });
  }

  dismissNotification() {
    this.defaultValues.notificationShow = false;
  }
}
