import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cron-edit',
  templateUrl: './cron-edit.component.html',
  styleUrls: ['./cron-edit.component.scss']
})
export class CronEditComponent implements OnInit {
  @Input() cron: string;
  @Output() cronChanged: EventEmitter<string> = new EventEmitter<string>();

  pickedDays: string[] = [];
  pickedTime = null;

  days = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
  ];
  
  constructor() { }

  get stringRepresentation() {
    const days = this.pickedDays.length === 0 
      ? "day" 
      : this.pickedDays.sort((a, b) => this.days.indexOf(a) - this.days.indexOf(b)).join(', ');
    return `every ${days} at ${this.pickedTime}`;
  }

  ngOnInit(): void {
    this.parseCron(this.cron);
  }
  zeroPad (num: number, places: number) { 
    return String(num).padStart(places, '0') 
  };

  parseCron(cron: string) {
    const cronArray = cron.split(' ');
    const day = cronArray[5];
    this.pickedDays = [];
    if (day.includes(',')) {
      this.pickedDays = day.split(',')
        .map(x => x.toLowerCase())
        .map(x => x.charAt(0).toUpperCase() + x.slice(1));
    } else if (day !== '*') {
      this.pickedDays = [day];
    }
    const time = `${this.zeroPad(parseInt(cronArray[2]), 2)}:${this.zeroPad(parseInt(cronArray[1]), 2)}`;
    this.pickedTime = time;
  }

  constructCronAndEmit() {
    const days = this.pickedDays.length > 0 
      ? this.pickedDays.join(',') 
      : "*";

    const splitTime = this.pickedTime.split(':');
    const minutes = splitTime[1];
    const hours = splitTime[0];
  
    // s m h d m w y
    // 0 | 0 - seconds
    // 1 | minutes - minutes
    // 2 | hours - hours
    // 3 | ? - day of month
    // 4 | * - month
    // 5 | days - day of week
    const cron = `0 ${minutes} ${hours} ? * ${days}`;
    this.cronChanged.emit(cron);
  }

  onTimeChanged() { 
    this.constructCronAndEmit();
  }

  dayChecked(e) {
    if (e.currentTarget.checked) {
      this.pickedDays.push(e.currentTarget.value);
    } else {
      this.pickedDays.splice(this.pickedDays.indexOf(e.currentTarget.value), 1);
    }
    this.constructCronAndEmit();
  }
}
