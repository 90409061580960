<form clrForm [formGroup]="nugetInformationForm" (change)="validateForm()" autocomplete="off">
    <clr-input-container>
        <label for="nugetRepositoryUrl" class="required-label">NuGet Repository</label>
        <input type="text" formControlName="nugetRepositoryUrl" placeholder="NuGet Repository URL" clrInput />
        <clr-control-error>Enter the customer NuGet repository URL.</clr-control-error>
    </clr-input-container>
    <p class="mt-4 mb-3 fs-6">Read-Write Credentials</p>
    <clr-input-container>
        <label for="nugetRwUsername" class="required-label">NuGet Username</label>
        <input type="text" formControlName="nugetRwUsername" placeholder="NuGet Username" clrInput />
        <clr-control-error>Enter the customer RW NuGet username.</clr-control-error>
    </clr-input-container>
    <clr-password-container>
        <label for="nugetRwPassword" class="required-label">NuGet Password</label>
        <input clrPassword placeholder="NuGet Password" formControlName="nugetRwPassword" />
        <clr-control-error>Enter the customer RW NuGet password.</clr-control-error>
    </clr-password-container>
    <p class="mt-4 mb-3 fs-6">Read-Only Credentials</p>
    <clr-input-container>
        <label for="nugetRoUsername" class="required-label">NuGet Username</label>
        <input type="text" formControlName="nugetRoUsername" placeholder="NuGet Username" clrInput />
        <clr-control-error>Enter the customer RO NuGet username.</clr-control-error>
    </clr-input-container>
    <clr-password-container>
        <label for="nugetRoPassword" class="required-label">NuGet Password</label>
        <input clrPassword placeholder="NuGet Password" formControlName="nugetRoPassword" />
        <clr-control-error>Enter the customer RO NuGet password.</clr-control-error>
    </clr-password-container>
</form>