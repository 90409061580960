<div class="card">
  <div class="card-block">
    <div>
      <cds-icon [shape]="icon" size="lg" [badge]="badge" [style.--badge-color]="badgeColor"></cds-icon>
    </div>
    <div>
      <div class="card-text">{{ subheader }}</div>
      <h3 class="card-title">{{ header }}</h3>
    </div>
  </div>
</div>