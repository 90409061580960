<div (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)">
    <div id="fm-drop-zone">
        <cds-icon shape="upload" size="lg"></cds-icon>
        <h4>
            Drop your files here, or <span id="browse-btn" (click)="browseDirectory()">Browse</span>
        </h4>
    </div>
    <div id="fm-files">
        <div class="p-0">
            <button [disabled]="stack.size() <= 1" (click)="goBack()" class="btn btn-link p-0">
                <cds-icon *ngIf="stack.size() > 1" shape="angle" direction="left"></cds-icon>
                <cds-icon id="disabled-icon" *ngIf="stack.size() <= 1" shape="angle" direction="left"></cds-icon>
                BACK <span *ngIf="stack.size() > 1">TO {{previousDir.name}}</span>
            </button>
        </div>
        <div class="breadcrumbs">
            <span *ngFor="let crumb of breadcrumbs; let i = index">
                <span (click)="goTo(i)" class="crumb">{{crumb}}</span>
                <span>/</span>
            </span>
        </div>
        <div class="file-entry" *ngFor="let childNode of stack.peek().children">
            <div *ngIf="childNode.children">
                <cds-icon shape="folder"></cds-icon>
            </div>
            <div *ngIf="!childNode.children">
                <img src="../../../../assets/nimdeploy-logo-shadow.svg" />
            </div>
            <div class="d-flex justify-content-between w-100 px-3">
                <div>
                    <span *ngIf="childNode.children" (click)="listDirectoryContents(childNode)"
                        class="brand-font-color cursor-pointer">
                        {{childNode.name}}
                    </span>
                    <span *ngIf="!childNode.children" class="brand-font-color">{{childNode.name}}</span>
                </div>
                <div><cds-icon class="trash-icon" shape="trash" (click)="softDeleteHandle(childNode)"></cds-icon></div>
            </div>
        </div>
    </div>
</div>