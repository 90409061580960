import { Direct } from 'protractor/built/driverProviders';
import { FilePathHandle } from './file-system-path-handle';

interface BaseFileSystemNode {
  isPruned?: boolean;
}

export interface FileNode extends BaseFileSystemNode {
  name: string;
  data: FilePathHandle;
}

export interface DirectoryNode extends BaseFileSystemNode {
  name: string;
  children: (FileNode | DirectoryNode)[];
}

export function isDirectoryNode(
  node: FileNode | DirectoryNode
): node is DirectoryNode {
  return (node as DirectoryNode).children !== undefined;
}
