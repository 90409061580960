import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKeyToStringValue',
})
export class EnumKeyToStringValue implements PipeTransform {
  transform(value: number, enumeration: any): any {
    return Object.values(enumeration)[value];
  }
}
