import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { Application } from 'src/app/domain/interfaces/application';
import { ApplicationEndpoint } from 'src/app/domain/interfaces/application-endpoint';
import { ApplicationVersion } from 'src/app/domain/interfaces/application-version';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent {
  applications$: Observable<Application[]> | undefined =
    this.applicationService.applications$;
  applicationEndpoints: ApplicationEndpoint[] = [];
  filteredApplicationEndpoints: ApplicationEndpoint[] = [];

  selectedApplications: Application[] = [];
  isSelected: boolean = false;

  selectedApplicationVersions: ApplicationVersion[] = [];
  selectedApplicationEndpoints: ApplicationEndpoint[] = [];

  detailState: Application;

  constructor(private applicationService: ApplicationService) {}

  // ### Handlers ###
  handleSelectionChange(elements: Application[]) {
    this.selectedApplications = elements;
    this.isSelected = elements.length > 0 ? true : false;
  }

  handleVersionSelectionChange(elements: ApplicationVersion[]) {
    this.selectedApplicationVersions = elements;
  }

  handleApplicationEndpointSelectionChange(elements: ApplicationEndpoint[]) {
    this.selectedApplicationEndpoints = elements;
  }

  handleVersionFilterChange(selectedAppVersion?: ApplicationVersion) {
    if (!!selectedAppVersion) {
      this.filteredApplicationEndpoints = this.applicationEndpoints;
      return;
    }

    this.filteredApplicationEndpoints = this.applicationEndpoints.filter(
      (appEndpoint) =>
        appEndpoint.version.version === selectedAppVersion.version
    );
  }

  onDetailOpen(event) {
    if (!!event) {
      this.fetchApplicationEndpoints(event.id);
    }
  }

  // ### Service calls ###
  fetchApplicationEndpoints(applicationId) {
    this.applicationService
      .applicationEndpoints$(applicationId)
      .subscribe((appEndpoints) => {
        this.applicationEndpoints = appEndpoints;
        this.filteredApplicationEndpoints = appEndpoints;
      });
  }

  closeDetail() {
    this.detailState = null;
  }
}
