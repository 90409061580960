import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeCron',
})
export class HumanizeCronPipe implements PipeTransform {
  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  transform(value: string): string {
    return this.parseCronTime(value);
  }

  parseCronTime(cron: string) {
    const cronArray = cron.split(' ');
    const day = cronArray[5];

    let pickedDays = [];
    if (day.includes(',')) {
      pickedDays = day
        .split(',')
        .map((x) => x.toLowerCase())
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1));
    } else if (day !== '*') {
      pickedDays = [day];
    }

    const time = `${this.zeroPad(parseInt(cronArray[2]), 2)}:${this.zeroPad(
      parseInt(cronArray[1]),
      2
    )}`;

    const days =
      pickedDays.length === 0
        ? 'day'
        : pickedDays
            .sort((a, b) => this.days.indexOf(a) - this.days.indexOf(b))
            .join(', ');
            
    return `every ${days} at ${time}`;
  }

  zeroPad(num: number, places: number) {
    return String(num).padStart(places, '0');
  }
}
