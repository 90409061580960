import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseSplitter',
})
export class CamelCaseSplitterPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .map((w) => w[0].toUpperCase() + w.substring(1))
      .join(' ');
  }
}
