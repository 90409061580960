import { Component, OnInit } from '@angular/core';
import { Action } from 'src/app/modules/shared/domain/action';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';
import { Stage } from 'src/app/modules/shared/domain/enums/stage';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';

@Component({
  selector: 'msp-packages-page',
  templateUrl: './packages-page.component.html',
  styleUrls: ['./packages-page.component.scss'],
})
export class PackagesPageComponent implements OnInit {
  actions: Action[] = [];
  detailState: Action;
  stageEnum = Stage;
  isLoading = false;
  versionsBeingDeleted: ActionVersion[] = [];

  constructor(
    private actionService: ActionService,
    private actionVersionService: ActionversionService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.actionService.getActionsWithoutRecycledVersions().subscribe({
      next: (data) => {
        this.actions = data;
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => (this.isLoading = false),
    });
  }

  onDetailOpen(_action: Action): void {
    this.versionsBeingDeleted = [];
  }

  openDetail(element: any): void {
    const detailButton: HTMLButtonElement = element.detailButton.nativeElement;
    detailButton.click();
  }

  closeDetail(): void {
    this.detailState = null;
  }

  onVersionStageUpdate(stage: Stage, versionId: number): void {
    this.actionVersionService
      .setActionVersionStage(versionId, stage)
      .subscribe();
  }

  moveActionVersionToRecycleBin(versionId: number): void {
    const version = this.detailState.actionVersion.find(
      (v) => v.id === versionId
    );
    this.versionsBeingDeleted = [...this.versionsBeingDeleted, version];

    this.actionVersionService
      .moveActionVersionToRecycleBin(versionId)
      .subscribe({
        next: () => {
          this.detailState = {
            ...this.detailState,
            actionVersion: this.detailState.actionVersion.filter(
              (v) => v.id !== versionId
            ),
          };

          if (!this.detailState.actionVersion.length) {
            this.actions = this.actions.filter(
              (a) => a.id !== this.detailState.id
            );
            this.detailState = null;
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () =>
          (this.versionsBeingDeleted = this.versionsBeingDeleted.filter(
            (v) => v.id !== versionId
          )),
      });
  }

  isBeingDeleted(versionId: number): boolean {
    return !!this.versionsBeingDeleted.find((v) => v.id === versionId);
  }

  updateAutoLifecycleConfigurationForAction(detail: Action): void {
    this.actionService
      .setAutoLifecycleConfigurationForAction(detail.id, {
        hasOverridenGlobalDefaults: detail.hasOverridenGlobalAutoLifecycle,
        flowToAcceptanceInHours: detail.flowToAcceptanceInHours,
        flowToProductionInHours: detail.flowToProductionInHours,
      })
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }

  updateVersionAutoLifecycle(
    version: ActionVersion,
    isAutoLifecycleOn: boolean
  ): void {
    version.isPreventingAutomaticLifecycleUpdates = !isAutoLifecycleOn;
    this.actionVersionService
      .setAutoLifecycleForVersion(isAutoLifecycleOn, version.id)
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }
}
