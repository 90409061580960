import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITag } from '../modules/shared/domain/tag';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class TagService {

  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  GetTags() {
    return this.http.get<ITag[]>(`${this.URL}/Tag/`);
  }

  GetSingleTag(id: number) {
    return this.http.get<ITag>(`${this.URL}/Tag/${id}`);
  }

  UpdateSingleTag(id, name) {
    var data = {
      "id": parseInt(id),
      "name": name
    };
    return this.http.put<ITag>(`${this.URL}/Tag/${id}`, data);
  }

  PostSingleTag(name: string) {
    var data = { "name": name };
    return this.http.post<ITag>(`${this.URL}/Tag/`, data);
  }

  RemoveSingleTagByID(id) {
    return this.http.delete<ITag>(`${this.URL}/Tag/${id}`);
  }

}
