import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITag } from 'src/app/modules/shared/domain/tag';

@Component({
  selector: 'nd-add-tags-modal',
  templateUrl: './nd-add-tags-modal.component.html',
  styleUrls: ['./nd-add-tags-modal.component.scss'],
})
export class NdAddTagsModalComponent {
  @Input() title: string;
  @Input() systemTags: ITag[] = [];
  @Input() userTags: ITag[] = [];
  @Input() modalIsVisible: boolean = false;
  @Input() multiSelectSystemTags: boolean = false;
  @Input() previouslySelectedTags: any[] = [];
  @Output() selectedTagsEmitter = new EventEmitter<ITag[]>();
  @Output() closeAddTagsModalEmitter = new EventEmitter<boolean>();
  selectedSystemTags: ITag[] = [];
  selectedUserTags: ITag[] = [];

  handleSystemTagsChange(elements: ITag[]) {
    this.selectedSystemTags = elements;
  }

  handleUserTagsChange(elements: ITag[]) {
    this.selectedUserTags = elements;
  }

  handleModalClose(event: boolean) {
    this.closeAddTagsModalEmitter.emit(event);
  }

  handleDoneButtonClick() {
    const mergedTagsArray: ITag[] = this.selectedUserTags.concat(this.selectedSystemTags);
    this.selectedTagsEmitter.emit(mergedTagsArray);
    this.selectedSystemTags = [];
    this.selectedUserTags = [];
  }
}
