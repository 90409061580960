<div class="login-wrapper">
  <form class="login">
    <section class="logo">
      <img src="/assets/logo-solid.png" alt="NimDeploy" />
    </section>
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      NimDeploy
    </section>
    <div class="login-group">
      <button [disabled]="loading" class="btn btn-primary azure-btn rounded-10px"
        (click)="onLogInWithAzure()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <img style="height: 20px; margin-top: -2px" *ngIf="!loading" src="/assets/icons/azure.svg" alt="Azure" />
        Log in with Azure
      </button>
    </div>
  </form>
</div>