import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  addNewTagVisible = false;
  // viewTemplateDataVisible = false;
  renameTagVisible = false;
  openDeleteModal = false;


  searchValue = '';
  visible = false;
  tags: any = "";
  listOfDisplayData = [...this.tags];
  newTagName: string = "";
  renameTagName: string = "";
  sub: any;

  viewListOfActions: any = "";
  currentTagViewed: number = 0;

  openModal = false;
  selected: any[] = [];



  constructor(private router: Router,
    private tSvc: TagService
  ) {
    this.GetTags();
  }

  ngOnInit(): void {
  }

  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click()
  }

  loading = true;
  GetTags() {
    this.tSvc.GetTags().subscribe(v => {
      this.tags = v;
      this.listOfDisplayData = [...this.tags]
      this.loading = false;
    })
  }

  PostNewTag(name) {
    this.tSvc.PostSingleTag(name).subscribe(v => {
      this.GetTags();
    })
  }

  ChangeDatagridIcon() {
    var parentEl, btn;
    parentEl = document.getElementsByClassName('datagrid-filter-toggle');
    btn = parentEl[0];
    btn.childNodes[0].shape = "search";
    btn.removeChild(btn.childNodes[0]);
    var icon = document.createElement("clr-icon");
    icon.setAttribute('shape', "search");
    btn.appendChild(icon);
    // @ts-ignore
    icon.childNodes[0] = "search"

  }


  async DeleteTag(selectedTag) {
    for (let index = 0; index < selectedTag.length; index++) {
      this.tSvc.RemoveSingleTagByID(selectedTag[index].id).subscribe(v => {
        this.GetTags();
      })
    }
    this.openDeleteModal = false;
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(): void {
    this.visible = false;
    this.listOfDisplayData = this.tags.filter((item: any) => item.name.indexOf(this.searchValue) !== -1);
  }


  showModal(): void {
    this.addNewTagVisible = true;
  }

  // showTemplateDataListModal(id) {
  //   this.currentBlueprintViewed = id;
  //   // this.viewTemplateDataVisible = true;
  //   this.tSvc.GetBlueprintActionsByBlueprint(id).subscribe(v => {
  //     this.viewListOfActions = v;

  //   });
  // }

  handleOkNewTag(): void {
    this.PostNewTag(this.newTagName);
    this.newTagName = "";
    this.addNewTagVisible = false;
  }

  handleCancelNewTag(): void {
    this.newTagName = "";
    this.addNewTagVisible = false;
  }

  showRenameTagModal(selectedBP) {
    this.currentTagViewed = selectedBP[0].id;
    this.renameTagName = this.tags.filter(curr => curr.id === selectedBP[0].id)[0].name;
    this.renameTagVisible = true;
  }

  handleOkRenameTag(): void {
    this.tSvc.UpdateSingleTag(this.currentTagViewed, this.renameTagName).subscribe(v => {
      this.GetTags();
    });
    this.renameTagName = "";
    this.renameTagVisible = false;
  }

  handleCancelRenameTag(): void {
    this.renameTagName = "";
    this.renameTagVisible = false;
  }

  onDetailOpen(e) {
    if (e == null) {
    }
    else {
      // this.showTemplateDataListModal(e.id);
    }
  }

  selectionChanged(e) {
    this.selected = e;
  }
}
