<clr-modal
  clrModalSize="lg"
  [(clrModalOpen)]="modalIsVisible"
  (clrModalOpenChange)="handleModalClose($event)"
>
  <h3 class="modal-title">{{ title }}</h3>
  <div class="modal-body">
    <div class="mb-1">
      <div
        class="all-heading"
        style="border-bottom: none; margin-bottom: 0px !important"
      >
        <div style="display: flex; justify-content: space-between">
          <span>System Tags</span>
          <div></div>
        </div>
      </div>
      <!-- System Tags Multi Select-->
      <clr-datagrid
        *ngIf="modalIsVisible && multiSelectSystemTags"
        class="datagrid-compact"
        [clrDgRowSelection]="true"
        [clrDgSelected]="selectedSystemTags"
        (clrDgSelectedChange)="handleSystemTagsChange($event)"
        style="overflow: hidden"
      >
        <clr-dg-column [clrDgField]="'name'">Tags</clr-dg-column>
        <clr-dg-row
          *clrDgItems="let tag of systemTags"
          [clrDgItem]="tag"
          style="overflow: hidden"
        >
          <clr-dg-cell style="cursor: pointer">{{ tag.name }}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #paginationAbc [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10]"
              >Tags per page</clr-dg-page-size
            >
            {{ paginationAbc.firstItem + 1 }} -
            {{ paginationAbc.lastItem + 1 }} of
            {{ paginationAbc.totalItems }} Tags
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

      <!-- System Tags Single Select-->
      <clr-datagrid
        *ngIf="modalIsVisible && !multiSelectSystemTags"
        class="datagrid-compact"
        [clrDgRowSelection]="true"
        [(clrDgSingleSelected)]="selectedSystemTags"
        (clrDgSingleSelectedChange)="handleSystemTagsChange($event)"
        style="overflow: hidden"
      >
        <clr-dg-column [clrDgField]="'name'">Tags</clr-dg-column>
        <clr-dg-row
          *clrDgItems="let tag of systemTags"
          [clrDgItem]="tag"
          style="overflow: hidden"
        >
          <clr-dg-cell style="cursor: pointer">{{ tag.name }}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #paginationAbc [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10]"
              >Tags per page</clr-dg-page-size
            >
            {{ paginationAbc.firstItem + 1 }} -
            {{ paginationAbc.lastItem + 1 }} of
            {{ paginationAbc.totalItems }} Tags
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>


    <!-- User Tags -->
    <div *ngIf="false" class="mt-1">
      <div
        class="all-heading"
        style="border-bottom: none; margin-bottom: 0px !important"
      >
        <div style="display: flex; justify-content: space-between">
          <span>User Tags</span>
          <div></div>
        </div>
      </div>
      <clr-datagrid
        *ngIf="modalIsVisible"
        class="datagrid-compact"
        [clrDgSelected]="selectedUserTags"
        (clrDgSelectedChange)="handleUserTagsChange($event)"
      >
        <clr-dg-column [clrDgField]="'name'">Tags</clr-dg-column>
        <clr-dg-row *clrDgItems="let tag of userTags" [clrDgItem]="tag">
          <clr-dg-cell
            #refEl
            (click)="clickName(refEl)"
            style="cursor: pointer"
            >{{ tag.name }}</clr-dg-cell
          >
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #paginationAbc [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10]"
              >Tags per page</clr-dg-page-size
            >
            {{ paginationAbc.firstItem + 1 }} -
            {{ paginationAbc.lastItem + 1 }} of
            {{ paginationAbc.totalItems }} Tags
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="handleDoneButtonClick()"
    >
      Done
    </button>
  </div>
</clr-modal>
