import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlobDownloadData } from '../models/blob-download-data';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  baseUrl: string = this.localStorageService.apiUrl;
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  uploadBinaryFile(file: File): Observable<BlobDownloadData> {
    const url = `${this.baseUrl}/blob/package-binaries`;

    const formData = new FormData();
    formData.append('form-data', file, file.name);

    return this.http.post<BlobDownloadData>(url, formData);
  }

  uploadIcon(icon: File, fileName: string): Observable<BlobDownloadData> {
    const url = `${this.baseUrl}/blob/icons`;

    const formData = new FormData();
    formData.append('file', icon, icon.name);
    formData.append('fileName', fileName);

    return this.http.post<BlobDownloadData>(url, formData);
  }

  getLog(
    endpointId: number,
    logName: string
  ): Observable<string> {
    const url = `${this.baseUrl}/blob/logs/${endpointId}/${logName}`;

    return this.http.get(url, {
      responseType: 'text',
    });
  }

  enumerateLogsForEndpoint(endpointId: number) : Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/blob/logs/enumerate/${endpointId}`);
  }
}
