import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from '../../../domain/interfaces/paginationModel';
import { PackageLifecycleUpdateDto } from '../../../domain/interfaces/package-lifecycle-update-dto';
import { ActionVersion } from '../domain/action-version';
import { LocalStorageService } from './local-storage.service';
import { Stage } from '../domain/enums/stage';
import { Observable } from 'rxjs';
import { BuilderData } from '../../package-builder/models/builder';
import { ActionVersionsPaginationDto } from 'src/app/domain/dtos/action-version-pagination-dto';
import { AutoLifecycleConfiguration as AutoLifecycleConfiguration } from '../domain/auto-lifecycle-configration';

@Injectable({
  providedIn: 'root',
})
export class ActionversionService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  GetActionVersions(filter: PaginationModel = { skip: 0, take: 0 }) {
    const queryParams = new HttpParams()
      .append('skip', filter.skip.toString())
      .append('take', filter.take.toString());
    return this.http.get<ActionVersionsPaginationDto>(
      `${this.URL}/ActionVersion`,
      { params: queryParams }
    );
  }

  GetActionVersionByID(id: number) {
    return this.http.get<ActionVersion>(`${this.URL}/ActionVersion/${id}`);
  }

  getBuilderJson(id: number) {
    return this.http.get<BuilderData>(
      `${this.URL}/ActionVersion/${id}/builder`
    );
  }

  GetActionVersionByActionID(id: number) {
    return this.http.get<ActionVersion[]>(
      `${this.URL}/ActionVersion/Action/${id}`
    );
  }

  getRecycledActionVersionByActionID(actionId: number) {
    return this.http.get<ActionVersion[]>(
      `${this.URL}/ActionVersion/Action/${actionId}/recycled`
    );
  }

  PostSingleActionVersion(data) {
    return this.http.post<ActionVersion>(`${this.URL}/ActionVersion`, data);
  }

  PostNewActionVersion(data) {
    return this.http.post<ActionVersion>(`${this.URL}/ActionVersion`, data);
  }

  UpdateSingleActionVersion(data) {
    return this.http.put<ActionVersion>(
      `${this.URL}/ActionVersion/${data.id}`,
      data
    );
  }

  BulkUpdateActionVersionLifecycle(data: PackageLifecycleUpdateDto) {
    return this.http.put(`${this.URL}/ActionVersion/update-lifecycles`, data);
  }

  bulkUpdateActionVersionStage(data: PackageLifecycleUpdateDto) {
    return this.http.put(`${this.URL}/ActionVersion/update-stages`, data);
  }

  getGlobalAutoLifecycleConfiguration(): Observable<AutoLifecycleConfiguration> {
    return this.http.get<AutoLifecycleConfiguration>(
      `${this.URL}/ActionVersion/lifecycle/configuration`
    );
  }

  putGlobalAutoLifecycleConfiguration(
    configuration: AutoLifecycleConfiguration
  ): Observable<any> {
    return this.http.put(
      `${this.URL}/ActionVersion/lifecycle/configuration`,
      configuration
    );
  }

  setAutoLifecycleForVersion(
    isTurnedOn: boolean,
    id: number
  ): Observable<any> {
    return this.http.put(
      `${this.URL}/ActionVersion/lifecycle/automatic-update/${id}`,
      isTurnedOn
    );
  }

  RemoveSingleActionVersionByID(id) {
    return this.http.delete<ActionVersion>(`${this.URL}/ActionVersion/${id}`);
  }

  AddTagToActionVersionByActionVersionId(actionVersionId, data) {
    return this.http.put<ActionVersion>(
      `${this.URL}/ActionVersion/tags/${actionVersionId}`,
      data
    );
  }

  setActionVersionStage(
    actionVersionId: number,
    stage: Stage
  ): Observable<ActionVersion> {
    return this.http.put<ActionVersion>(
      `${this.URL}/actionversion/update-stage/${actionVersionId}`,
      undefined,
      { params: new HttpParams({ fromObject: { stage: stage.toString() } }) }
    );
  }

  moveActionVersionToRecycleBin(actionVersionId: number): Observable<any> {
    return this.http.post(
      `${this.URL}/actionversion/recycle/${actionVersionId}`,
      null
    );
  }

  restoreActionVersionFromRecycleBin(actionVersionId: number): Observable<any> {
    return this.http.post(
      `${this.URL}/actionversion/restore/${actionVersionId}`,
      null
    );
  }
}
