<!-- <content-area></content-area> -->


  <clr-tabs>
    <clr-tab>
      <button clrTabLink>Endpoints</button>
      <clr-tab-content *clrIfActive>
        <!-- <div class=".container-fluid"> -->
            <div class="clr-row">
              <!-- <button class="btn btn-primary">Primary</button> -->

                <div class="clr-col-" *ngFor="let item of endpoints">
                        <!-- <div class="section-heading">Available Endpoints:
                        </div> -->
                        <!-- <div class="endpoints-parent"> -->
                            <!-- <div id="AvailableActionParent"  class="item-list" -->
                                
                                <!-- <div #refEl class="item-box" (click)="AddEndPointVisible = true"
                                >
             
                                    <p class="desktop-text">New Endpoint</p>
                                </div> -->
                                <div #refEl class="item-box"
                                    (click)="RouteHostDetails(item.id)" >
                                    <div (click)="RemoveEndPointByID(item.id);  $event.stopPropagation();"
                                        class="delete_button">
                                        <!-- <i style="font-size: x-large;" class="desktop-icon"
                                            nz-icon nzType="close-circle" nzTheme="fill"></i> -->
                                        
                                        </div>
                                    <!-- <a class="delete_button">X</a> -->
                                    <!-- <i class="desktop-icon" nz-icon nzType="desktop" nzTheme="outline"></i> -->
                                    <p class="desktop-text">{{item.name}}</p>
                                </div>
                            <!-- </div> -->
                        <!-- </div> -->
                </div>
            </div>
        <!-- </div> -->

      </clr-tab-content>
    </clr-tab>
    <clr-tab >
      <button clrTabLink>Actions</button>
      <clr-tab-content *clrIfActive="true" >

        
        <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
          <div style="float:left;">
            <button type="button" (click)="UpdateActionList()" class="btn btn-icon btn-success btn-sm" title="Update list of Actions" ><clr-icon shape="refresh"></clr-icon></button>

          </div>  
        </div>
        <clr-datagrid class="datagrid-compact" style="width: 100%;">
          <clr-dg-column >ID</clr-dg-column>
          <clr-dg-column >Actions</clr-dg-column>
          <clr-dg-column >Action Parameters</clr-dg-column>
          <clr-dg-column >Action Type</clr-dg-column>
        
          <clr-dg-row *clrDgItems="let action of actions" [clrDgItem]="action">
            <clr-dg-action-overflow>
              <button class="action-item" >Edit</button>
              <button class="action-item" >Delete</button>
            </clr-dg-action-overflow>
            <clr-dg-cell>{{action.id}}</clr-dg-cell>
            <clr-dg-cell >{{action.name}}</clr-dg-cell>
            <clr-dg-cell >{{action.parameters}}</clr-dg-cell>
            <clr-dg-cell >{{action.actionType.name}}</clr-dg-cell>
          </clr-dg-row>
        
          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="15">
              <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Actions per page</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Actions
            </clr-dg-pagination>

          </clr-dg-footer>
        </clr-datagrid>
      </clr-tab-content>
    </clr-tab>
    <!-- <clr-tab>
      <button clrTabLink>Other Settings</button>
      <clr-tab-content *clrIfActive="true">
        Cloud content
      </clr-tab-content>
    </clr-tab> -->
  </clr-tabs>


<!-- 
<clr-alert [clrAlertAppLevel]="true">
    <clr-alert-item>
      <span class="alert-text">
        Congratulations, you have installed Clarity!
      </span>
    </clr-alert-item>
  </clr-alert> -->