import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEndPointGroup } from '../domain/interfaces/iendpointgroup';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  GetEndpointGroups() {
    return this.http.get<IEndPointGroup[]>(`${this.URL}/endpointgroup/`);
  }

  GetCount(): Observable<number> {
    return this.http.get<number>(`${this.URL}/endpointgroup/count`);
  }

  GetSingleEndPointGroup(id: number) {
    return this.http.get<IEndPointGroup>(`${this.URL}/endpointgroup/${id}`);
  }

  PostSingleEndPointGroup(name: string) {
    var data = { name: name };
    return this.http.post<IEndPointGroup>(`${this.URL}/endpointgroup/`, data);
  }

  RemoveSingleEndPointGroupByID(id) {
    return this.http.delete<IEndPointGroup>(`${this.URL}/endpointgroup/${id}`);
  }

  UpdateGroupActions(data, groupId) {
    return this.http.put<IEndPointGroup>(
      `${this.URL}/endpointgroup/actions/${groupId}`,
      data
    );
  }

  UpdateSingleGroup(data: IEndPointGroup): Observable<IEndPointGroup> {
    return this.http.put<IEndPointGroup>(
      `${this.URL}/endpointgroup/${data.id}`,
      data
    );
  }

  AddTagToGroupByGroupID(groupId, data) {
    return this.http.put<IEndPointGroup>(
      `${this.URL}/endpointgroup/tags/${groupId}`,
      data
    );
  }
}
