import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cb-welcome-step',
  templateUrl: './welcome-step.component.html',
  styleUrls: ['./welcome-step.component.scss'],
})
export class WelcomeStepComponent implements OnInit {
  devOpsOrganizationName: string = environment.azureDevOpsOrganizationName;
  
  constructor() {}

  ngOnInit(): void {}
}
