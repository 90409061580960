import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClrWizard } from '@clr/angular';
import { Command } from '../../models/command';
import { BlobService } from '../../services/blob.service';
import { FilePathHandle } from '../../models/file-system-path-handle';
import { EMPTY, forkJoin, of, zip } from 'rxjs';
import { PackageFile } from '../../models/package-file';
import { xml2js, js2xml, Options } from 'xml-js';
import { BuilderData } from '../../models/builder';
import { saveAs } from 'file-saver';
import {
  getNewChocolateyInstallContent,
  getTemplateZip,
  updateNuspec,
  updateRels,
} from '../../utils/builder-utils';
import { updateXmlNuspecMedatadata } from '../../utils/xml-utils';
import { GeneralInformation } from '../../models/general-information';
import { BlobDownloadData } from '../../models/blob-download-data';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { NugetService } from '../../services/nuget.service';

const NUSPEC_PATH = 'template.nuspec';
const CHOCOLATEYINSTALL_PATH = 'tools/chocolateyinstall.ps1';
const RELS_PATH = '_rels/.rels';
const BUILDER_FILE_PATH = 'tools/builder.json';

@Component({
  selector: 'pb-package-builder',
  templateUrl: './package-builder.component.html',
  styleUrls: ['./package-builder.component.scss'],
})
export class PackageBuilderComponent implements OnInit {
  @ViewChild('wizard') wizard: ClrWizard;

  isGeneralInformationFormValid: boolean = false;
  generalInformationForm: FormGroup;
  sequence: Command[] = [];
  isSequenceValid = true;
  filePathHandles: FilePathHandle[] = [];
  filePaths: string[] = [];
  isDone = false;
  isError = false;
  isLoading = false;
  iconUrl?: string;
  defaultValues: {
    [key: string]: { [key: string]: string | boolean };
  } = {};

  uploadedFiles: PackageFile[] = [];
  uploadedIcon?: BlobDownloadData;
  currentProgressMessage: string;

  editingBuilderData?: BuilderData;

  constructor(
    private blobService: BlobService,
    private route: ActivatedRoute,
    private actionVersionService: ActionversionService,
    private router: Router,
    private nugetService: NugetService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.queryParamMap
      .pipe(
        switchMap((paramMap) => {
          if (paramMap.has('id')) {
            return this.actionVersionService.GetActionVersionByID(
              +paramMap.get('id')
            );
          }
          this.isLoading = false;
          return EMPTY;
        }),
        tap((actionVersion) => {
          this.iconUrl = actionVersion.iconUri;
        }),
        switchMap((actionVersion) => {
          return this.actionVersionService.getBuilderJson(actionVersion.id);
        })
      )
      .subscribe({
        next: (builder) => {
          this.editingBuilderData = builder;
          this.isLoading = false;
        },
        error: (err) => {
          console.error('Error fetching the specified action version: ', err);
          this.isLoading = false;
        },
      });
  }

  setGeneralInformationForm(form: FormGroup): void {
    this.generalInformationForm = form;
    this.isGeneralInformationFormValid = this.generalInformationForm.valid;

    const formData: GeneralInformation = form.value;

    if (formData.icon) {
      const reader = new FileReader();
      reader.onload = (_) => (this.iconUrl = reader.result as string);
      reader.readAsDataURL(formData.icon);
    } else if (form.get('icon').dirty) {
      this.iconUrl = null;
    }

    if (formData.id) {
      this.defaultValues = {
        'Install-ChocolateyInstallPackage': {
          PackageName: formData.id,
        },
      };
    }
  }

  async doCustomClick(
    buttonType: 'sequence-next' | 'build' | 'refresh' | 'publish'
  ): Promise<void> {
    switch (buttonType) {
      case 'sequence-next':
        this.isSequenceValid = this.checkIfSequenceIsValid();
        if (this.isSequenceValid) this.wizard.next();
        break;
      case 'build':
      case 'publish':
        this.wizard.next();
        const saveFunction: (nupkg: File) => Promise<void> =
          buttonType == 'build'
            ? async (nupkg) => saveAs(nupkg)
            : (nupkg) => this.nugetService.pushPackage(nupkg).toPromise();
        if (
          this.filePathHandles.length ||
          this.generalInformationForm.value?.icon
        ) {
          await this.uploadFiles(saveFunction);
        } else {
          await this.buildPackage(saveFunction);
        }
        break;
      case 'refresh':
        await this.router.navigate([], {
          queryParams: { id: null },
          queryParamsHandling: 'merge',
        });
        location.reload();
        break;
    }
  }

  checkIfSequenceIsValid(): boolean {
    for (const command of this.sequence) {
      for (let i = 0; i < command.parameters.length; i++) {
        if (!command.specification.parameters[i].isRequired) continue;
        if (!command.parameters[i].value) return false;
      }
    }
    return true;
  }

  onSequenceChanged(sequence: Command[]): void {
    this.sequence = sequence;
  }

  onFilesChanged(files: FilePathHandle[]): void {
    this.filePathHandles = files;
    this.filePaths = files.map((f) => f.path);
  }

  async uploadFiles(save: (nupkg: File) => Promise<void>): Promise<void> {
    this.currentProgressMessage = 'Reading files...';

    const files: (BlobDownloadData | File)[] = [];

    for (const handle of this.filePathHandles) {
      if (handle.file instanceof FileSystemFileHandle)
        files.push(await handle.file.getFile());
      else files.push(handle.file);
    }

    const observables = files.map((f, index) =>
      zip(
        of(this.filePathHandles[index].path),
        f instanceof File
          ? this.blobService.uploadBinaryFile(f)
          : of(f as BlobDownloadData)
      )
    );

    const formData: GeneralInformation = this.generalInformationForm.value;

    if (formData.icon) {
      observables.push(
        zip(
          of('icon'),
          this.blobService.uploadIcon(
            formData.icon,
            `${formData.id}.${formData.version}`
          )
        )
      );
    }

    const blobObservables = forkJoin(observables);

    this.currentProgressMessage = 'Uploading files...';

    blobObservables.subscribe({
      next: async (results) => {
        results.forEach((result) => {
          const [name, response] = result;
          if (name === 'icon') {
            this.uploadedIcon = response;
          } else {
            this.uploadedFiles.push({
              path: name,
              downloadUri: response.downloadUri,
              hash: response.hash,
            });
          }
        });
        await this.buildPackage(save);
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
    });
  }

  async buildPackage(save: (nupkg: File) => Promise<void>): Promise<void> {
    try {
      this.currentProgressMessage = 'Downloading template...';

      const templateZip = await getTemplateZip();

      this.currentProgressMessage = 'Packing .nupkg...';

      const formData: GeneralInformation = this.generalInformationForm.value;
      formData.iconUrl = this.uploadedIcon?.downloadUri ?? this.iconUrl;

      const builderData: BuilderData = {
        generalInformation: formData,
        sequence: this.sequence,
        files: this.uploadedFiles,
      };

      const chocolateyinstallFile = templateZip.file(CHOCOLATEYINSTALL_PATH);
      const nuspecFile = templateZip.file(NUSPEC_PATH);
      const relsFile = templateZip.file(RELS_PATH);

      const chocolateyStringData = await chocolateyinstallFile.async('string');
      const nuspecStringData = await nuspecFile.async('string');
      const relsStringData = await relsFile.async('string');

      const nuspecTree = xml2js(nuspecStringData);
      updateNuspec(nuspecTree, formData);

      const tags = [
        formData.rebootAfterInstall ? 'rebootAfterInstall' : null,
        formData.detachFromService ? 'detachFromService' : null,
        formData.processesToClose.length
          ? `processesToClose=${formData.processesToClose.join(';')}`
          : null,
      ]
        .filter((t) => t)
        .join(' ');

      updateXmlNuspecMedatadata(nuspecTree, 'tags', tags);

      if (this.uploadedIcon) {
        updateXmlNuspecMedatadata(
          nuspecTree,
          'iconUrl',
          this.uploadedIcon?.downloadUri
        );
      } else if (this.editingBuilderData?.generalInformation?.iconUrl) {
        updateXmlNuspecMedatadata(
          nuspecTree,
          'iconUrl',
          this.editingBuilderData.generalInformation.iconUrl
        );
      }

      const relsTree = xml2js(relsStringData);
      updateRels(relsTree, formData);

      const newChocolateyInstallStringData = getNewChocolateyInstallContent(
        chocolateyStringData,
        this.sequence
      );

      const js2xmlOptions: Options.JS2XML = {
        spaces: 2,
        fullTagEmptyElement: true,
      };

      templateZip.file(BUILDER_FILE_PATH, JSON.stringify(builderData, null, 2));
      templateZip.file(CHOCOLATEYINSTALL_PATH, newChocolateyInstallStringData);
      templateZip.file(RELS_PATH, js2xml(relsTree, js2xmlOptions));
      templateZip.remove(NUSPEC_PATH);
      templateZip.file(
        `${formData.id}.nuspec`,
        js2xml(nuspecTree, js2xmlOptions)
      );

      const nupkgName = `${formData.id}.${formData.version}.nupkg`;
      const nupkgBlob = await templateZip.generateAsync({ type: 'blob' });

      const nupkgFile = new File([nupkgBlob], nupkgName, {
        type: nupkgBlob.type,
      });

      this.currentProgressMessage = 'Uploading package...';

      await save(nupkgFile);

      this.currentProgressMessage = 'Done.';
      this.isDone = true;
    } catch (e) {
      this.isError = true;
      console.error('Error while building package', e);
    }
  }
}
