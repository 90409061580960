import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageBuilderComponent } from './components/package-builder/package-builder.component';
import { ClarityModule } from '@clr/angular';
import { GeneralInformationStepComponent } from './components/general-information-step/general-information-step.component';
import { FileManagementComponent } from './components/file-management/file-management.component';
import { SequenceBuilderStepComponent } from './components/sequence-builder-step/sequence-builder-step.component';
import { CommandSpecItemComponent } from './components/command-spec-item/command-spec-item.component';
import { ExpandableCommandItemComponent } from './components/expandable-command-item/expandable-command-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SummaryStepComponent } from './components/summary-step/summary-step.component';
import { CamelCaseSplitterPipe } from './pipes/camel-case-splitter.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BuildingStepComponent } from './components/building-step/building-step.component';
import { InputWithChipsComponent } from './components/input-with-chips/input-with-chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  ClarityIcons,
  importIcon,
  fileGroupIcon,
  tasksIcon,
  syncIcon,
  timesIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  importIcon,
  fileGroupIcon,
  tasksIcon,
  syncIcon,
  timesIcon
);

@NgModule({
  declarations: [
    PackageBuilderComponent,
    GeneralInformationStepComponent,
    FileManagementComponent,
    SequenceBuilderStepComponent,
    CommandSpecItemComponent,
    ExpandableCommandItemComponent,
    SummaryStepComponent,
    CamelCaseSplitterPipe,
    BuildingStepComponent,
    InputWithChipsComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatFormFieldModule,
  ],
  exports: [PackageBuilderComponent],
})
export class PackageBuilderModule {}
