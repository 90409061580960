import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NugetService {
  baseUrl: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  pushPackage(nupkg: File): Observable<void> {
    const formData = new FormData();
    formData.append('form-data', nupkg);

    return this.http.post<void>(`${this.baseUrl}/nuget`, formData);
  }
}
