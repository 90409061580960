import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from 'src/app/modules/shared/domain/action';
import { VersionDropEvent } from 'src/app/domain/interfaces/version-drop-event';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';

@Component({
  selector: 'nd-package-lifecycle-column',
  templateUrl: './package-lifecycle-column.component.html',
  styleUrls: ['./package-lifecycle-column.component.scss'],
})
export class PackageLifecycleColumnComponent {
  @Input() actions: Action[] = [];
  @Input() hasButtons = true;
  @Input() moveAllButtonText: string;
  @Output() dropEvent = new EventEmitter<VersionDropEvent>();
  @Output() moveEvent = new EventEmitter<ActionVersion[]>();
  filteredActions: Action[] = [];
  query = '';

  filterCallback = (a: Action) => {
    return (
      a.actionVersion.length &&
      (!this.query ||
        (a.name?.toUpperCase().includes(this.query.toUpperCase()) ?? false))
    );
  };

  constructor() {}

  drop(event: VersionDropEvent) {
    this.dropEvent.emit(event);
  }

  move(versions: ActionVersion[]) {
    this.moveEvent.emit(versions);
  }
}
