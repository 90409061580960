export function setMspColorScheme() {
  const ndpMspBrandColor = '#4FAC9E';
  const ndpMspLightBrandColor = '#CAE6E2';
  const tableFontColor = '#565656';
  const black = '#000000';

  document.documentElement.style.setProperty(
    '--ndp-brand-color',
    ndpMspBrandColor
  );
  document.documentElement.style.setProperty(
    '--ndp-light-brand-color',
    ndpMspLightBrandColor
  );
  document.documentElement.style.setProperty('--clr-h3-color', black);
  document.documentElement.style.setProperty(
    '--clr-vertical-nav-item-color',
    black
  );
  document.documentElement.style.setProperty(
    '--clr-vertical-nav-item-active-color',
    black
  );
  document.documentElement.style.setProperty(
    '--clr-table-font-color',
    tableFontColor
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-50',
    '#edf7f5'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-100',
    '#cae6e2'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-200',
    '#a7d6cf'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-300',
    '#84c5bb'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-400',
    '#72bdb1'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-500',
    '#4fac9e'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-600',
    '#479b8e'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-700',
    '#37786f'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-800',
    '#28564f'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-900',
    '#18342f'
  );
  document.documentElement.style.setProperty(
    '--clr-color-action-1000',
    '#081110'
  );
}
