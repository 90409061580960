import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBlueprintActions } from '../domain/interfaces/iblueprintactions';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlueprintActionService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  updateBlueprintAction(
    blueprintAction: IBlueprintActions
  ): Observable<IBlueprintActions> {
    return this.http.put<IBlueprintActions>(
      `${this.URL}/blueprintaction/${blueprintAction.id}`,
      blueprintAction
    );
  }

  GetBluePrintActions() {
    return this.http.get<IBlueprintActions>(`${this.URL}/blueprintaction`);
  }

  GetBlueprintActionsByBlueprint(id) {
    return this.http.get<IBlueprintActions>(
      `${this.URL}/blueprintaction/blueprint/${id}`
    );
  }

  PostBluePrintActions(data) {
    return this.http.post<IBlueprintActions>(
      `${this.URL}/blueprintaction/actions`,
      data
    );
  }

  PutBlueprintActions(data, blueprintId) {
    return this.http.put<IBlueprintActions>(
      `${this.URL}/blueprintaction/actions/${blueprintId}`,
      data
    );
  }
}
