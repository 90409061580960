import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authenticationService.currentUserValue) {
      this.navigateToLoginPage(state);
      return false;
    }

    const groups = this.getGroups();

    const hasRequiredGroups =
      groups.includes(environment.tenantSecurityGroupId) ||
      groups.includes(environment.mspSecurityGroupId);

    if (hasRequiredGroups) {
      return true;
    } else {
      this.handleInsufficientPermissions();
      this.navigateToLoginPage(state);
      return false;
    }
  }

  handleInsufficientPermissions(): void {
    this.alertInsufficientPermissions();
    this.forceLogOut();
  }

  navigateToLoginPage(state: RouterStateSnapshot): void {
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  }

  forceLogOut(): void {
    this.authenticationService.logOut();
  }

  alertInsufficientPermissions(): void {
    alert('Insufficient Permissions to access this tenant.');
  }

  getGroups(): string[] {
    try {
      const claims = this.authenticationService.currentUserValue.claims;
      return claims['groups'] as string[];
    } catch (error) {
      return [];
    }
  }
}
