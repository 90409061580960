<div class="card table-container-card">
  <div
    class="table-heading"
    style="border-bottom: none; margin-bottom: 0px !important"
  >
    <div class="table-title">
      <cds-icon shape="application" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Applications</h3>
    </div>
  </div>
  <clr-datagrid
    *ngIf="applications$ | async as applications"
    id="my-datagrid"
    class="datagrid-compact w-100"
    [clrDgSelected]="selectedApplications"
    (clrDgSelectedChange)="handleSelectionChange($event)"
  >
    <clr-dg-column [clrDgField]="'name'">Application</clr-dg-column>
    <clr-dg-column>Publisher</clr-dg-column>
    <clr-dg-column>Latest Version</clr-dg-column>
    <clr-dg-row
      *clrDgItems="let application of applications"
      [clrDgItem]="application"
    >
      <clr-dg-cell
        style="display: flex; height: 40px; justify-content: space-between"
        >{{ application.name }}</clr-dg-cell
      >
      <clr-dg-cell>
        {{ application.publisher }}
      </clr-dg-cell>
      <clr-dg-cell>
        {{ application.applicationVersions[0].version }}
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[15, 30, 45, 60, 75, 90, 100]"
          >Applications per page</clr-dg-page-size
        >
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
        {{ pagination.totalItems }} Packages
      </clr-dg-pagination>
    </clr-dg-footer>

    <ng-template
      [(clrIfDetail)]="detailState"
      let-detail
      (clrIfDetailChange)="onDetailOpen($event)"
    >
      <clr-dg-detail>
        <clr-dg-detail-header>
          <span style="display: flex; justify-content: space-between">
            <span>{{ detail.name }}</span>
            <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
              <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
            </button>
          </span>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink>Versions</button>
              <clr-tab-content>
                <clr-datagrid
                  class="datagrid-compact mt-2"
                  id="my-datagrid"
                  [clrDgSelected]="selectedApplicationVersions"
                  (clrDgSelectedChange)="handleVersionSelectionChange($event)"
                >
                  <clr-dg-column>Application Versions</clr-dg-column>
                  <clr-dg-row
                    *clrDgItems="let version of detail.applicationVersions"
                    [clrDgItem]="version"
                  >
                    <clr-dg-cell>{{ version.version }}</clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10, 15]"
                        >Versions per page</clr-dg-page-size
                      >
                      {{ paginationBP.firstItem + 1 }} -
                      {{ paginationBP.lastItem + 1 }} of
                      {{ paginationBP.totalItems }}
                      Versions
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Endpoints</button>
              <clr-tab-content [clrCloseMenuOnItemClick]="true">
                <clr-dropdown class="mt-2">
                  <button class="dropdown-toggle" clrDropdownTrigger>
                    Select version
                  </button>
                  <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <label class="dropdown-header" aria-hidden="true"
                      >Filter by version</label
                    >
                    <div clrDropdownItem (click)="handleVersionFilterChange()">
                      Reset
                    </div>
                    <div class="dropdown-divider"></div>
                    <div
                      clrDropdownItem
                      *ngFor="let version of detail.applicationVersions"
                      (click)="handleVersionFilterChange(version)"
                    >
                      {{ version.version }}
                    </div>
                  </clr-dropdown-menu>
                </clr-dropdown>
                <clr-datagrid
                  class="datagrid-compact mt-2"
                  id="my-datagrid"
                  [clrDgSelected]="selectedApplicationEndpoints"
                  (clrDgSelectedChange)="
                    handleApplicationEndpointSelectionChange($event)
                  "
                >
                  <clr-dg-column>Endpoints</clr-dg-column>
                  <clr-dg-column>Installed Version</clr-dg-column>
                  <clr-dg-column>Installed by NimDeploy</clr-dg-column>
                  <clr-dg-row
                    *clrDgItems="let endpoint of filteredApplicationEndpoints"
                    [clrDgItem]="endpoint"
                  >
                    <clr-dg-cell>{{ endpoint.endpoint.name }}</clr-dg-cell>
                    <clr-dg-cell>{{ endpoint.version.version }}</clr-dg-cell>
                    <clr-dg-cell>{{
                      endpoint.installedByNimDeploy == true ? "TRUE" : "FALSE"
                    }}</clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationBP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10, 15]"
                        >Endpoints per page</clr-dg-page-size
                      >
                      {{ paginationBP.firstItem + 1 }} -
                      {{ paginationBP.lastItem + 1 }} of
                      {{ paginationBP.totalItems }}
                      Endpoints
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>
