import { Component, Input } from '@angular/core';
import { ITag } from 'src/app/modules/shared/domain/tag';

@Component({
  selector: 'nd-lifecycle-badge',
  templateUrl: './lifecycle-badge.component.html',
  styleUrls: ['./lifecycle-badge.component.scss']
})
export class LifecycleBadgeComponent {
  @Input() tag: ITag;
}
