import { Component, OnInit } from '@angular/core';
import { Tenant } from '../../domain/tenant';
import { Action } from '../../domain/action';
import { ActionService } from '../../services/action.service';
import { TenantService } from '../../services/tenant.service';
import { SubscriptionStatus } from '../../domain/subscription-status';

@Component({
  selector: 'msp-package-subscription-status-table',
  templateUrl: './package-subscription-status-table.component.html',
  styleUrls: ['./package-subscription-status-table.component.scss'],
})
export class PackageSubscriptionStatusTableComponent implements OnInit {
  dataIsLoading: boolean = true;
  tenants: Tenant[];
  actions: Action[];
  subscriptionStatuses: SubscriptionStatus[] = [];

  constructor(
    private actionService: ActionService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.calculateSubscriptionStatus();
  }

  calculateSubscriptionStatus() {
    this.actionService.getActions().subscribe({
      next: (actions) => {
        this.actions = actions;
        this.tenantService.getAllTenants().subscribe({
          next: (tenants) => {
            this.tenants = tenants;
            this.actions.forEach((action) => {
              const subStatus: SubscriptionStatus = {
                action: action,
                statuses: [],
              };
              this.tenants.forEach((tenant) => {
                let isSubscribed = false;

                var exists = action.subscribedTenants.find(
                  (a) => a.id === tenant.id
                );

                if (exists) {
                  isSubscribed = true;
                }

                subStatus.statuses.push({
                  tenant: tenant,
                  isSubscribed: isSubscribed,
                });
              });
              this.subscriptionStatuses.push(subStatus);
            });
            this.dataIsLoading = false;
          },
          error: (err) => console.error(err),
        });
      },
      error: (err) => console.error(err),
    });
  }
}
