import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEndpointStatus } from '../domain/interfaces/istatus';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointStatusService {
  URL: string = this.localStorageService.apiUrl;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  GetEndpointStatus() {
    return this.http.get<IEndpointStatus>(`${this.URL}/endpointstatus`);
  }

  GetSingleEndpointStatusById(id) {
    return this.http.get<IEndpointStatus>(`${this.URL}/endpointstatus/${id}`);
  }

  PostSingleEndpointStatus(name: string) {
    var data = { "name": name };
    return this.http.post<IEndpointStatus>(`${this.URL}/endpointstatus`, data);
  }

  UpdateSingleEndpointStatus(id, name) {
    var data = {
      "id": parseInt(id),
      "name": name
    };
    return this.http.put<IEndpointStatus>(`${this.URL}/endpointstatus/${id}`, data);
  }
  DeleteSingleEndpointStatusByID(id) {
    return this.http.delete<IEndpointStatus>(`${this.URL}/endpointstatus/${id}`);

  }
}
