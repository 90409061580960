<form clrForm [formGroup]="generalInformationForm" (change)="validateForm()" autocomplete="off">
    <clr-input-container>
        <label for="vendor">Vendor</label>
        <input type="text" formControlName="vendor" placeholder="Vendor name" clrInput />
    </clr-input-container>
    <clr-input-container>
        <label for="vendor" class="required-label">Application Id</label>
        <input type="text" formControlName="id" placeholder="Application Id" clrInput />
        <clr-control-error>Enter application id.</clr-control-error>
    </clr-input-container>
    <clr-input-container>
        <label for="application" class="required-label">Application Name</label>
        <input type="text" formControlName="title" placeholder="Application name" clrInput />
        <clr-control-error>Enter application name.</clr-control-error>
    </clr-input-container>
    <clr-input-container>
        <label for="version" class="required-label">Version</label>
        <input type="text" formControlName="version" placeholder="0.0.0.0" clrInput />
        <clr-control-error *clrIfError="'required'">Enter valid version number.</clr-control-error>
        <clr-control-error *clrIfError="'pattern'">
            Version must be in a valid software version format.
        </clr-control-error>
    </clr-input-container>
    <clr-select-container>
        <label for="language">Language</label>
        <select clrSelect formControlName="language">
            <option value="en">English</option>
            <option value="nl">Dutch</option>
            <option value="fr">French</option>
            <option value="mk">Macedonian</option>
        </select>
    </clr-select-container>
    <clr-radio-container clrInline>
        <label for="architecture" class="required-label">Architecture</label>
        <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="architecture" value="x86" />
            <label>x86</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="architecture" value="x64" />
            <label>x64</label>
        </clr-radio-wrapper>
    </clr-radio-container>
    <clr-textarea-container>
        <label for="description" class="required-label">Description</label>
        <textarea clrTextarea formControlName="description" rows="5"
            placeholder="A description for the package..."></textarea>
        <clr-control-error>Enter short package description.</clr-control-error>
    </clr-textarea-container>
    <clr-input-container>
        <label for="authors" class="required-label">Authors</label>
        <input type="text" formControlName="authors" placeholder="Authors" clrInput />
        <clr-control-error>Enter author names.</clr-control-error>
    </clr-input-container>
    <clr-input-container>
        <label for="owners">Owners</label>
        <input type="text" formControlName="owners" placeholder="Owners" clrInput />
    </clr-input-container>
    <clr-input-container>
        <label for="copyright">Copyright</label>
        <input type="text" formControlName="copyright" placeholder="Copyright" clrInput />
    </clr-input-container>
    <clr-control-container>
        <label for="icon">Icon</label>
        <input type="file" (change)="onIconSelected($event)" accept=".png, .jpg, .jpeg, .svg, .ico" />
    </clr-control-container>
    <clr-checkbox-container>
        <label for="detachFromService">Detach from Service</label>
        <clr-checkbox-wrapper>
            <input type="checkbox" formControlName="detachFromService" clrCheckbox />
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <clr-checkbox-container>
        <label for="rebootAfterInstall">Reboot after install</label>
        <clr-checkbox-wrapper>
            <input type="checkbox" formControlName="rebootAfterInstall" clrCheckbox />
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <div class="generic-container">
        <label>Processes to close</label>
        <pb-input-with-chips [selections]="processesToClose" (onSelectionChangedEvent)="onProcessesToCloseChanged($event)"></pb-input-with-chips>
    </div>
</form>