import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'msp-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
