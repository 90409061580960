<nd-back-button></nd-back-button>

<div *ngIf="isLoadingPage" class="spinner-wrapper">
    <span class="spinner">
        Loading...
    </span>
</div>

<div *ngIf="!isLoadingPage" class="card details-container-card">
    <div class="details-title">
        <img src="../../../assets/icons/right-arrow.svg" />
        <h5 *ngIf="groupData">{{groupData.name}}
            <span *ngIf="saveSvc.currentSave.unsavedChanges">*</span>
        </h5>
        <div class="btn-group btn-icon add-blueprint">
            <button type="button" class="btn btn-icon btn-success btn-md" title="Add new Blueprint"
                (click)="addBlueprintModal()" #tooltip="matTooltip" matTooltip="Import Blueprint"
                [matTooltipPosition]="'below'" matTooltipHideDelay="100">
                <img src="../../../assets/icons/plus.svg" />
                Add Blueprints
            </button>
        </div>
    </div>
    <div class="main-container row">
        <div class="col-md-6">
            <div class="section-heading">
                <div class="filter">
                    <div>Available Packages: </div>
                    <nz-input-group [nzSuffix]="inputClearTpl">
                        <input type="text" nz-input [(ngModel)]="inputValue" class="form-control"
                            id="availableActionInput" placeholder="Filter" (keyup)="filterAvailableActions()" />
                    </nz-input-group>
                    <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill"
                            nzType="close-circle" *ngIf="inputValue" (click)="ClearAvailableFilterValue()"></i>
                    </ng-template>
                </div>

            </div>
            <div cdkScrollable class="scroll-container">
                <div id="AvailableActionParent" cdkDropList #pendingList="cdkDropList" [cdkDropListData]="actions"
                    (cdkDropListEntered)="onSourceListEntered($event)" cdkDropListSortingDisabled
                    [cdkDropListConnectedTo]="[doneList,reviewList]" class="item-list"
                    (cdkDropListDropped)="removeSelectedItemDrop($event)">
                    <div #refEl class="draggable-item item-box" *ngFor="let item of actions" [cdkDragData]="item"
                        cdkDrag>
                        <div class="item-name">
                            {{item.name}}
                        </div>
                        <button (click)="addItem(item)" type="button" #tooltip="matTooltip"
                            matTooltip="Add Installer Package" [matTooltipPosition]="'below'" matTooltipHideDelay="100">
                            <cds-icon shape="angle" direction="right"></cds-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="section-heading" style="text-align: center;">
                <div class="btn-group btn-icon">
                    <button type="button" (click)="DeleteEverything()"
                        class="btn btn-icon btn-primary btn-md btn-outline" aria-label="settings" #tooltip="matTooltip"
                        matTooltip="Remove every selected item" [matTooltipPosition]="'below'"
                        matTooltipHideDelay="100">
                        <img src="../../../assets/icons/rewind.svg" />
                    </button>
                    <button type="button" (click)="saveGroupAssignment()" class="btn btn-icon btn-success btn-md"
                        aria-label="settings" #tooltip="matTooltip" matTooltip="Save your Assignment"
                        [matTooltipPosition]="'below'" matTooltipHideDelay="100">
                        <img src="../../../assets/icons/diskette.svg" />
                    </button>
                </div>
                <div class="filter">
                    <div>Selected Items: </div>
                    <nz-input-group [nzSuffix]="inputClearTplSelected">
                        <input type="text" nz-input [(ngModel)]="selectedInputValue" class="form-control"
                            id="selectedActionInput" placeholder="Filter" (keyup)="FilterSelectedActions()" />
                    </nz-input-group>
                    <ng-template #inputClearTplSelected><i nz-icon class="ant-input-clear-icon" nzTheme="fill"
                            nzType="close-circle" *ngIf="inputValue" (click)="ClearSelectedFilterValue()"></i>
                    </ng-template>
                </div>
            </div>
            <div cdkScrollable class="scroll-container">
                <div id="selectedActionParent" cdkDropList #doneList="cdkDropList"
                    [cdkDropListData]="groupAssignmentItems" [cdkDropListConnectedTo]="[pendingList,reviewList]"
                    class="item-list" (cdkDropListDropped)="selectItemDrop($event)">
                    <div #refEl class="draggable-item selected-item"
                        *ngFor="let item of groupAssignmentItems; index as index" [cdkDragData]="item" cdkDrag>
                        <button (click)="removeItemFromIndex(index)" type="button">
                            <cds-icon shape="angle" direction="left"></cds-icon>
                        </button>
                        <div class="selected-item-details">
                            <cds-icon *ngIf="item.action" shape="application" size="md"
                                style="--color: var(--ndp-brand-color)"></cds-icon>
                            <cds-icon *ngIf="item.blueprint" shape="applications" size="md"
                                style="--color: var(--ndp-brand-color)"></cds-icon>
                            <div class="item-name">{{item.action ? item.action.name : item.blueprint.name}}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="actions">
        <clr-toggle-container>
            <clr-toggle-wrapper>
                <label>Immediate deployment</label>
                <input type="checkbox" clrToggle name="options" required value="option1" [(ngModel)]="isAccepted" />
            </clr-toggle-wrapper>
        </clr-toggle-container>
        <div class="btn-group btn-icon">
            <button type="button" (click)="saveGroupAssignment()" class="btn btn-icon btn-success btn-md"
                [class.btn-warning]="saveSvc.currentSave.unsavedChanges" aria-label="settings" #tooltip="matTooltip"
                matTooltip="Save your Assignment" [matTooltipPosition]="'below'" matTooltipHideDelay="100"
                [clrLoading]="saveButtonState">
                Save
            </button>
        </div>
    </div>
</div>

<app-notify-me #cmp></app-notify-me>

<clr-modal clrModalSize="lg" [(clrModalOpen)]="openBlueprintModal">
    <h3 class="modal-title">Add Blueprints</h3>
    <div class="modal-body">
        <clr-datagrid *ngIf="openBlueprintModal" class="datagrid-compact" [clrDgSelected]="selectedBlueprintsToAdd"
            (clrDgSelectedChange)="selectionChanged($event)">
            <clr-dg-column>Blueprint</clr-dg-column>
            <clr-dg-row *clrDgItems="let blueprint of listofBlueprints" [clrDgItem]="blueprint">
                <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{blueprint.name}}</clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                <clr-dg-pagination #paginationAbc [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10]">Blueprints per page</clr-dg-page-size>
                    {{paginationAbc.firstItem + 1}} - {{paginationAbc.lastItem + 1}} of {{paginationAbc.totalItems}}
                    Blueprints
                </clr-dg-pagination>
            </clr-dg-footer>
            <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">
                <clr-dg-detail>
                    <clr-dg-detail-header>{{detail.name}} </clr-dg-detail-header>
                    <clr-dg-detail-body>
                        <clr-datagrid class="datagrid-compact">
                            <clr-dg-column>Action</clr-dg-column>
                            <clr-dg-column [style.width.px]="50">Type</clr-dg-column>
                            <clr-dg-column>Version</clr-dg-column>
                            <clr-dg-row *clrDgItems="let blueprint of viewListOfActions" [clrDgItem]="blueprint.action">
                                <clr-dg-cell>{{blueprint.action.name}}</clr-dg-cell>
                                <clr-dg-cell *ngIf="blueprint.isInstall==true">
                                    <cds-icon shape="install" status="success" size="md" solid></cds-icon>
                                </clr-dg-cell>
                                <clr-dg-cell *ngIf="blueprint.isInstall==false && 2>blueprint.action.actionType.id">
                                    <cds-icon shape="uninstall" status="danger" size="md" solid></cds-icon>
                                </clr-dg-cell>
                                <clr-dg-cell *ngIf="blueprint.action.actionType.name=='Command Prompt'">Command
                                    Prompt</clr-dg-cell>
                                <clr-dg-cell
                                    *ngIf="blueprint.action.actionType.name=='Powershell'">Powershell</clr-dg-cell>
                                <clr-dg-cell>{{blueprint.actionVersion.version}}</clr-dg-cell>
                            </clr-dg-row>
                            <clr-dg-footer>
                                <clr-dg-pagination #paginationBP [clrDgPageSize]="8">
                                    <clr-dg-page-size [clrPageSizeOptions]="[8,15]">Actions per page</clr-dg-page-size>
                                    {{paginationBP.firstItem + 1}} - {{paginationBP.lastItem + 1}} of
                                    {{paginationBP.totalItems}} Actions
                                </clr-dg-pagination>
                            </clr-dg-footer>
                        </clr-datagrid>
                    </clr-dg-detail-body>
                </clr-dg-detail>
            </ng-template>
        </clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="blueprintModalDone()">Done</button>
    </div>
</clr-modal>