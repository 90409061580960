import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionInformation } from '../../models/subscription-information';
import { GeneralInformation } from '../../models/general-information';
import { NugetInformation } from '../../models/nuget-information';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cb-creating-step',
  templateUrl: './creating-step.component.html',
  styleUrls: ['./creating-step.component.scss'],
})
export class CreatingStepComponent implements OnInit {
  organizationName: string = environment.azureDevOpsOrganizationName;
  projectName: string = environment.azureDevOpsProjectName;
  
  @Input() subscriptionInformation: SubscriptionInformation;
  @Input() generalInformation: GeneralInformation;
  @Input() nugetInformation: NugetInformation;

  @Input() creationInProgress: boolean;
  @Output() creationInProgressChange = new EventEmitter<boolean>();

  @Input() creationSuccess: boolean;
  @Output() creationSuccessChange = new EventEmitter<boolean>();

  @Input() creationError: boolean;
  @Output() creationErrorChange = new EventEmitter<boolean>();

  @Input() errorMessage: string;
  @Output() errorMessageChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
