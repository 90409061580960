import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBluePrint } from '../domain/interfaces/iblueprint';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlueprintService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  GetBluePrint() {
    return this.http.get<IBluePrint[]>(`${this.URL}/blueprint`);
  }

  GetCount(): Observable<number> {
    return this.http.get<number>(`${this.URL}/blueprint/count`);
  }

  GetSingleBlueprintById(id) {
    return this.http.get<IBluePrint>(`${this.URL}/blueprint/${id}`);
  }

  PostSingleBlueprint(name: string, description?: string) {
    if (description == null) {
      description = '';
    }
    var data = { name: name, description: description };
    return this.http.post<IBluePrint>(`${this.URL}/blueprint`, data);
  }

  UpdateBlueprint(data: IBluePrint): Observable<IBluePrint> {
    return this.http.put<IBluePrint>(`${this.URL}/blueprint/${data.id}`, data);
  }

  UpdateSingleBlueprint(id, name, description) {
    var data = {
      id: parseInt(id),
      name: name,
      description: description,
    };
    return this.http.put<IBluePrint>(`${this.URL}/blueprint/${id}`, data);
  }

  DeleteSingleBlueprintByID(id) {
    return this.http.delete<IBluePrint>(`${this.URL}/blueprint/${id}`);
  }

  AddTagToBlueprintByBlueprintID(blueprintId, data) {
    return this.http.put<IBluePrint>(
      `${this.URL}/blueprint/tags/${blueprintId}`,
      data
    );
  }

  GetBlueprintsWithAction(actionId: number) {
    return this.http.get<IBluePrint[]>(
      `${this.URL}/blueprint/withAction/${actionId}`
    );
  }
}
