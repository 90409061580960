import { Pipe, PipeTransform } from '@angular/core';
import { ActionStatus } from '../domain/enums/action-status';

@Pipe({
  name: 'enumToString',
})
export class EnumToStringPipe implements PipeTransform {
  transform(value: ActionStatus): string {
    return ActionStatus[value].replace(/([A-Z])/g, ' $1').trim();
  }
}
