<div class="console">
    <div class="console-header">
        <button *ngIf="text?.length" id="copyButton" class="btn btn-link" (click)="onCopy()">
            <img src="../../../../assets/icons/add.svg" class="copy-icon" />
            {{copyButtonText}}
        </button>
        <button *ngIf="text?.length" class="btn btn-outline" (click)="onOpenInNewTab()">
            <cds-icon shape="download" style="--color: var(--ndp-brand-color)"></cds-icon>
            Download
        </button>
        <button type="button" class="btn btn-icon" aria-label="toggle dark and light mode" (click)="onToggleMode()">
            <cds-icon shape="moon" [solid]="!localStorageService.isLightTheme"
                style="--color: var(--ndp-brand-color)"></cds-icon>
        </button>
    </div>
    <div class="consolebody" #consoleBody [class.light]="localStorageService.isLightTheme"
        [class.dark]="!localStorageService.isLightTheme">
        <span>{{shouldDisplayText ? text : '[!] Can\'t display large logs. Download or copy the log to view it.'}}</span>
    </div>
</div>