import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectionChip } from '../input-with-chips/input-with-chips.component';
import { BuilderData } from '../../models/builder';

@Component({
  selector: 'pb-general-information-step',
  templateUrl: './general-information-step.component.html',
  styleUrls: ['./general-information-step.component.scss'],
})
export class GeneralInformationStepComponent implements OnChanges {
  @Output() generalInformationFormEmitter = new EventEmitter<FormGroup>();
  @Output() isFormValid = new EventEmitter<boolean>();
  @Input() builderData?: BuilderData;
  generalInformationForm: FormGroup = new FormGroup({
    vendor: new FormControl(''),
    id: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    version: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)$/),
    ]),
    language: new FormControl(''),
    description: new FormControl('', Validators.required),
    authors: new FormControl('', Validators.required),
    architecture: new FormControl('', Validators.required),
    owners: new FormControl(''),
    copyright: new FormControl(''),
    icon: new FormControl(null),
    detachFromService: new FormControl(false),
    rebootAfterInstall: new FormControl(false),
    processesToClose: new FormControl([]),
  });
  processesToClose?: SelectionChip[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.builderData && this.builderData) {
      const general = this.builderData.generalInformation;
      this.generalInformationForm.patchValue(
        {
          vendor: general.vendor,
          id: general.id,
          title: general.title,
          version: general.version,
          language: general.language,
          architecture: general.architecture,
          description: general.description,
          authors: general.authors,
          owners: general.owners,
          copyright: general.copyright,
          detachFromService: general.detachFromService,
          rebootAfterInstall: general.rebootAfterInstall,
          processesToClose: general.processesToClose ?? [],
        },
        { emitEvent: true }
      );
      if (general.processesToClose) {
        this.processesToClose = general.processesToClose.map((p) => {
          return {
            value: p,
          };
        });
      }

      this.validateForm();
    }
  }

  validateForm(): void {
    this.isFormValid.emit(this.generalInformationForm.valid);
    this.generalInformationFormEmitter.emit(this.generalInformationForm);
  }

  onIconSelected(event: InputEvent): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.generalInformationForm.patchValue({ icon: file });
  }

  onProcessesToCloseChanged(selections: SelectionChip[]): void {
    this.generalInformationForm.patchValue({
      processesToClose: selections.map((s) => s.value),
    });
  }
}
