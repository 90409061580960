<nd-back-button></nd-back-button>
<div class="card ">
    <clr-progress-bar [class.invisible]="!isLoading" clrValue="75" clrLoop></clr-progress-bar>
    <div class="p-3 pt-1">
        <h3> <cds-icon shape="cog" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
            Recycle Bin Configuration</h3>
        <div class="mt-4">
            <div class="configuration-wrapper w-100">
                <div>
                    <clr-toggle-container class="mb-3">
                        <clr-toggle-wrapper>
                            <label>Automatic package cleanup</label>
                            <input [disabled]="isLoading" type="checkbox" clrToggle
                                [(ngModel)]="configuration.isAutomaticCleanupOn" name="enableAutoCleanup" />
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <form clrForm clrLayout="vertical">
                        <clr-input-container>
                            <label for="days">Minimum number of production versions to keep</label>
                            <input [disabled]="!configuration.isAutomaticCleanupOn || isLoading"
                                [(ngModel)]="configuration.versionCountToKeep" name="versionCountToKeep" clrInput
                                max="10" min="3" type="number" />
                        </clr-input-container>
                        <p class="mt-1 mb-3">This configures how many latest versions should be left by the automatic
                            cleanup.
                        </p>
                        <clr-select-container>
                            <label for="days">Retention period</label>
                            <select clrSelect name="retentionDays"
                                [(ngModel)]="configuration.recycleBinRetentionPeriodInDays" [disabled]="isLoading">
                                <option value="30">30 days</option>
                                <option value="45">45 days</option>
                                <option value="60">60 days</option>
                                <option value="75">75 days</option>
                                <option value="90">90 days</option>
                            </select>
                        </clr-select-container>
                        <p class="mt-1 mb-3">This configures how long packages should be kept in the recycle bin before
                            being deleted
                            permanently.
                        </p>
                    </form>
                </div>
                <div>
                    <clr-datagrid *ngIf="versionCleanupStatuses.length > 0" class="h-full"
                        [clrDgLoading]="isLoadingPreview">
                        <clr-dg-column [clrDgField]="'actionVersion.action.name'">Package</clr-dg-column>
                        <clr-dg-column>Version</clr-dg-column>
                        <clr-dg-column [clrDgField]="'cleanupStatus'">Change</clr-dg-column>
                        <clr-dg-column [clrDgField]="'permanentDeletionInDays'">Deletion In</clr-dg-column>

                        <clr-dg-row *clrDgItems="let change of versionCleanupStatuses">
                            <clr-dg-cell>{{change.actionVersion.action.name}}</clr-dg-cell>
                            <clr-dg-cell>{{change.actionVersion.version}}</clr-dg-cell>
                            <clr-dg-cell>{{change.cleanupStatus == 0 ? 'Moved to Recycle bin' : 'Already
                                Recycled'}}</clr-dg-cell>
                            <clr-dg-cell *ngIf="change.permanentDeletionInDays > 0">{{change.permanentDeletionInDays}}
                                day(s)
                                <cds-icon *ngIf="change.permanentDeletionInDays < 7" shape="warning-standard"
                                    status="warning"></cds-icon>
                            </clr-dg-cell>
                            <clr-dg-cell *ngIf="change.permanentDeletionInDays <= 0">Immediate <cds-icon
                                    shape="warning-standard" status="danger"></cds-icon></clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{
                                pagination.totalItems
                                }} Changes
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
            </div>
        </div>
        <div class="actions mt-3">
            <button [disabled]="isLoading" (click)="getWhatif()" type="button" class="btn btn-icon btn-md"
                #tooltip="matTooltip" matTooltip="Preview changes" [matTooltipPosition]="'above'"
                matTooltipHideDelay="300">
                <clr-icon shape="eye"></clr-icon>
                Preview Changes
            </button>
            <button [disabled]="isLoading" (click)="saveChanges()" type="button" class="btn btn-icon btn-success btn-md"
                #tooltip="matTooltip" matTooltip="Save changes to configuration" [matTooltipPosition]="'above'"
                matTooltipHideDelay="300">
                <clr-icon shape="check"></clr-icon>
                Save Changes
            </button>
        </div>
    </div>
</div>