import { Component, Input, OnInit } from '@angular/core';
import { IEndpointGrouping } from 'src/app/domain/interfaces/endpoint-grouping';

@Component({
  selector: 'nd-group-badge',
  templateUrl: './group-badge.component.html',
  styleUrls: ['./group-badge.component.scss'],
})
export class GroupBadgeComponent {
  @Input() groups: IEndpointGrouping[];
}
