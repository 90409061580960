import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGroupActions } from '../domain/interfaces/igroupactions';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { GroupAssignmentItem } from '../domain/interfaces/group-assignment-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupactionsService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  GetGroupActions() {
    return this.http.get<IGroupActions>(`${this.URL}/EndpointGroupAction`);
  }

  GetGroupActionsByID(id) {
    return this.http.get<IGroupActions>(
      `${this.URL}/EndpointGroupAction/${id}`
    );
  }
  GetEndpointGroupActionsByEndpointGroupId(id) {
    return this.http.get<IGroupActions[]>(
      `${this.URL}/EndpointGroupAction/endpointGroup/${id}`
    );
  }

  PutGroupActions(data, groupId, shouldDeployImmediately) {
    return this.http.put<IGroupActions>(
      `${this.URL}/EndpointGroupAction/actions/${groupId}?immediateDeployment=${shouldDeployImmediately}`,
      data
    );
  }

  PostGroupActions(data, groupId) {
    return this.http.put<IGroupActions>(
      `${this.URL}/EndpointGroupAction/${groupId}`,
      data
    );
  }

  RemoveSingleGroupActionByID(id) {
    return this.http.delete<IGroupActions>(
      `${this.URL}/EndpointGroupAction/${id}`
    );
  }

  UpdateGroupActionStatus(groupActionId, statusId) {
    return this.http.put<IGroupActions>(
      `${this.URL}/EndpointGroupAction/status/${groupActionId}/${statusId}`,
      {}
    );
  }

  updateGroupAssignment(
    items: GroupAssignmentItem[],
    groupId: number,
    shouldDeployImmediately = false
  ): Observable<void> {
    return this.http.put<void>(
      `${this.URL}/EndpointGroupAction/v2/actions/${groupId}?immediateDeployment=${shouldDeployImmediately}`,
      items
    );
  }

  updateGroupActionOptionalStatus(
    groupAction: IGroupActions
  ): Observable<IGroupActions> {
    return this.http.put<IGroupActions>(
      `${this.URL}/EndpointGroupAction/updateOptionalStatus/${groupAction.id}`,
      groupAction.isOptional ?? false
    );
  }
}
