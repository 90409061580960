<p>
    Create a new customer account in just six simple steps. Begin by filling the customer's Subscription Information,
    General Information and NuGet Information.
</p>
<p>This guided, step-by-step process will enable you to seamlessly create a new customer.</p>
<p>Click 'Continue' to initiate the process of creating a new customer account.</p>
<p>
    <strong>NOTE:</strong>
    Please
    <a target="_blank" href="https://dev.azure.com/{{devOpsOrganizationName}}/_usersSettings/tokens">
        <u>check here</u>
    </a> 
    to check and make sure that your Personal Access Token on Azure DevOps is not expired!
</p>