<mat-form-field class="chip-list">
    <mat-chip-list #chipGrid aria-label="Enter fruits">
        <mat-chip class="chip" *ngFor="let selection of selections" (removed)="remove(selection)" [editable]="true"
            (edited)="edit(selection, $event)" [aria-description]="'press enter to edit ' + selection.value">
            {{selection.value}}
            <cds-icon matChipRemove shape="times"></cds-icon>
        </mat-chip>
        <input [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)" />
    </mat-chip-list>
</mat-form-field>