<div class="clr-row mt-2">
    <div class="clr-col">
        <div class="btn-group">
            <div *ngFor="let day of days" class="checkbox btn">
                <input [value]="day" [checked]="pickedDays.includes(day)" (change)="dayChecked($event)" type="checkbox" id="btn-check-{{day}}">
                <label for="btn-check-{{day}}">{{day}}</label>
            </div>
        </div>
    </div>
</div>

<div class="clr-row mt-2">
    <div class="clr-col">
        <cds-time>
            <label>Time</label>
            <input [(ngModel)]="pickedTime" (change)="onTimeChanged()" type="time" />
            <cds-control-message>Update {{stringRepresentation}}.</cds-control-message>
        </cds-time>
    </div>
</div>