<nd-back-button></nd-back-button>

<clr-tabs>
  <clr-tab>
    <button clrTabLink id="link1">Package Logs</button>
    <clr-tab-content id="content1">
      <nd-package-logs></nd-package-logs>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>MSI Package Logs</button>
    <clr-tab-content>
      <nd-msi-package-logs endpointId="{{endpointId}}"></nd-msi-package-logs>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Endpoint Logs</button>
    <clr-tab-content>
      <nd-endpoint-logs endpointId="{{endpointId}}"></nd-endpoint-logs>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>