import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VersionDropEvent } from 'src/app/domain/interfaces/version-drop-event';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';

@Component({
  selector: 'nd-package-version-card',
  templateUrl: './package-version-card.component.html',
  styleUrls: ['./package-version-card.component.scss'],
})
export class PackageVersionCardComponent {
  @Input() version: ActionVersion;
  @Input() hasButton = true;
  @Output() dropEvent = new EventEmitter<VersionDropEvent>();
  @Output() moveEvent = new EventEmitter<ActionVersion>();

  drop(event: CdkDragDrop<ActionVersion>) {
    this.dropEvent.emit({
      version: event.item.data,
      fromId: event.previousContainer.id,
      toId: event.container.id,
    } as VersionDropEvent);
  }

  move() {
    this.moveEvent.emit(this.version);
  }
}
