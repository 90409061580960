import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  ClarityIcons,
  importIcon,
  fileGroupIcon,
  tasksIcon,
  syncIcon,
  timesIcon,
} from '@cds/core/icon';
import { CustomerBuilderComponent } from './components/customer-builder/customer-builder.component';
import { WelcomeStepComponent } from './components/welcome-step/welcome-step.component';
import { SubscriptionInformationStepComponent } from './components/subscription-information-step/subscription-information-step.component';
import { GeneralInformationStepComponent } from './components/general-information-step/general-information-step.component';
import { NugetInformationStepComponent } from './components/nuget-information-step/nuget-information-step.component';
import { SummaryStepComponent } from './components/summary-step/summary-step.component';
import { CreatingStepComponent } from './components/creating-step/creating-step.component';

ClarityIcons.addIcons(
  importIcon,
  fileGroupIcon,
  tasksIcon,
  syncIcon,
  timesIcon
);

@NgModule({
  declarations: [
    CustomerBuilderComponent,
    WelcomeStepComponent,
    SubscriptionInformationStepComponent,
    GeneralInformationStepComponent,
    NugetInformationStepComponent,
    SummaryStepComponent,
    CreatingStepComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatFormFieldModule,
  ],
})
export class CustomerBuilderModule {}
