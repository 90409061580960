<div *ngIf="isContentReady" id="last-fetch" class="d-flex align-items-center justify-content-end gap-3">
    <div class="">Last fetched at {{endpointLog.lastFetch+'Z' | date:'HH:mm, dd-MM-yyyy'}}</div>
    <button class="btn btn-outline" (click)="requestLogs()">
        <cds-icon shape="refresh"></cds-icon>
        Refetch
    </button>
</div>

<!-- error -->
<div *ngIf="error" class="alert alert-danger w-50 m-auto mb-2" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
            </div>
            <div class="alert-text">
                {{error}}
            </div>
        </div>
    </div>
</div>

<div class="card rounded-0 mt-2">
    <!-- fetch logs view -->
    <div class="d-flex flex-column align-items-center p-3" *ngIf="!isLoading && !isContentReady">
        <cds-icon shape="bubble-exclamation" class="icon" size="xxl"></cds-icon>
        <h4>There are no Endpoint Logs uploaded yet</h4>
        <button class="btn btn-primary" (click)="requestLogs()">
            <img src="../../../assets/icons/wait-document.svg" />
            Request endpoint logs
        </button>
    </div>

    <!-- loading -->
    <div class="d-flex flex-column align-items-center p-5" *ngIf="isLoading">
        <h4>Fetching your logs, please wait...</h4>
        <clr-progress-bar clrLoop class="w-50 mt-4"></clr-progress-bar>
    </div>

    <!-- logs -->
    <nd-tabbed-terminal *ngIf="isContentReady" [endpointId]="endpointId" [tabData]="tabs"></nd-tabbed-terminal>
</div>