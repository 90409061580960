import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IAssignmentAction } from 'src/app/domain/interfaces/iassignmentlist';
import { LogListItem } from 'src/app/domain/interfaces/log-list-item';
import { BlobService } from 'src/app/modules/package-builder/services/blob.service';
import { AssignmentActionService } from 'src/app/services/assignmentaction.service';
import { AssignmentService } from 'src/app/services/tasklist.service';

@Component({
  selector: 'nd-msi-package-logs',
  templateUrl: './msi-package-logs.component.html',
  styleUrls: ['./msi-package-logs.component.scss'],
})
export class MsiPackageLogsComponent implements OnInit {
  isLoadingActions = true;
  listItems: LogListItem[] = [];
  actions: IAssignmentAction[];
  logText = '';
  @Input()
  endpointId: number;

  constructor(
    private blobService: BlobService,
    private assignmentService: AssignmentService,
    private assignmentActionService: AssignmentActionService
  ) {}

  ngOnInit(): void {
    forkJoin({
      blobNames: this.blobService.enumerateLogsForEndpoint(this.endpointId),
      actions: this.assignmentService
        .GetAssignmentByEndpointID(this.endpointId)
        .pipe(
          switchMap((assignment) =>
            this.assignmentActionService.GetAssignmentActionByAssignmentID(
              assignment[0].id
            )
          )
        ),
    }).subscribe({
      next: (result) => {
        this.isLoadingActions = false;
        this.actions = result.actions;

        const msiNames = result.blobNames
          .filter((name) => name.endsWith('.msi.log'))
          .map((name) => name.replace('.msi.log', '').split('/')[1]);

        this.listItems = msiNames
          .map((name) => {
            var matchingAction = result.actions.find(
              (a) => a.action?.parameters === name
            );
            return {
              name: matchingAction?.action?.name,
              icon: matchingAction?.action?.actionVersion[0].iconUri,
            };
          })
          .filter((item) => item.name);

        if (!this.listItems.length)
          this.logText = '[!] No MSI logs for Endpoint available.';
      },
      error: (error) => {
        console.error(error);
        this.logText = '[!] Failed to load MSI logs.';
        this.isLoadingActions = false;
      },
    });
  }

  onItemSelected(name: string): void {
    this.logText = 'Loading...';

    var parameters = this.actions.find((a) => a.action.name === name).action
      .parameters;

    this.blobService
      .getLog(this.endpointId, `${parameters}.msi.log`)
      .subscribe({
        next: (log) => (this.logText = log),
        error: (_) => (this.logText = '[!] Failed to load MSI log.'),
      });
  }
}
