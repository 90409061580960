<div class="card table-container-card">
  <div class="table-heading">
    <div class="table-title">
      <cds-icon shape="devices" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
      <h3>Groups</h3>
    </div>
    <div class="action-group">
      <button type="button" class="btn btn-icon btn-success btn-md" (click)="addNewGroupVisible = true"
        #tooltip="matTooltip" matTooltip="Add new Group" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../../assets/icons/plus.svg" />
      </button>
      <button [disabled]="selectedEndpointGroups.length!=1" class="action-item" class="btn btn-icon btn-primary  btn-md"
        #tooltip="matTooltip" matTooltip="Rename Group" [matTooltipPosition]="'above'" matTooltipHideDelay="100"
        (click)="showRenameGroupModal(selectedEndpointGroups)">
        <img src="../../../../assets/icons/edit.svg" />
      </button>
      <button [disabled]="selectedEndpointGroups.length === 0" class="action-item"
        class="btn btn-icon btn-danger  btn-md" (click)="openDeleteModal = true" #tooltip="matTooltip"
        matTooltip="Delete Group" [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../../assets/icons/trash.svg" />
      </button>
      <button [disabled]="1>selectedEndpointGroups.length" type="button" class="btn btn-icon btn-primary btn-md"
        (click)="addTagsToGroup = true" #tooltip="matTooltip" matTooltip="Add Tags to Group"
        [matTooltipPosition]="'above'" matTooltipHideDelay="100">
        <img src="../../../../assets/icons/tag.svg" />
      </button>
    </div>
  </div>
  <clr-datagrid class="datagrid-compact" [clrDgSelected]="selectedEndpointGroups" [clrDgLoading]="isLoadingGroups"
    (clrDgSelectedChange)="groupSelectionChanged($event)" id="my-datagrid" style="margin-top: 0px !important; ">
    <clr-dg-column [clrDgField]="'name'" [style.width.px]="200">Groups</clr-dg-column>
    <clr-dg-column [clrDgField]="'id'" [style.width.px]="20">ID</clr-dg-column>
    <clr-dg-column [clrDgField]="'priority'" [style.width.px]="20">Priority</clr-dg-column>
    <clr-dg-column>Lifecycle</clr-dg-column>
    <clr-dg-row *clrDgItems="let data of groups" [clrDgItem]="data">
      <clr-dg-cell [contextMenu]="itemMenu" [contextMenuSubject]="data" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">{{data.name}}</clr-dg-cell>
      <clr-dg-cell [contextMenu]="itemMenu" [contextMenuSubject]="data" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">{{data.id}}</clr-dg-cell>
      <clr-dg-cell [contextMenu]="itemMenu" [contextMenuSubject]="data" #refEl (click)="clickName(refEl)"
        style="cursor: pointer;">{{data.priority}}</clr-dg-cell>
      <clr-dg-cell #refEl style="cursor: pointer;">
        <nd-lifecycle-badge [tag]="data.endpointGroupTag[0]?.tag"></nd-lifecycle-badge>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="100">
        <clr-dg-page-size [clrPageSizeOptions]="[15,30,45,60,75,90,100]">Groups per page</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} groups
      </clr-dg-pagination>
    </clr-dg-footer>
    <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
      <clr-dg-detail>
        <clr-dg-detail-header>
          <div style="display: flex; justify-content: space-between;">
            <div>
              <span>{{detail.name}} Group</span><br />
              <small>ID: {{detail.id}}</small>
              <div class="priority">
                <small>
                  <p>Priority: </p>
                  <input clrInput max="1000" min="0" type="number" [(ngModel)]="detail.priority"
                    (change)="updateGroup(detailState)" [disabled]="isUpdatingPriority" />
                  <span *ngIf="isUpdatingPriority">
                    <clr-spinner clrInline>Updating Assignments</clr-spinner>
                    <span>Updating Assignments</span>
                  </span>
                </small>
              </div>
            </div>
            <button class="btn btn-link" aria-label="close details" (click)="closeDetail()">
              <cds-icon shape="window-close" size="md" style="--color: var(--clr-p1-color)"></cds-icon>
            </button>
          </div>
          <div class="mt-2">
            <div class="clr-row my-6">
              <div class="clr-col-12">
                <clr-toggle-container style="margin-top: 0.15rem !important;">
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="options" required value="option1"
                      [(ngModel)]="updateScheduleEnabled" (change)="ToggleUpdateSchedule(updateScheduleEnabled)" />
                    <label>Update on schedule</label>
                  </clr-toggle-wrapper>
                </clr-toggle-container>
              </div>
              <div *ngIf="updateScheduleEnabled" class="clr-row my-6">
                <div class="clr-col-12">
                  <app-cron-edit (cronChanged)="onCronChanged($event)" [cron]="detail.updateCron"></app-cron-edit>
                </div>
              </div>
            </div>
          </div>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-tabs>
            <clr-tab>
              <button clrTabLink>Endpoints</button>
              <clr-tab-content *clrIfActive="true">
                <div class="btn-group btn-icon" style="margin-top: 10px; margin-bottom: 10px;">
                  <button type="button" class="btn btn-icon btn-primary btn-md" (click)="GetEndpointData(detail)"
                    #tooltip="matTooltip" matTooltip="Add Endpoints to Group" [matTooltipPosition]="'above'"
                    matTooltipHideDelay="100">
                    <clr-icon shape="file-settings"></clr-icon>
                  </button>
                  <button type="button" class="btn btn-icon btn-danger btn-md"
                    [disabled]="selectedEndpoints.length === 0" #tooltip="matTooltip"
                    matTooltip="Delete Endpoints from Group" [matTooltipPosition]="'above'" matTooltipHideDelay="100"
                    (click)="DeleteEndpointsFromGroup(detail.id)">
                    <clr-icon shape="trash"></clr-icon>
                  </button>
                </div>
                <clr-datagrid class="datagrid-compact" id="my-datagrid" [clrDgSelected]="selectedEndpoints"
                  (clrDgSelectedChange)="endpointSelectionChanged($event)">
                  <clr-dg-column>Name</clr-dg-column>
                  <clr-dg-row *clrDgItems="let endpoint of groupEndpoints" [clrDgItem]="endpoint">
                    <clr-dg-cell>{{endpoint.name}}</clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationEP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Endpoints per page</clr-dg-page-size>
                      {{paginationEP.firstItem + 1}} - {{paginationEP.lastItem + 1}} of {{paginationEP.totalItems}}
                      Endpoints
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Packages</button>
              <clr-tab-content>
                <div class="btn-group btn-icon" style="margin-top: 10px; margin-bottom: 10px;">
                  <button (click)="RouteGroupDetails(detail.id)" type="button" class="btn btn-icon btn-success btn-md"
                    #tooltip="matTooltip" matTooltip="Edit Group Actions" [matTooltipPosition]="'above'"
                    matTooltipHideDelay="100">
                    <clr-icon shape="note"></clr-icon>
                  </button>
                </div>

                <clr-datagrid class="datagrid-compact" id="my-datagrid">
                  <clr-dg-column>Name</clr-dg-column>
                  <clr-dg-column>Optional</clr-dg-column>
                  <clr-dg-row *clrDgItems="let action of groupActions" [clrDgItem]="actions">
                    <clr-dg-cell>{{action.action.name}}</clr-dg-cell>
                    <clr-dg-cell>
                      <input type="checkbox" clrCheckbox (change)="updateGroupActionOptionalStatus(action)" [(ngModel)]="action.isOptional"  />
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationEP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Packages per page</clr-dg-page-size>
                      {{paginationEP.firstItem + 1}} - {{paginationEP.lastItem + 1}} of {{paginationEP.totalItems}}
                      Packages
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
            <clr-tab>
              <button clrTabLink>Blueprints</button>
              <clr-tab-content>
                <div class="btn-group btn-icon" style="margin-top: 10px; margin-bottom: 10px;">
                  <button (click)="RouteGroupDetails(detail.id)" type="button" class="btn btn-icon btn-success btn-md"
                    #tooltip="matTooltip" matTooltip="Edit Group Actions" [matTooltipPosition]="'above'"
                    matTooltipHideDelay="100">
                    <clr-icon shape="note"></clr-icon>
                  </button>
                </div>

                <clr-datagrid class="datagrid-compact" id="my-datagrid">
                  <clr-dg-column>Name</clr-dg-column>
                  <clr-dg-row *clrDgItems="let blueprint of blueprints" [clrDgItem]="actions">
                    <clr-dg-cell>{{blueprint.name}}</clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #paginationEP [clrDgPageSize]="15">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,15]">Blueprints per page</clr-dg-page-size>
                      {{paginationEP.firstItem + 1}} - {{paginationEP.lastItem + 1}} of {{paginationEP.totalItems}}
                      Packages
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </clr-datagrid>
</div>
<clr-modal [(clrModalOpen)]="addNewGroupVisible">
  <h3 class="modal-title">New Group</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Group Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="newGroupName" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelNewGroup()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkNewGroup()">Ok</button>
  </div>
</clr-modal>
<clr-modal clrModalSize="lg" [(clrModalOpen)]="addEndpointsToGroup">
  <h3 class="modal-title" *ngIf="selectedEndpointGroups">Add Endpoints to Group {{selectedGroupName}}</h3>
  <div class="modal-body">
    <div class="all-heading" style="border-bottom: none; margin-bottom: 0px !important;">
      <div style="display:flex; justify-content: space-between;">
        <span>Endpoints</span>
        <div></div>
      </div>
    </div>
    <clr-datagrid *ngIf="addEndpointsToGroup" class="datagrid-compact" [clrDgSelected]="selectedEndpoints"
      (clrDgSelectedChange)="endpointSelectionChanged($event)">
      <clr-dg-column [clrDgField]="'name'">Endpoints</clr-dg-column>
      <clr-dg-row *clrDgItems="let endpoint of endpoints" [clrDgItem]="endpoint">
        <clr-dg-cell #refEl (click)="clickName(refEl)" style="cursor: pointer;">{{endpoint.name}}</clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-pagination #paginationAbc [clrDgPageSize]="10">
          <clr-dg-page-size [clrPageSizeOptions]="[10]">Endpoint per page</clr-dg-page-size>
          {{paginationAbc.firstItem + 1}} - {{paginationAbc.lastItem + 1}} of {{paginationAbc.totalItems}} Endpoints
        </clr-dg-pagination>

      </clr-dg-footer>

    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="AddEndpointsToGroup()">Done</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="openDeleteModal">
  <h3 class="modal-title">Delete Groups?</h3>
  <div class="modal-body">
    <p>Are you sure?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="openDeleteModal = false">No</button>
    <button type="button" class="btn btn-primary" (click)="DeleteGroups(selectedEndpointGroups)">Yes</button>
  </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="renameGroupVisible">
  <h3 class="modal-title">Rename Group</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Group Name</label>
        <input clrInput placeholder="Enter Name here!" name="name" [(ngModel)]="renameGroupName" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCancelRenameGroup()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="handleOkRenameGroup()">Ok</button>
  </div>
</clr-modal>
<nd-add-tags-modal [title]="'Add Tags To Group'" [(modalIsVisible)]="addTagsToGroup" [systemTags]="systemTags"
  [userTags]="tags" (selectedTagsEmitter)="handleOkTagModal($event)"
  (closeAddTagsModalEmitter)="handleCancelTagModal()">
</nd-add-tags-modal>
<context-menu #itemMenu>
  <ng-template contextMenuItem let-item (execute)="RouteGroupDetails($event.item.id)">
    <p style="margin: 0px 10px 0px 10px;">Edit {{item.name}} Actions</p>
    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
  </ng-template>
  <ng-template contextMenuItem let-item (execute)="GetEndpointData($event.item)">
    <p style="margin: 0px 10px 0px 10px;">Add Endpoints to {{item.name}}</p>
  </ng-template>
</context-menu>
<app-notify-me #cmp></app-notify-me>