import { Component, Input } from '@angular/core';
import { IconShapeTuple } from '@cds/core/icon/interfaces/icon.interfaces';

@Component({
  selector: 'msp-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  @Input() subheader: string;
  @Input() header: string;
  @Input() icon: IconShapeTuple;
  @Input() badge: string = '';
  @Input() badgeColor: string;

  constructor() {}
}
