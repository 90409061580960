<clr-datagrid [clrDgLoading]="dataIsLoading">
  <clr-dg-column>Package</clr-dg-column>
  <clr-dg-column>Version</clr-dg-column>
  <clr-dg-column *ngIf="!mspDashboard">Lifecycle</clr-dg-column>
  <clr-dg-column *ngIf="mspDashboard">Stage</clr-dg-column>
  <clr-dg-column>Modified On</clr-dg-column>

  <clr-dg-row *ngFor="let item of actionVersions; let i = index">
    <clr-dg-cell>{{ item.actionName }}</clr-dg-cell>
    <clr-dg-cell>{{ item.version }}</clr-dg-cell>
    <clr-dg-cell *ngIf="!mspDashboard">{{ item.tag.name }}</clr-dg-cell>
    <clr-dg-cell *ngIf="mspDashboard">{{ item.stage | enumKeyToStringValue: stagesEnum }}</clr-dg-cell>
    <clr-dg-cell>
      {{ item.lastModified === "0001-01-01T00:00:00" ? 'Unknown' : item.lastModified + 'Z' | date:'dd-MM-yyyy HH:mm' }}
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #lastModifiedTablePagination [(clrDgPage)]="currentPage" [clrDgPageSize]="itemsPerPage"
      [clrDgTotalItems]="actionVersionsCount" (clrDgPageChange)="onChangePage($event)">
      <clr-dg-page-size [(clrPageSizeOptions)]="pageSizeOptions">Items per page</clr-dg-page-size>
      {{lastModifiedTablePagination.firstItem + 1}} - {{lastModifiedTablePagination.lastItem + 1}}
      of {{lastModifiedTablePagination.totalItems}}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>