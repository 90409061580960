import { Component, OnInit } from '@angular/core';
import { Stage } from 'src/app/modules/shared/domain/enums/stage';
import { Tenant } from 'src/app/modules/shared/domain/tenant';
import { TenantService } from 'src/app/modules/shared/services/tenant.service';

@Component({
  selector: 'msp-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss'],
})
export class CustomersTableComponent implements OnInit {
  customers: Tenant[];
  stageEnum = Stage;

  constructor(private tenantService: TenantService) {}

  ngOnInit(): void {
    this.tenantService.getAllTenants().subscribe({
      next: (tenants) => {
        this.customers = tenants;
      },
    });
  }

  onTenantStageUpdate(stage: Stage, tenantId: number): void {
    this.tenantService.setTenantStage(tenantId, stage).subscribe();
  }
}
