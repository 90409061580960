import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Action } from 'src/app/modules/shared/domain/action';
import { VersionDropEvent } from 'src/app/domain/interfaces/version-drop-event';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';

@Component({
  selector: 'nd-expandable-package-item',
  templateUrl: './expandable-package-item.component.html',
  styleUrls: ['./expandable-package-item.component.scss'],
})
export class ExpandablePackageItemComponent {
  @Input() action: Action;
  @Input() hasButtons = true;
  @Input() moveAllButtonText: string;
  @Output() dropEvent = new EventEmitter<VersionDropEvent>();
  @Output() moveEvent = new EventEmitter<ActionVersion[]>();
  expanded = false;

  onToggleExpand() {
    this.expanded = !this.expanded;
  }

  drop(event: VersionDropEvent) {
    this.dropEvent.emit(event);
  }

  move(versions: ActionVersion[]) {
    this.moveEvent.emit(versions);
  }
}
