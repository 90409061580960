import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  error = '';
  loading = false;
  submitted = false;
  isIframe = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    document.documentElement.style.setProperty('--content-area-padding', '0px');

    this.isIframe = window !== window.parent && !window.opener;

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    document.documentElement.style.setProperty(
      '--content-area-padding',
      '10px'
    );
  }

  onLogInWithAzure(): void {
    this.authenticationService.logInWithAzure().subscribe({
      next: (_) => {
        this.router.navigate(['/dashboard']);
      },
      error: (error) => console.error(error),
    });
  }
}
