import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HostService } from 'src/app/services/host.service';
import { IEndpointGrouping } from 'src/app/domain/interfaces/endpoint-grouping';
import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';

@Component({
  selector: 'nd-schedule-picking-tab',
  templateUrl: './schedule-picking-tab.component.html',
  styleUrls: ['./schedule-picking-tab.component.scss'],
})
export class SchedulePickingTabComponent implements OnChanges {
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;
  @Input() endpoint: IEndPoint;
  selectedGroupId?: string;
  endpointScheduleEnabled = false;

  constructor(private endpointService: HostService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.endpoint) {
      this.endpointScheduleEnabled = !!changes.endpoint.currentValue.updateCron;
      if (this.endpointScheduleEnabled) this.selectedGroupId = '-1';
      else this.selectedGroupId = String(this.endpoint.selectedScheduleGroupId);
    }
  }

  filterGroupingsThatHaveSchedules(grouping: IEndpointGrouping) {
    return !!grouping.endpointGroup?.updateCron;
  }

  onChooseSchedule(groupId: string) {
    if (this.selectedGroupId == groupId) {
      this.selectedGroupId = '';
      this.endpoint.updateCron = '';
      this.endpoint.selectedScheduleGroupId = null;
    } else if (groupId !== '-1') {
      this.endpoint.updateCron = '';
      this.endpoint.selectedScheduleGroupId = +groupId;
    } else {
      this.endpoint.updateCron = '0 0 12 ? * *';
      this.endpoint.selectedScheduleGroupId = null;
    }
    this.endpointScheduleEnabled = !!this.endpoint.updateCron;
    this.onCronChanged(this.endpoint.updateCron);
  }

  onCronChanged(cron: string) {
    this.endpoint.updateCron = cron;
    this.updateEndpoint();
  }

  updateEndpoint() {
    this.endpointService.UpdateSingleEndpoint(this.endpoint).subscribe({
      error: (err) => {
        console.log(err);
        this.notifyMe.showNotification({
          defaultNotificationText:
            'An error occurred while attempting to change the schedule.',
          standardStatus: 'danger',
          notificationShow: true,
        });
      },
    });
  }
}
