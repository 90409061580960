import { Component, OnInit } from '@angular/core';
import { AutoLifecycleConfiguration } from 'src/app/modules/shared/domain/auto-lifecycle-configration';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';

@Component({
  selector: 'nd-configure-package-lifecycle',
  templateUrl: './configure-package-lifecycle.component.html',
  styleUrls: ['./configure-package-lifecycle.component.scss'],
})
export class ConfigurePackageLifecycleComponent implements OnInit {
  isLoading = true;
  configuration: AutoLifecycleConfiguration = {
    id: 0,
    flowToAcceptanceInHours: null,
    flowToProductionInHours: null,
  };

  constructor(private actionVersionService: ActionversionService) {}

  ngOnInit(): void {
    this.actionVersionService.getGlobalAutoLifecycleConfiguration().subscribe({
      next: (value) => {
        this.configuration = value;
        this.isLoading = false;
      },
    });
  }

  saveChanges(): void {
    this.isLoading = true;
    this.actionVersionService
      .putGlobalAutoLifecycleConfiguration(this.configuration)
      .subscribe({
        next: () => {
          this.isLoading = false;
        },
        error(err) {
          console.error(err);
        },
      });
  }
}
