<div [hidden]="isDone || isError" class="d-flex align-items-center gap-2 w-100 wrapper">
    <img [attr.src]="imageUri ? imageUri : 'assets/nimdeploy-logo-shadow.svg'" class="package-icon" />
    <div class="d-flex flex-column gap-1 w-100">
        <h4 class="m-0">{{packageName}}</h4>
        <div class="progress mt-1" *ngIf="!isDone">
            <clr-progress-bar clrLoop></clr-progress-bar>
        </div>
        <p class="m-0">{{message}}</p>
    </div>
</div>

<div *ngIf="isDone" id="success">
    <cds-icon shape="check" id="checkmark" size="xxl"></cds-icon>
    <h5>The package has been successfully created!</h5>
</div>

<div *ngIf="isError" id="error">
    <cds-icon shape="error-standard" id="error-standard" size="xxl"></cds-icon>
    <h5>Something went wrong, your package has not been created!</h5>
</div>