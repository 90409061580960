import { Component, OnInit } from '@angular/core';
import { PackageCleanupConfiguration } from '../../models/package-cleanup-configuration';
import { PackageCleanupService } from '../../services/package-cleanup.service';
import { ActionService } from 'src/app/modules/shared/services/action.service';
import { ActionversionService } from 'src/app/modules/shared/services/actionversion.service';
import { ActionDto } from 'src/app/modules/shared/domain/action-dto';
import { ActionVersion } from 'src/app/modules/shared/domain/action-version';
import { RecycledActionVersion } from '../../models/recycled-action-version';
import { Router } from '@angular/router';

@Component({
  selector: 'nd-recycle-bin',
  templateUrl: './recycle-bin.component.html',
  styleUrls: ['./recycle-bin.component.scss'],
})
export class RecycleBinComponent implements OnInit {
  isLoadingPackages = true;
  versionsAreLoading = true;
  actions: ActionDto[] = [];
  selectedActions: ActionDto[] = [];
  actionVersions: RecycledActionVersion[] = [];
  restoredVersionIds: number[] = [];
  detailState?: ActionDto = null;
  configuration: PackageCleanupConfiguration;
  confirmModalOpen = false;
  isMspDashboard = false;

  constructor(
    private packageCleanupConfiguration: PackageCleanupService,
    private actionService: ActionService,
    private actionVersionService: ActionversionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoadingPackages = true;

    this.isMspDashboard = this.router.url.includes('/msp/');

    this.packageCleanupConfiguration
      .getPackageCleanupConfiguration()
      .subscribe({
        next: (config) => (this.configuration = config),
        error: (err) => console.error(err),
      });

    this.actionService.getRecycledActionDtos().subscribe({
      next: (actions) => (this.actions = actions),
      error: (err) => console.error(err),
      complete: () => (this.isLoadingPackages = false),
    });
  }

  clickRow(element: any): void {
    element._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  onDetailOpen(selectedAction: ActionDto): void {
    this.versionsAreLoading = true;
    this.actionVersions = [];
    this.restoredVersionIds = [];
    this.actionVersionService
      .getRecycledActionVersionByActionID(selectedAction.id)
      .subscribe({
        next: (value) => {
          this.actionVersions = value.map((v) => ({
            actionVersion: v,
            daysRemaining: this.getDaysRemaining(v),
          }));
        },
        error: (error) => console.error(error),
        complete: () => (this.versionsAreLoading = false),
      });
  }

  closeDetail(): void {
    this.detailState = null;
  }

  onRestore(version: ActionVersion): void {
    this.restoredVersionIds = [...this.restoredVersionIds, version.id];
    const action = this.actions.find((a) => a.id === version.actionId);
    action.versionCount--;

    if (!action.versionCount) {
      this.actions = this.actions.filter((a) => a.id !== action.id);
    }

    this.actionVersionService
      .restoreActionVersionFromRecycleBin(version.id)
      .subscribe({ error: (error) => console.error(error) });
  }

  restoreSelectedPackages(): void {
    console.log(this.selectedActions);
    const selectedActionVersionIds = this.selectedActions.map((a) => a.id);

    this.actions = this.actions.filter(
      (a) => !this.selectedActions.includes(a)
    );

    this.selectedActions = [];

    this.actionService
      .restoreAllActionVersionsForActionIds(selectedActionVersionIds)
      .subscribe({ error: (error) => console.error(error) });
  }

  isRestored(version: ActionVersion): boolean {
    return !!this.restoredVersionIds.find((id) => id === version.id);
  }

  getDaysRemaining(version: ActionVersion): number {
    const deletedAt = new Date(version.deletedAt);

    const millisecondsInDay = 24 * 60 * 60 * 1000;

    const permanentDeletionAt = new Date(
      deletedAt.getTime() +
        millisecondsInDay * this.configuration.recycleBinRetentionPeriodInDays
    );

    const millisecondsRemaining =
      permanentDeletionAt.getTime() - new Date().getTime();

    const daysRemaining = Math.floor(millisecondsRemaining / millisecondsInDay);

    console.log({
      deletedAt: deletedAt.getTime(),
      permanentDeletionAt: permanentDeletionAt.getTime(),
      millisecondsRemaining,
      millisecondsInDay,
      daysRemaining,
    });

    return daysRemaining ? daysRemaining : 0;
  }
}
