import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { ClarityModule } from '@clr/angular';
import { CdsModule } from '@cds/angular';
import { PackageSubscriptionStatusTableComponent } from './components/package-subscription-status-table/package-subscription-status-table.component';
import { RouterModule } from '@angular/router';
import { EnumToListPipe } from './pipes/enum-to-list.pipe';
import { LastModifiedPackagesComponent } from './components/last-modified-packages/last-modified-packages.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EnumKeyToStringValue } from './pipes/enum-key-to-string-value.pipe';
import { BackButtonComponent } from './components/back-button/back-button.component';

@NgModule({
  declarations: [
    FilterPipe,
    PackageSubscriptionStatusTableComponent,
    EnumToListPipe,
    LastModifiedPackagesComponent,
    EnumKeyToStringValue,
    BackButtonComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    CdsModule,
    RouterModule,
    MatTooltipModule,
  ],
  exports: [
    CommonModule,
    ClarityModule,
    CdsModule,
    RouterModule,
    FilterPipe,
    PackageSubscriptionStatusTableComponent,
    EnumToListPipe,
    LastModifiedPackagesComponent,
    BackButtonComponent,
  ],
})
export class SharedModule {}
