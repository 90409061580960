<div class="row h-100">
    <div class="col-lg-5 col" id="command-list-column">
        <b>Available helper functions</b>
        <input class="form-control form-control-sm" id="search-bar" type="text" placeholder="Search functions..."
            [(ngModel)]="query" />
        <div cdkDropList class="remove-list" #removeList="cdkDropList" (cdkDropListDropped)="remove($event)">
            <pb-command-spec-item *ngFor="let command of (availableCommands | filter: filterCallback )"
                [commandSpecification]="command" (onAdd)="addCommand($event)"></pb-command-spec-item>
        </div>
    </div>
    <div class="col-lg-7 col">
        <b>Sequence</b>
        <div cdkDropList class="sequence-list" [cdkDropListData]="selectedCommands"
            [cdkDropListConnectedTo]="[removeList]" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let command of selectedCommands; let i = index" class="d-flex align-items-start">
                <pb-expandable-command-item [command]="command" [filePaths]="filePaths" [defaultValues]="defaultValues" class="w-100"></pb-expandable-command-item>
                <cds-icon shape="trash" (click)="delete(i)" size="md" class="ms-1 trash-icon"></cds-icon>
            </div>
        </div>
    </div>
</div>