import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { ContextMenuComponent } from 'ngx-contextmenu';

import { IEndPoint } from 'src/app/domain/interfaces/iendpoint';
import { IEndPointGroup } from 'src/app/domain/interfaces/iendpointgroup';
import { ITag, TagType } from 'src/app/modules/shared/domain/tag';
import { LocalStorageManager } from 'src/app/utils/LocalStorageManager';
import { GroupactionsService } from 'src/app/services/groupactions.service';
import { GroupsService } from 'src/app/services/groups.service';
import { HostService } from 'src/app/services/host.service';
import { TagService } from 'src/app/services/tag.service';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { IBluePrint } from 'src/app/domain/interfaces/iblueprint';
import { IGroupActions } from 'src/app/domain/interfaces/igroupactions';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  @ViewChild(ContextMenuComponent) public itemMenu: ContextMenuComponent;
  @ViewChild(ContextMenuComponent) public tableMenu: ContextMenuComponent;
  @ViewChild('cmp', { static: false }) notifyMe: NotifyMeComponent;
  $groupChangeSubject = new Subject<IEndPointGroup>();
  $optionalStatusChangeSubject = new Subject<IGroupActions>();

  // For Groups
  selectedEndpointGroups: IEndPointGroup[] = [];

  // For Endpoints in a Group
  selectedEndpoints: IEndPoint[] = [];

  groups: IEndPointGroup[];
  detailState: IEndPointGroup;
  addNewGroupVisible: boolean;
  openDeleteModal: boolean;
  addEndpointsToGroup: boolean = false;

  endpoints: IEndPoint[];
  groupEndpoints: IEndPoint[];
  systemTags: ITag[] = [];
  tags: ITag[];
  updateScheduleEnabled: boolean = false;
  selectedEndpoint: IEndPoint;

  groupActions;
  selectedGroupName: string = '';
  selectedGroupId: number = -1;

  newGroupName: string = '';

  // Group Rename Modal
  renameGroupName: string;
  renameGroupVisible: boolean;
  currentGroupViewed: number;

  // Add Tags to Group
  addTagsToGroup: boolean = false;

  isLoadingGroups = true;
  isUpdatingPriority = false;

  blueprints: IBluePrint[];

  constructor(
    private groupService: GroupsService,
    private eSvc: HostService,
    private router: Router,
    private tagService: TagService,
    private groupActionsService: GroupactionsService
  ) {}

  ngOnInit(): void {
    this.GetGroups();
    this.GetEndPoints();
    this.GetTags();

    this.$groupChangeSubject
      .pipe(
        debounceTime(500),
        tap((_) => (this.isUpdatingPriority = true)),
        switchMap((group) => this.groupService.UpdateSingleGroup(group))
      )
      .subscribe({
        next: (_) => {
          this.isUpdatingPriority = false;
        },
        error: (_) => {
          this.isUpdatingPriority = false;
        },
      });

    this.$optionalStatusChangeSubject
      .pipe(
        debounceTime(100),
        switchMap((action) =>
          this.groupActionsService.updateGroupActionOptionalStatus(action)
        )
      )
      .subscribe({ error: (err) => console.error(err) });
  }

  updateGroup(group: IEndPointGroup): void {
    if (group.priority > 1000) {
      group.priority = 1000;
    } else if (group.priority < 0) {
      group.priority = 0;
    }

    this.$groupChangeSubject.next(group);
  }

  updateGroupActionOptionalStatus(action: IGroupActions): void {
    this.$optionalStatusChangeSubject.next(action);
  }

  RouteGroupDetails(groupId: number) {
    this.router.navigate(['/groupdetails', groupId]);
    LocalStorageManager.AddCurrAssignment({ isGroup: true, groupId: groupId });
  }

  // ### Handlers ###
  handleOkNewGroup(): void {
    this.PostGroup(this.newGroupName);
    this.newGroupName = '';
    this.addNewGroupVisible = false;
  }

  handleCancelNewGroup(): void {
    this.newGroupName = '';
    this.addNewGroupVisible = false;
  }

  onDetailOpen(e: IEndPointGroup) {
    if (e != null) {
      this.selectedGroupId = e.id;
      this.groupEndpoints = this.GetGroupsfromEndpoints(e.id);
      this.updateScheduleEnabled = !!this.detailState.updateCron;
      this.GetGroupActionsByGroupID(e.id);
    }
  }

  groupSelectionChanged(elements: IEndPointGroup[]): void {
    this.selectedEndpointGroups = elements;
  }

  endpointSelectionChanged(elements: IEndPoint[]): void {
    this.selectedEndpoints = elements;
  }

  showRenameGroupModal(selectedGP): void {
    this.currentGroupViewed = selectedGP[0].id;
    //@ts-ignore
    this.renameGroupName = this.groups.filter(
      (curr) => curr.id === selectedGP[0].id
    )[0].name;
    this.renameGroupVisible = true;
  }

  handleOkRenameGroup(): void {
    const groupToRename = this.selectedEndpointGroups.find(
      (g) => g.id === this.currentGroupViewed
    );
    if (!groupToRename) return;
    groupToRename.name = this.renameGroupName;
    this.groupService.UpdateSingleGroup(groupToRename).subscribe();
    this.renameGroupName = '';
    this.renameGroupVisible = false;
  }

  handleCancelRenameGroup(): void {
    this.renameGroupName = '';
    this.renameGroupVisible = false;
  }

  handleCancelTagModal() {
    this.addTagsToGroup = false;
  }

  // ### Service calls ###
  async handleOkTagModal(newTags: ITag[]) {
    this.selectedEndpointGroups.forEach((element: IEndPointGroup) => {
      element.endpointGroupTag = newTags.map((tag) => {
        return { tag: tag };
      });
      this.groupService.AddTagToGroupByGroupID(element.id, newTags).subscribe();
    });
    this.addTagsToGroup = false;
  }

  async GetGroups() {
    this.groupService.GetEndpointGroups().subscribe((v) => {
      this.groups = v;
      this.isLoadingGroups = false;
    });
  }

  async GetEndPoints() {
    this.eSvc.GetEndPoints().subscribe((v) => {
      this.endpoints = v;
    });
  }

  DeleteGroups(selected) {
    for (let index = 0; index < selected.length; index++) {
      this.groupService
        .RemoveSingleEndPointGroupByID(selected[index].id)
        .subscribe({
          next: (v) => {
            this.GetGroups();
            let notifyMessage: string;
            if (selected.length == 1) {
              notifyMessage = 'Your Group has been deleted.';
            } else {
              notifyMessage = 'Your Groups have been deleted.';
            }
            this.notify(notifyMessage, 'info');
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this.notify(err.error, 'danger');
            }
          },
        });
    }
    this.openDeleteModal = false;
  }

  DeleteEndpointsFromGroup(groupId: number): void {
    this.selectedEndpoints.forEach((item) => {
      // Exclude the group with id: 'groupId' from the endpoint's array of groups
      const filteredGroups = item.endpointGrouping.filter(
        (item: { endpointGroupId: number }) => item.endpointGroupId != groupId
      );
      item.endpointGrouping = filteredGroups;
      this.UpdateEndpoints(item);
    });
  }

  GetGroupActionsByGroupID(groupID) {
    this.groupActionsService
      .GetEndpointGroupActionsByEndpointGroupId(groupID)
      .subscribe((v) => {
        this.groupActions = v;
        this.blueprints = v
          .map((a) => a.blueprint)
          .filter((b) => b != null)
          .filter(
            (bp, i, array) => array.findIndex((bp2) => bp2.id === bp.id) === i
          );
      });
  }

  PostGroup(name) {
    this.groupService.PostSingleEndPointGroup(name).subscribe((v) => {
      this.GetGroups();
      this.addNewGroupVisible = false;
    });
  }

  GetTags() {
    this.tagService.GetTags().subscribe((tags) => {
      this.tags = tags.filter((tag) => tag.type == TagType.User);
      this.systemTags = tags.filter((tag) => tag.type == TagType.DeployStage);
    });
  }

  AddEndpointsToGroup() {
    this.selectedEndpoints.forEach((element) => {
      if (element.endpointGrouping.length < 1) {
        element.endpointGrouping[0] = {
          endpointId: element.id,
          endpointGroupId: this.selectedEndpoint.id as number,
        };
      } else {
        element.endpointGrouping.forEach((element) => {
          delete element.endpointGroup;
        });
        if (
          !element.endpointGrouping.find(
            (v) => v.endpointGroupId == this.selectedEndpoint.id
          )
        )
          element.endpointGrouping.push({
            endpointId: element.id,
            endpointGroupId: this.selectedEndpoint.id,
          });
      }
      this.UpdateEndpoints(element);
    });

    this.selectedEndpoints = [];
    this.addEndpointsToGroup = false;
  }

  UpdateEndpoints(data) {
    this.eSvc.UpdateSingleEndpointForGroups(data).subscribe((v) => {
      if (this.selectedGroupId != -1) {
        this.groupEndpoints = this.GetGroupsfromEndpoints(this.selectedGroupId);
      }
    });
  }

  onCronChanged(e: string) {
    this.groupService
      .UpdateSingleGroup({
        ...this.detailState,
        updateCron: e,
      })
      .subscribe((e) => {
        console.log(e);
      });
  }

  ToggleUpdateSchedule() {
    if (this.updateScheduleEnabled) {
      this.detailState.updateCron = '0 0 12 ? * *';
    } else {
      this.detailState.updateCron = null;
    }

    this.groupService.UpdateSingleGroup(this.detailState).subscribe((e) => {
      if (this.updateScheduleEnabled) {
        this.notify('Update schedule created.', 'success');
      } else {
        this.notify('Update schedule removed.', 'success');
      }
    });
  }

  // ### Helper methods ###
  GetGroupsfromEndpoints(groupId) {
    var endpointswithGroups = this.endpoints.filter(
      (v) => v.endpointGrouping.length > 0
    );
    var endpointGroups = endpointswithGroups.filter((v) =>
      v.endpointGrouping.find((v) => v.endpointGroupId == groupId)
    );
    return endpointGroups;
  }

  GetEndpointData(selectedEP) {
    this.addEndpointsToGroup = true;
    this.selectedEndpoint = selectedEP;
    this.selectedGroupName = selectedEP.name;
  }

  clickName(dom) {
    dom._view._lView[0].parentElement.previousSibling.children[1].children[0].click();
  }

  notify(text: string, status: string) {
    var data = {
      defaultNotificationText: text,
      standardStatus: status,
      notificationShow: true,
    };
    this.notifyMe.showNotification(data);
  }

  closeDetail() {
    this.detailState = null;
  }
}
