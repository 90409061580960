<div cdkDrag [cdkDragDisabled]="isReadOnly" class="item-wrapper my-1 p-2 ps-0 rounded-1 w-100">
    <div class="unexpanded-content d-flex justify-content-between align-items-center" (click)="expand()">
        <div class="d-flex align-items-center gap-1">
            <cds-icon cdkDragHandle *ngIf="!isReadOnly" shape="drag-handle" class="handle" size="md"></cds-icon>
            <cds-icon [attr.shape]="command.specification.icon" [class.ms-2]="isReadOnly" class="command-icon"
                size="md"></cds-icon>
            <div class="command-name">{{command.specification.name}}</div>
        </div>
        <cds-icon shape="angle" [attr.direction]="expanded ? 'down' : 'right'"></cds-icon>
    </div>
    <div class="expanded-content p-2" [hidden]="!expanded">
        <form clrForm clrLayout="horizontal">
            <ng-container *ngFor="let param of command.specification.parameters; let i = index;"
                [ngSwitch]="param.type">
                <clr-select-container *ngSwitchCase="'string-select'">
                    <label #tooltip="matTooltip" matTooltip="{{param.description}}" matTooltipPosition="left"
                        matTooltipHideDelay="10" matTooltipClass="sequenceCommand"
                        [class.required-label]="param.isRequired" class="clr-col-12 clr-col-lg-4"
                        for="{{param.name}}">{{param.name}}</label>
                    <select class="clr-select" clrSelect [ngModel]="command.parameters[i].value"
                        [ngModelOptions]="{standalone: true}"
                        (change)="onCommandParamChange($event.target.value, command.parameters[i])">
                        <option *ngIf="!param.isRequired" value="{{null}}">/</option>
                        <ng-container *ngFor="let option of param.options">
                            <option value="{{option}}">
                                {{option}}
                            </option>
                        </ng-container>
                    </select>
                </clr-select-container>
                <clr-datalist-container *ngSwitchCase="'path'">
                    <label #tooltip="matTooltip" matTooltip="{{param.description}}" matTooltipPosition="left"
                        matTooltipHideDelay="10" matTooltipClass="sequenceCommand"
                        [class.required-label]="param.isRequired" class="clr-col-12 clr-col-lg-4"
                        for="{{param.name}}">{{param.name}}</label>
                    <input #input="ngModel" [required]="param.isRequired" type="text" name="{{param.name}}"
                        class="clr-col-12 clr-col-lg-8" placeholder="{{param.name}}" clrDatalistInput
                        [disabled]="isReadOnly" [(ngModel)]="command.parameters[i].value"
                        (change)="onCommandParamChange($event.target.value, command.parameters[i])" />
                    <datalist>
                        <option *ngFor="let path of filePaths" value="{{path}}"></option>
                    </datalist>
                    <clr-control-error [hidden]="input.valid">This field is required!</clr-control-error>
                </clr-datalist-container>
                <clr-checkbox-container
                    *ngSwitchCase="param.type === 'boolean' || param.type === 'switch' ? param.type : '' ">
                    <clr-checkbox-wrapper>
                        <label #tooltip="matTooltip" matTooltip="{{param.description}}" matTooltipPosition="left"
                            matTooltipHideDelay="10" matTooltipClass="sequenceCommand">{{param.name}}</label>
                        <input type="checkbox" clrCheckbox value="{{param.name}}" name="{{param.name}}"
                            [disabled]="isReadOnly" [(ngModel)]="command.parameters[i].value" />
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <clr-textarea-container *ngSwitchCase="'scriptblock'">
                    <label #tooltip="matTooltip" matTooltip="{{param.description}}" matTooltipPosition="left"
                        matTooltipHideDelay="10" matTooltipClass="sequenceCommand"
                        [class.required-label]="param.isRequired" class="clr-col-12 clr-col-lg-4"
                        for="{{param.name}}">{{param.name}}</label>
                    <textarea #input="ngModel" [required]="param.isRequired" type="text" name="{{param.name}}"
                        class="clr-col-12 clr-col-lg-8 scriptblock-area" placeholder="{{param.name}}"
                        [disabled]="isReadOnly" [(ngModel)]="command.parameters[i].value" clrTextarea></textarea>
                    <clr-control-error [hidden]="input.valid">This field is required!</clr-control-error>
                </clr-textarea-container>
                <clr-input-container *ngSwitchDefault>
                    <label #tooltip="matTooltip" matTooltip="{{param.description}}" matTooltipPosition="left"
                        matTooltipHideDelay="10" matTooltipClass="sequenceCommand"
                        [class.required-label]="param.isRequired" class="clr-col-12 clr-col-lg-4"
                        for="{{param.name}}">{{param.name}}</label>
                    <input #input="ngModel" [required]="param.isRequired" type="text" name="{{param.name}}"
                        class="clr-col-12 clr-col-lg-8" placeholder="{{param.name}}" clrInput [disabled]="isReadOnly"
                        [(ngModel)]="command.parameters[i].value" />
                    <clr-control-error [hidden]="input.valid">This field is required!</clr-control-error>
                </clr-input-container>
            </ng-container>
        </form>
    </div>
    <div *cdkDragPreview class="item-wrapper my-1 p-2 ps-0 rounded-1">
        <div class="unexpanded-content d-flex justify-content-between align-items-center" (click)="expand()">
            <div class="d-flex align-items-center gap-1">
                <cds-icon cdkDragHandle shape="drag-handle" class="handle" size="md"></cds-icon>
                <cds-icon [attr.shape]="command.specification.icon" class="command-icon" size="md"></cds-icon>
                <div class="command-name">{{command.specification.name}}</div>
            </div>
            <cds-icon shape="angle" [attr.direction]="expanded ? 'down' : 'right'"></cds-icon>
        </div>
    </div>
</div>