import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagesPageComponent } from './packages-page/packages-page.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClarityModule } from '@clr/angular';
import { CdsModule } from '@cds/angular';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [PackagesPageComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    ClarityModule,
    CdsModule,
    RouterModule,
    SharedModule,
    FormsModule
  ],
})
export class MspPackagesPageModule {}
