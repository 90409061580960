import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Command } from '../../models/command';

@Component({
  selector: 'pb-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
})
export class SummaryStepComponent {
  @Input() formData: FormGroup;
  @Input() sequence: Command[] = [];
  @Input() iconUrl?: string;

  constructor() {}
}
