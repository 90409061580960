export interface ITag{
    id?: number;
    name?: string;
    color?: string;
    type: TagType;
}

export enum TagType {
    User = 0,
    DeployStage = 1
}
