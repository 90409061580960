import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LogListItem } from 'src/app/domain/interfaces/log-list-item';

@Component({
  selector: 'nd-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss'],
})
export class LogListComponent {
  @Input()
  listItems: LogListItem[] = [];
  @Output()
  onItemSelected = new EventEmitter<string>();
  selectedItem?: LogListItem;

  constructor() {}

  selectTab(item: LogListItem): void {
    this.onItemSelected.emit(item.name);
    this.selectedItem = item;
  }
}
